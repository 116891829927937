import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import { closePIPWindow } from "./Communicator";
import { removeToken } from "./editor";
import { CurrentTabOpened } from "./events/Resource_Assignment";
import { handleFocusPip, storePosition } from "./persistStore";
import { storeSizing, storeZoomSizing } from "./presenter";
import { storeScreenSharingName } from "./waitingAV";
import { handleResultsTime } from "./gamificationDrawer";

interface ISavedQuestions {
  id: string;
  time?: string;
}

interface IScreenSharedParticipant {
  id?: string,
  name?: string,
}
interface IPreviousStoreSize {
  width: string;
  height: string;
  positionX: string;
  positionY: string;
  isMinimized: boolean;
}

interface ICommunicatorState {
  show?: boolean;
  pin?: boolean;
  resource: boolean;
  creategroup: boolean;
  snack: boolean;
  search: string;
  fullScreen: boolean;
  toggleVideoTile: boolean;
  communicator: boolean;
  unread: boolean;
  gamification: boolean;
  screenSharing: boolean;
  drawer: boolean;
  disableScreenSharing: boolean;
  snackScrrenMsg: {
    active: boolean;
    value: string;
  };
  openDialogQuickAction: boolean;
  deviceSelection: boolean;
  raiseHand: string;
  raiseHandStatus: boolean;
  reaction: string;
  // isFocused: boolean;
  pipTestId: string;
  savedQuestions: ISavedQuestions[];
  previousStoreSize: IPreviousStoreSize;
  openNotes: boolean;
  showActiveAssign: string;
  noteHandler: boolean;
  screenSharedParticipant: IScreenSharedParticipant[];
}

const initialState: ICommunicatorState = {
  show: true,
  pin: true,
  resource: false,
  creategroup: false,
  snack: false,
  search: "",
  fullScreen: false,
  toggleVideoTile: false,
  communicator: false,
  unread: false,
  gamification: false,
  screenSharing: false,
  drawer: false,
  disableScreenSharing: false,
  snackScrrenMsg: {
    active: false,
    value: "",
  },
  openDialogQuickAction: false,
  deviceSelection: false,
  raiseHand: "",
  raiseHandStatus: false,
  reaction: "",
  pipTestId: "",
  savedQuestions: [],
  previousStoreSize: { width: "", height: "", positionX: "", positionY: "", isMinimized: false },
  openNotes: false,
  showActiveAssign: "",
  noteHandler: false,
  screenSharedParticipant: [],
};

const slice = createSlice({
  name: "avToolbar",
  initialState,
  reducers: {
    toggleToolBar(state) {
      state.show = !state.show;
    },
    confToggleToolBar(state, action) {
      state.show = action.payload;
    },
    pinToolBar(state) {
      state.pin = !state.pin;
    },
    confPinToolBar(state, action) {
      state.pin = action.payload;
    },
    resource(state, action) {
      state.resource = action.payload;
    },
    creategroup(state, action) {
      state.creategroup = action.payload;
    },
    snack(state, action) {
      state.snack = action.payload;
    },
    search(state, action) {
      state.search = action.payload;
    },
    fullScreen(state, action) {
      state.fullScreen = action.payload;
    },
    toggleVideo(state, action) {
      state.toggleVideoTile = action.payload;
    },
    toggleCommunicator(state, action) {
      state.communicator = action.payload;
      if (state.communicator) {
        state.unread = false;
      }
    },
    unreadToggle(state) {
      if (!state.communicator) {
        state.unread = true;
      } else {
        state.unread = false;
      }
    },
    gamification(state, action) {
      state.gamification = action.payload;
    },
    setScreenSharing(state, action) {
      state.screenSharing = action.payload;
    },
    setDisableScreenSharing(state, action) {
      state.disableScreenSharing = action.payload;
    },
    storeSnakMsg(state, action) {
      state.snackScrrenMsg = action.payload;
    },
    gamificationDrawer(state, action) {
      state.drawer = action.payload;
    },
    openDialogQuickAction(state, action) {
      state.openDialogQuickAction = action.payload;
    },
    deviceSelectionDailog(state, action) {
      state.deviceSelection = action.payload;
    },
    setRaiseHand(state, action) {
      state.raiseHand = action.payload;
    },
    raiseHandStatus(state, action) {
      state.raiseHandStatus = action.payload;
    },
    setReaction(state, action) {
      state.reaction = action.payload;
    },
    handleTestId(state, action) {
      state.pipTestId = action.payload;
    },
    SavedQuestions(state, action) {
      state.savedQuestions = action.payload;
    },
    storePreviousNotesSize(state, action) {
      state.previousStoreSize = action.payload;
    },
    handleNotes(state, action) {
      state.openNotes = action.payload;
    },
    showActiveAssign(state, action) {
      state.showActiveAssign = action.payload;
    },
    setNoteHandler(state, action) {
      state.noteHandler = action.payload;
    },
  },
});

export const reducer = slice.reducer;
// const link = "https://api-dev.einstonlabs.com/api/v1/events;
const APP_BASE_URL = process.env.REACT_APP_API_URL;

export const toggleToolBar = () => async (dispatch) => {
  dispatch(slice.actions.toggleToolBar());
};

export const confToggleToolBar = (data) => async (dispatch) => {
  dispatch(slice.actions.confToggleToolBar(data));
};
export const pinToolBar = () => async (dispatch) => {
  dispatch(slice.actions.pinToolBar());
};

export const confPinToolBar = (data) => async (dispatch) => {
  dispatch(slice.actions.confPinToolBar(data));
};

export const setDialogQuickAction = (data) => async (dispatch) => {
  dispatch(slice.actions.openDialogQuickAction(data));
};

export const resourceInfo = (data) => async (dispatch) => {
  dispatch(slice.actions.resource(data));
  if (!data) {
    dispatch(CurrentTabOpened(false));
  }
};

export const setGamification = (data) => async (dispatch) => {
  dispatch(slice.actions.gamification(data));
};
export const setGamificationDrawer = (data) => async (dispatch) => {
  dispatch(slice.actions.gamificationDrawer(data));
};
export const setdeviceSelectionDailog = (data) => async (dispatch) => {
  dispatch(slice.actions.deviceSelectionDailog(data));
};

export const creategroupInfo = (data) => async (dispatch) => {
  dispatch(slice.actions.creategroup(data));
};
export const snackInfo = (data) => async (dispatch) => {
  dispatch(slice.actions.snack(data));
};
export const searchInfo = (data) => async (dispatch) => {
  dispatch(slice.actions.search(data));
};
export const fullScreenInfo = (data) => async (dispatch) => {
  dispatch(slice.actions.fullScreen(data));
};
export const toggleVideo = (data) => async (dispatch) => {
  dispatch(slice.actions.toggleVideo(data));
};
export const toggleCommunicator = (data) => async (dispatch) => {
  dispatch(slice.actions.toggleCommunicator(data));
};

export const NotificationToggle = () => async (dispatch) => {
  dispatch(slice.actions.unreadToggle());
};
export const setNoteHandler = (data) => async (dispatch) => {
  dispatch(slice.actions.setNoteHandler(data));
};

export const ScreenSharing = (data) => async (dispatch) => {
  dispatch(slice.actions.setScreenSharing(data));
};

export const showActiveAssignButton = (data: any) => async (dispatch) => {
  dispatch(slice.actions.showActiveAssign(data));
};

export const screenSharingMsg = (data) => async (dispatch, getState) => {
  const name = data?.response?.data?.participant_name;
  if (data.response.data.state === "SHARE") {
    dispatch(
      slice.actions.storeSnakMsg({
        active: true,
        value: `${data.response.data.participant_name} sharing the screen`,
      })
    );
    const room:any = getState().meeting.room;
    const oldTrack = room.localTracks.desktop;
    if(oldTrack) {
    await room.roomInstance.replaceTrack(oldTrack.trackInformationInstance, null);
    await oldTrack.trackInformationInstance.dispose();
    }
    dispatch(slice.actions.setNoteHandler(true));
    dispatch(closePIPWindow(false));
    dispatch(storeScreenSharingName(name));
    dispatch(removeToken());
  } else if (data.response.data.state === "UNSHARE") {
    dispatch(
      slice.actions.storeSnakMsg({
        active: true,
        value: `${data.response.data.participant_name} stopped the sharing`,
      })
    );
    dispatch(storeScreenSharingName(""));
  }

  setTimeout(() => {
    dispatch(slice.actions.storeSnakMsg({ active: false, value: "" }));
  }, 3000);
};

export const stopSharingSelf = (data = false) => async (dispatch) => {
  dispatch(slice.actions.setDisableScreenSharing(data));
};

export const refreshScreenSharing = (data) => async (dispatch) => {
  if (data.length > 0) {
    dispatch(storeScreenSharingName(data[0].displayName));
  }
};

//raise hand
export const handleRaiseHand =
  (enqueueSnackbar) =>
    async (dispatch) => {
      const response = await axios.post(
        `${APP_BASE_URL}/api/v1/events/raise-hand`
      );
      dispatch(slice.actions.setRaiseHand(response.data));
      if (response.data.message === "Successfully RAISE_HAND") {
        dispatch(handleRaiseHandStatus(true));
        enqueueSnackbar("Hand Raised", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        dispatch(handleRaiseHandStatus(false));
        enqueueSnackbar("Hand down", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    };

export const handleRaiseHandStatus = (data) => async (dispatch) => {
  dispatch(slice.actions.raiseHandStatus(data));
};

export const handleReaction = (option) => async (dispatch) => {
  const response = await axios.post(
    `${APP_BASE_URL}/api/v1/events/react/${option}`
  );
  dispatch(slice.actions.setReaction(response.data));
};

export const Test = (enqueueSnackbar) => async (dispatch) => {
  try {
    const testResponse = await axios.get(`${APP_BASE_URL}/api/v1/test`);

    if (!testResponse?.data?.status) {
      throw new Error("Test creation failed");
    }

    const testId = testResponse?.data?.data?.assesments?.filter((test) => test?.type === "flexible");

    dispatch(slice.actions.handleTestId(testId[0]?.id));
    dispatch(getSavedQuestions(enqueueSnackbar, testId[0]?.id));
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Test creation is failed", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const getSavedQuestions = (enqueueSnackbar, testId) => async (dispatch) => {
  try {
    const savedQuestionResponse = await axios.get(`${APP_BASE_URL}/api/v1/question`,
      {
        params: { status: "draft", subType: "custom", parentId: testId },
      }
    );

    if (!savedQuestionResponse?.data?.status) {
      throw new Error("Unable to fetch saved question");
    }

    dispatch(slice.actions.SavedQuestions(savedQuestionResponse?.data?.data));
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Unable to fetch saved question",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const questionPublish = (enqueueSnackbar, id, time) => async (dispatch, getState) => {
  try {
    const publishResponse = await axios.put(`${APP_BASE_URL}/api/v1/question/${id}/publish`);

    if (!publishResponse?.data?.status) {
      throw new Error("Question Publish Failed");
    }
    const testId = getState().avToolbar.pipTestId;
    dispatch(getSavedQuestions(enqueueSnackbar, testId));
    dispatch(handleResultsTime(time));

    enqueueSnackbar(publishResponse?.data?.message || "Question published successfully", {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });

  } catch (error) {
    enqueueSnackbar(error?.message || error || "Question publish failed", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const updateSavedQuestions = (data) => (dispatch) => {
  dispatch(slice.actions.SavedQuestions(data));
};

export const handleToggleFocus = (enqueueSnackbar) => async () => {
  try {
    const focusResponse = await axios.post(`${APP_BASE_URL}/api/v1/events/toogle-notepad`);

    if (focusResponse?.status !== 200) {
      throw new Error("Focus request is failed");
    }

    enqueueSnackbar(focusResponse?.data?.message || "Focus request is sent successfully", {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });

  } catch (error) {
    enqueueSnackbar(error?.message || error, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const updateToggleFocus = (isFocused) => (dispatch, getState) => {
  if (isFocused) {
    const data = {
      positionX: getState().persistStore.presenterPosition.x,
      positionY:  getState().persistStore.presenterPosition.y,
      width: getState().presenter.sizing.width,
      height: getState().presenter.sizing.height,
      isMinimized: getState().Communicator.presenterMinimize
    };
    dispatch(storePreviousNotesSize(data));
    dispatch(closePIPWindow(false));
    if (getState().avToolbar.openNotes) {
      dispatch(storeSizing({ width: "66vw", height: `${document.body.clientHeight - 61 - 52}px` }));
      dispatch(storeZoomSizing({ width: "66vw", height: `${document.body.clientHeight - 61 - 52}px` }));
      dispatch(storePosition({ x: 0, y: 52 }));
    } else {
      dispatch(storeSizing({ width: "100vw", height: `${document.body.clientHeight - 61}px` }));
      dispatch(storeZoomSizing({ width: "100vw", height: `${document.body.clientHeight - 61}px` }));
      dispatch(storePosition({ x: 0, y: 0 }));
    }
    dispatch(handleFocusPip(isFocused));
  } else {
    const data = getState().avToolbar.previousStoreSize;
    dispatch(storeSizing({ width: data.width, height: data.height }));
    dispatch(storeZoomSizing({ width: data.width, height: data.height }));
    dispatch(storePosition({ x: data.positionX, y: data.positionY }));
    dispatch(closePIPWindow(data.isMinimized));
    dispatch(handleFocusPip(isFocused));
    dispatch(storePreviousNotesSize({ width: "", height: "", positionX: "", positionY: "", isMinimized: false}));
  }
};

export const handleNotes = (data) => (dispatch) => {
  dispatch(slice.actions.handleNotes(data));
};

export const storePreviousNotesSize = (data) => (dispatch) => {
  dispatch(slice.actions.storePreviousNotesSize(data));
};

export const notifyParticipants = (enqueueSnackbar) => async () => {
  try {
    const focusResponse = await axios.post(`${APP_BASE_URL}/api/v1/participants/notify?all=true`);

    if (!focusResponse?.data?.status) {
      throw new Error("Could not notify participants");
    }

    enqueueSnackbar(focusResponse?.data?.message || "Notified to all participants", {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });

  } catch (error) {
    enqueueSnackbar(error?.message || error, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export default slice;
