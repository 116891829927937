import React, { useCallback, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreator } from "./base";
import { StyledEngineProvider } from "@mui/material";

// const cacheRtl = createCache({
//   key: 'bloom-ui'
//   // @ts-ignore
//   stylisPlugins: [rtlPlugin]
// }  );

/* eslint-disable */
export const ThemeContext = React.createContext(
  (themeName: string): void => {}
);
/* eslint-enable */

const ThemeProviderGlobal: React.FC = (props) => {
  const curThemeName = localStorage.getItem("appTheme") || "PureLightTheme";
  const [themeName, setThemename] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = useCallback(
    (themeName: string): void => {
      localStorage.setItem("appTheme", themeName);
      setThemename(themeName);
    },
    [setThemename]
  );
  return (
    <>
      {/* <CacheProvider value={cacheRtl}> */}
      <StyledEngineProvider injectFirst>
        <ThemeContext.Provider value={setThemeName}>
          <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </ThemeContext.Provider>
      </StyledEngineProvider>
      {/* </CacheProvider> */}
      {/* // </StylesProvider> */}
    </>
  );
};

export default ThemeProviderGlobal;
