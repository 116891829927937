import mqtt from "precompiled-mqtt";
import { NotificationToggle, screenSharingMsg, updateToggleFocus } from "src/slices/AvToolbar";
import { getVDIPresenter, presenterEnabled, removePresenterAccessUpdate, setCursorPosition, setSnakMsg } from "src/slices/editor";
import {
    fetcingLiveServices, getAllServices, getParticipants, participantsUpdate, serviceUpdate, UpdateGroupVdiGroup, UpdateGroupVdiGroupStatus,
    handleMachineStopNotification, getGroupData
} from "src/slices/events/Resource_Assignment";
import {
    updatePublishedQuestions, updateQuickAction, updateTestCase, handleLiveResultResponse, handleAssessmentId,
    handleShowLatestResponse, clearQuickActions, canSelectAnyQuestion, handleAssessmentDuration, closePublish
} from "src/slices/gamificationDrawer";
import { leftMessages, messageApiById } from "src/slices/messenger";
import { AccessNotification, deviceID, devicesUsage, NotificationMuteUnmute, vdiAccessNotification } from "src/slices/myMachines";
import { autoAccessSetVDI, EventDeleted, notifySnackMSG } from "src/slices/oprations";
import { hitWaitingAPI, insufficientCreditsDialog, storeReaction, storeWaitingDialog, manageUserReaction } from "src/slices/waitingRoom";

import store from "src/store";
import { audioAction } from "src/slices/waitingAV";
import { notePromptSend, showRecordingStartedDialog, showRecordinStoppedDialog} from "src/slices/presenter";
import { clearAllDrawerStates, ParticipantRecordMsg } from "src/slices/meeting";
import { removeAccessActiveMachine, handleIsCollabAvailable } from "src/slices/persistStore";

const connectUrl = process.env.REACT_APP_MQTT;

let mqttConnected = false;

let client;
let timerId;

function getClient(data, dispatch, VIEW_ALL_PARTICIPANT) {
    client = mqtt.connect(connectUrl, {
        clientId: data.clientId.toString(),
        clean: true,
        connectTimeout: 4000,
        reconnectPeriod: 1000,
        username: data.username,
        password: data.password,
    });

    client.on("connect", () => {
        const connected = data.allowedSubscriptions.map((topic) =>
            new Promise((resolve) => {
                client.subscribe(topic, (err) => {
                    if (!err) {
                        resolve(true);
                    } else {
                        throw new Error(err);
                    }
                });
            })
        );

        Promise.all(connected).then(() => {
            dispatch(hitWaitingAPI(VIEW_ALL_PARTICIPANT));
        });
        mqttConnected = true;
    });
    client.on("reconnect", (err) => {
        if (err) {
            window.location.reload();
            throw new Error("Reconnecting...");
        }
    });
}

function getConnectionstatus() {
    return mqttConnected;
}

function publish(id, msg) {
    client.publish(id, msg, (error) => {
        if (error) {
            throw new Error(error);
        }
        //eslint-disable-next-line
        // console.log("mq",id,msg)
    });
}

function onMessage(dispatch, enqueueSnackbar) {
    if (client) {
        client.on("message", (topic, message) => {
            const data = JSON.parse(message.toString());
            if (data.domain === "PARTICIPANT") {    
                if (data.intent === "NOTIFY_ACCESS_VDI") {
                    dispatch(getAllServices());
                    dispatch(notifySnackMSG(data));
                } else if (data.intent === "AUTO_ACCESS") {
                    dispatch(removeAccessActiveMachine());
                    dispatch(autoAccessSetVDI(data, store.getState().persistStore.activeMachine));
                } else if (data.intent === "PARTICIPANT_LEFT") {
                    dispatch(getParticipants());
                } else if (data.intent === "PARTICIPANT_JOIN") {
                    dispatch(getParticipants());
                } else if (data.intent === "ROLE_UPDATE_REFRESH") {
                    dispatch(ParticipantRecordMsg(enqueueSnackbar, data));
                } else if (data.intent === "ROLE_UPGRADE_DOWNGRADE") {
                    dispatch(clearAllDrawerStates(data));
                }
                else if (data.intent === "RAISE_HAND") {
                    dispatch(storeReaction(data, true));
                } else if (data.intent === "DOWN_HAND") {
                    dispatch(storeReaction(data, false));
                } else if (data.intent === "REACTION") {
                    dispatch(manageUserReaction(data));
                } else if (data.intent === "PARTICIPANT_REMOVE") {
                    const dataRemove = {
                        userName: store.getState().meeting.userName,
                        id: store.getState().events.threadId,
                        roomInstance: store.getState().meeting.room.roomInstance,
                        conferenceInstance: store.getState().meeting.conference.conferenceInstance,
                        localTracks: store.getState().meeting.room.localTracks,
                        room: store.getState().meeting.room
                    };
                    dispatch(leftMessages(dataRemove));
                }
                else {
                    dispatch(storeWaitingDialog(data));
                }
            } else if (data.domain === "GAMIFICATION") {
                if (data.response?.data?.questions) {
                    const questions = data.response?.data?.questions;
                    if(data?.response?.data?.type === "flexible") {
                        dispatch(canSelectAnyQuestion(false));
                    } else if(data?.response?.data?.type === "exam") {
                        dispatch(closePublish());
                        dispatch(handleAssessmentDuration(data?.response?.data?.duration * 60));
                        dispatch(canSelectAnyQuestion(true));
                    }
                    dispatch(handleAssessmentId(data?.response?.data?.id));
                    let isQuick = false;
                    for (const question of questions) {
                        if (question?.type === "quick") {
                            isQuick = true;
                            dispatch(updateQuickAction(data));
                        } else if (question?.type === "code") {
                            dispatch(updateTestCase(question?.id, question?.test_cases));
                        }
                    }
                    if (!isQuick) {
                        dispatch(updatePublishedQuestions(data));
                    }
                }
                if(data.intent === "SUBMISSION_UPDATE") {
                    dispatch(handleShowLatestResponse({}));
                    if (data.response?.data?.data?.type === "quick") {
                        dispatch(handleShowLatestResponse(data.response?.data?.data));
                        dispatch(handleLiveResultResponse(data.response?.data?.data));
                    } else {
                        dispatch(handleShowLatestResponse(data.response?.data));
                        dispatch(handleLiveResultResponse(data.response?.data));
                    }
                    if (timerId) {
                        clearTimeout(timerId);
                    }
                    timerId = setTimeout(() => { dispatch(handleShowLatestResponse({})); }, 7000);
                } else if(data.intent === "QUESTION_PREEMPT") {
                    dispatch(clearQuickActions());
                }
            } else if (data.domain === "CONFERENCE") {
                if (data.intent === "SCREENSHARING") {
                    dispatch(screenSharingMsg(data));
                }
                else if (data.intent === "MIC_UPDATE") {
                    dispatch(audioAction(data.response.data.action === "UNMUTE"));
                    dispatch(NotificationMuteUnmute(data.response.data.action, data.response.data.requestedBy));
                }
                else if (data.intent === "NOTE_RECEIVED") {
                    dispatch(notePromptSend(true, data.response.data.note));

                } else if (data.intent === "RECORDING_STARTED") {
                    dispatch(showRecordingStartedDialog(true));
                    dispatch(showRecordinStoppedDialog(false));
                } else if (data.intent === "RECORDING_ENDED") {
                    dispatch(showRecordingStartedDialog(false));
                    dispatch(showRecordinStoppedDialog(true));
                } else if (data.intent === "PARTICIPANT_REMOVE") {
                    dispatch(EventDeleted(true));
                } else if(data.intent === "TOOGLE_NOTEPAD") {
                    dispatch(updateToggleFocus(data?.response?.data?.focus));
                } 
                else if (data.intent === "COLLABORATION_ENABLED") {
                    dispatch(handleIsCollabAvailable(true));
                } else if (data.intent === "COLLABORATION_DISABLED") {
                    dispatch(handleIsCollabAvailable(false));
                }
            } else if (data.domain === "GROUP") {
                if (data.intent === "GROUP_INCREASE") {
                    dispatch(UpdateGroupVdiGroup(data));
                }
            } else if (data.domain === "SERVICE") {
                if (data.intent === "LIFECYCLE_UPDATE") {
                    dispatch(serviceUpdate(data));
                    if (data.response.data.state === "STARTED" && data.response.data.serviceId === store.getState().accessPermissionDialog.ID) {
                        dispatch(deviceID(data.response.data.serviceId, true));
                    }
                    dispatch(AccessNotification(data, store.getState().persistStore.activeMachine, store.getState().editor.vdiInfoRead, store.getState().accessPermissionDialog.ID));
                    if (data.response.data.state === "RESIZING") {
                        dispatch(UpdateGroupVdiGroupStatus(data));
                    }
                    if (data?.response?.data.state === "STOPPING" || data?.response?.data?.state === "STOPPED") {
                        dispatch(handleMachineStopNotification(data?.response?.data));
                    }
                    if(data?.response?.data?.state === "DESTROYED") {
                        dispatch(getGroupData(store.getState().AVSetting.currentGroupId));
                    }
                } else if (data.intent === "SERVICE_OPERATION") {
                    dispatch(handleMachineStopNotification(data?.response?.data));
                } else if (data.intent === "ACCESS_UPDATE") {
                    dispatch(presenterEnabled(data, store.getState().persistStore));
                    dispatch(getParticipants());
                    dispatch(getAllServices());
                    dispatch(AccessNotification(data, store.getState().persistStore.activeMachine, store.getState().editor.vdiInfoRead, store.getState().accessPermissionDialog.ID));
                    dispatch(fetcingLiveServices(data, store.getState().AVSetting.currentGroupId));
                } else if (data.intent === "PRESENTOR_VDI_UPDATED") {
                    dispatch(getVDIPresenter(data));
                    dispatch(setSnakMsg(true, data));
                } else if (data.intent === "PRESENTOR_VDI_REMOVED") {
                    dispatch(removePresenterAccessUpdate());
                    dispatch(setSnakMsg(false, data));
                } else if (data.intent === "LOCK") {
                    dispatch(participantsUpdate(data));
                } else if (data.intent === "SERVICE_INCREASE") {
                    dispatch(getAllServices());
                    dispatch(fetcingLiveServices(data, store.getState().AVSetting.currentGroupId));
                } else if (data.intent === "DEVICE_USGAE") {
                    dispatch(devicesUsage(data));
                } else if (data.intent === "ACCESS_NOTIFY") {
                    dispatch(vdiAccessNotification(data?.response?.data?.message));
                }
            } else if (data.domain === "MESSAGING") {
                if (data.intent === "INSUFFICIENT_CREDITS_WITH_REFILL") {
                    dispatch(insufficientCreditsDialog(data));
                }
            } else if (data.domain === "COLLAB_POINTER") {
                if (data.intent === "UPDATE") {
                    dispatch(setCursorPosition(data?.response));
                }
            } else if (data.eventType === "event_message" || data.eventType === "join_message" || data.eventType === "left_message" || data.userId !== "") {

                dispatch(messageApiById(store.getState().events.threadId));
                dispatch(NotificationToggle());
            }
            // eslint-disable-next-line
            console.log("MQTT MESSAGES", message.toString()); // This is required because what where message comes via MQTT we can verity. Once is done then remove
        });
    }
}

function unsubscribe(topic) {
    client.unsubscribe(topic);
}

function closeConnection() {
    return client.end(() => {
        mqttConnected = false;
    });
}

function mqttSubcribe(topic){
    if (client) {
      client.subscribe(topic, (error) => {
        if (error) {
            throw new Error(error);
        }
      });
    }
}
  
const mqttService = {
    getClient,
    getConnectionstatus,
    publish,
    onMessage,
    mqttSubcribe,
    unsubscribe,
    closeConnection,
};

export default mqttService;
