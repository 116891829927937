import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

interface IBaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<IBaseLayoutProps> = ({ children }) => {
  return <>{children}</>;
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
