import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
// import URLs from 'src/URL.json'
import { changeState, getvdiInfo, set360view } from "./editor";
import { resourceInfo } from "./AvToolbar";
import { storeAccessActiveMachine } from "./persistStore";

enum ECOLOR {
  ERROR = "error",
  INFO = "info",
}

interface IAccess {
  name: string;
  device: string;
  ip: string;
  access: string;
  os: string;
  address: string;
}

interface IRoot {
  snack: {
    active: boolean;
    message: string;
  };
  notifySnack: {
    active: boolean;
    color: ECOLOR;
    message: string;
  };
  accessDialog: {
    active: boolean;
    access: IAccess[];
  };
  autoAccessMsg: {
    active: boolean;
    message: string;
  };
  eventDeleted: boolean;
}

const initialState: IRoot = {
  snack: {
    active: false,
    message: "",
  },
  notifySnack: {
    active: false,
    color: ECOLOR.INFO,
    message: "",
  },
  accessDialog: {
    active: false,
    access: [],
  },
  autoAccessMsg: {
    active: false,
    message: "",
  },
  eventDeleted: false,
};

const slice = createSlice({
  name: "oprations",
  initialState,
  reducers: {
    snakMsg(state, action) {
      state.snack = action.payload;
    },
    notifySnakMsg(state, action) {
      state.notifySnack = action.payload;
    },
    setAccessVDIDialog(state, action) {
      state.accessDialog = action.payload;
    },
    autoAccessSnakMsg(state, action) {
      state.autoAccessMsg = action.payload;
    },
    setEventDeleted(state, action) {
      state.eventDeleted = action.payload;
    },
  },
});

export const reducer = slice.reducer;
const BASE_URL = process.env.REACT_APP_API_URL;

const LINK = `${process.env.REACT_APP_API_URL}/api/v1`;

export const oprations = (data) => async (dispatch) => {
  const newValue = [];
  const body = {
    type: "virtual_desktop_infrastructure_browser",
    process: data.action,
    serviceId: data.serviceId,
  };
  newValue.push(body);
  const response = await axios.post(`${BASE_URL}/api/v1/service`, newValue);

  if (response.status === 200) {
    dispatch(
      slice.actions.snakMsg({ active: true, message: response.data.message })
    );
  }
  setTimeout(() => {
    dispatch(slice.actions.snakMsg({ active: false, message: "" }));
  }, 3000);
};

export const oprationsMulti = (data, value, enqueueSnackbar) => async () => {
  if (data.length > 0) {
    data.forEach(async (id) => {
      const body = {
        type: "virtual_desktop_infrastructure_browser",
        process: value,
        serviceId: id,
      };
      await axios
        .post(`${BASE_URL}/api/v1/service`, [body])
        .then((res) => {
          if (res.status && res.data.status) {
            // console.log('res', res.data.data[0])
            let snackMessage;
            if (res.data.data[0].message.includes("stop")) {
              snackMessage = "Stop operation is initiated successfully";
            } else if (res.data.data[0].message.includes("restart")) {
              snackMessage = "Restart operation is initiated successfully";
            } else if (res.data.data[0].message.includes("delete")) {
              snackMessage = "Delete operation is initiated successfully";
            } else if (res.data.data[0].message.includes("start")) {
              snackMessage = "Start operation is initiated successfully";
            } else {
              snackMessage = "Operation initiated successfully";
            }
            enqueueSnackbar(snackMessage, {
              variant: "success",
              preventDuplicate: true,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(
            error?.message || error || "Failed to perform operation",
            {
              variant: "error",
              preventDuplicate: true,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
        });
    });
  }
};

export const setSnackMSG = (data) => (dispatch) => {
  dispatch(slice.actions.snakMsg(data));
};

export const notifyParticipants = (data) => async (dispatch) => {
  const response = await axios.post(
    `${BASE_URL}/api/v1/participants/notify?id=${data}`
  );
  if (response.data.status) {
    dispatch(
      slice.actions.notifySnakMsg({
        active: true,
        color: ECOLOR.INFO,
        message: response.data.data,
      })
    );
  } else {
    dispatch(
      slice.actions.notifySnakMsg({
        active: true,
        color: ECOLOR.ERROR,
        message: response.data.message,
      })
    );
  }

  setTimeout(() => {
    dispatch(
      slice.actions.notifySnakMsg({
        active: false,
        color: ECOLOR.INFO,
        message: "",
      })
    );
  }, 3000);
};

export const lockParticipants = (data) => async (dispatch) => {
  const response = await axios.post(
    `${BASE_URL}/api/v1/participants/${data}/lock`
  );
  if (response.data.status) {
    dispatch(
      slice.actions.notifySnakMsg({
        active: true,
        color: ECOLOR.INFO,
        message: response.data.data,
      })
    );
  } else {
    dispatch(
      slice.actions.notifySnakMsg({
        active: true,
        color: ECOLOR.ERROR,
        message: response.data.message,
      })
    );
  }
  setTimeout(() => {
    dispatch(
      slice.actions.notifySnakMsg({
        active: false,
        color: ECOLOR.INFO,
        message: "",
      })
    );
  }, 3000);
};

export const notifySnackMSG = (data) => async (dispatch) => {
  if (data) {
    const filterItem = data.response.data.access.map(
      async (value) =>
        new Promise((resolve) => {
          async function apiHit() {
            if (value.access !== "host") {
              const response = await axios.get(
                `${LINK}/service?id=${value.device}`
              );
              if (response.data.status) {
                resolve({
                  name: value.name,
                  ip: response.data.data[0]?.dns?.ip,
                  access: value.access,
                  device: value.device,
                  os: response.data?.data[0]?.properties?.os,
                  address: response.data?.data[0]?.dns?.address,
                });
              } else {
                resolve("");
              }
            } else {
              resolve("");
            }
          }
          apiHit();
        })
    );

    Promise.all(filterItem).then((value) => {
      dispatch(
        slice.actions.setAccessVDIDialog({
          active: true,
          access: value.filter((value) => value !== ""),
        })
      );
    });
  }
};

export const clearDialog = () => async (dispatch) => {
  dispatch(slice.actions.setAccessVDIDialog({ active: false, access: [] }));
};

export const autoUpdate = (data) => async (dispatch) => {
  const response = await axios.post(
    `${BASE_URL}/api/v1/participants/autoAccess`,
    { participants: data }
  );
  if (response.data.status) {
    dispatch(
      slice.actions.notifySnakMsg({
        active: true,
        color: ECOLOR.INFO,
        message: response.data.data,
      })
    );
  } else {
    dispatch(
      slice.actions.notifySnakMsg({
        active: true,
        color: ECOLOR.ERROR,
        message: response.data.message,
      })
    );
  }
  setTimeout(() => {
    dispatch(
      slice.actions.notifySnakMsg({
        active: false,
        color: ECOLOR.INFO,
        message: "",
      })
    );
  }, 3000);
};

export const handleAutoAccessSelectedMachine = (data, name, pc, enqueueSnackbar) => async () => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/participants/autoAccess/specific`, data);
    if (!response?.data?.status) {
      throw new Error(response?.data?.message || `${name} could not access ${pc}`);
    }

    enqueueSnackbar(`${name} is accessing ${pc}`,
      {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || `${name} could not access ${pc}`,
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const autoAccessSetVDI = (data, activeMachine) => async (dispatch) => {
  const value = data.response.data.access.filter(
    (val) => val.access === "owner"
  );
  if (value.length > 0) {
    const response = await axios.get(`${LINK}/service?id=${value[0].device}`);
    if (response.data.status) {
      if (activeMachine.length === 0) {
        dispatch(changeState(false));
        dispatch(set360view(false));
        dispatch(
          getvdiInfo({
            device: value[0].device,
            access: value[0].access,
            ip: response.data.data[0]?.dns?.ip,
            os: response.data?.data[0]?.properties?.os,
            address: response.data?.data[0]?.dns?.address,
          })
        );

        dispatch(
          storeAccessActiveMachine({
            deviceId: value[0].device,
            ipAddress: response.data.data[0]?.dns?.ip,
            accessType: value[0].access,
            os: response.data?.data[0]?.properties?.os,
            address: response.data?.data[0]?.dns?.address,
          })
        );
        dispatch(resourceInfo(false));
        dispatch(changeState(true));

        dispatch(
          slice.actions.autoAccessSnakMsg({
            active: true,
            message: "Trainer force for Access VDI",
          })
        );
        setTimeout(() => {
          dispatch(
            slice.actions.autoAccessSnakMsg({ active: false, message: "" })
          );
        }, 3000);
      }
    }
  }
};

export const EventDeleted = (data) => (dispatch)  => {
  dispatch(slice.actions.setEventDeleted(data));
};
export default slice;
