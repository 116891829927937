import { Suspense, Fragment, lazy } from "react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import BaseLayout from "src/layouts/BaseLayout";
import SuspenseLoader from "src/components/SuspenseLoader";

type RoutesType = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  childLayout?: any;
  component?: any;
  routes?: RoutesType;
}[];

export const renderRoutes = (routes: RoutesType = []): React.JSX.Element => (
  <Suspense fallback={<SuspenseLoader />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const ChildLayout = route.childLayout;

        return (
          <Route
            key={i}
            path={route.path as string}
            element={
              <Guard>
                <Layout>
                  {route.routes ? (
                    ChildLayout ? <ChildLayout>
                      renderRoutes(route.routes)
                    </ChildLayout> : renderRoutes(route.routes)) : <Component />}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes: RoutesType = [
  {
    exact: true,
    layout: BaseLayout,
    path: "/event/:id",
    component: lazy(() => import("src/content/join-conference")),
  },
  {
    exact: true,
    layout: BaseLayout,
    path: "/event/:id/waiting",
    component: lazy(() => import("src/content/join-conference")),
  },
  {
    exact: true,
    layout: BaseLayout,
    path: "/event/:id/registration",
    component: lazy(() => import("src/content/user-register")),
  },
  {
    exact: true,
    layout: BaseLayout,
    path: "/event/:id/conference",
    component: lazy(() => import("src/container/avconference")),
  },
  {
    exact: true,
    layout: BaseLayout,
    path: "/event/classroom/conference",
    component: lazy(() => import("src/content/join-conference")),
  },
  {
    exact: true,
    layout: BaseLayout,
    path: "/",
    component: lazy(() => import("src/content/landing")),
  },
  {
    path: "*",
    layout: BaseLayout,
    component: lazy(() => import("src/content/notFoundPages/NotFoundPage")),
  },
];

export default routes;
