import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import type { AppThunk } from 'src/store';
interface IAllowedPermissions {
    currentId?:string;
    name?: string;
    allowedPermissions?: Array<string>
}
interface IRoot {
    defaultVideoStatus: boolean,
    defaultAudioStatus: boolean,
    allowedPermissions?: IAllowedPermissions
    currentGroupId?: string
    vdiSharingName?: string
    screenSharingName?: string,
    eventLink: string,
    eventTitle: string,
    divLayerEnable: boolean,
    currentScreenSharedTrack: {} | null
}

const initialState: IRoot = {
    defaultVideoStatus: false,
    defaultAudioStatus: false,
    allowedPermissions: {
        currentId:'',
        name: "",
        allowedPermissions: []
    },
    currentGroupId: "",
    vdiSharingName: "",
    screenSharingName: "",
    eventLink: "",
    eventTitle: "",
    divLayerEnable: false,
    currentScreenSharedTrack: null
};
const slice = createSlice({
    name: 'meeting',
    initialState,
    reducers: {
        setVidioStatus(state, action) {
            state.defaultVideoStatus = action.payload;
        },
        setAudioStatus(state, action) {
            state.defaultAudioStatus = action.payload;
        },
        setAllowedPermission(state, action) {                        
            state.allowedPermissions = action.payload;
            state.allowedPermissions.currentId=action.payload.id;
        },
        setGroupID(state, action) {
            state.currentGroupId = action.payload;
        },
        setVdiSharingName(state, action) {
            state.vdiSharingName = action.payload;
        },
        setScreenSharingName(state, action) {
            state.screenSharingName = action.payload;
        },
        setEventLink(state, action) {
            state.eventLink = action.payload;
        },
        setEventTitle(state, action) {
            state.eventTitle = action.payload;
        },
        onResizing(state, action) {
            state.divLayerEnable = action.payload;
        },
        setCurrentScreenSharedTrack(state, action) {
            state.currentScreenSharedTrack = action.payload;
        }
    }
});
const APP_BASE_URL = process.env.REACT_APP_API_URL;

export const audioAction = (value: boolean): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setAudioStatus(value));
};

export const videoAction = (value: boolean): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setVidioStatus(value));
};

export const sharedScreenAction = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setCurrentScreenSharedTrack(data));
};

export const setAllowPermission = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setAllowedPermission(data));
};

export const storeCurrentGroupID = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setGroupID(data));
};

export const storeVdiSharingName = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setVdiSharingName(data));
};

export const storeScreenSharingName = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setScreenSharingName(data));
};

export const storeEventLink = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setEventLink(data));
};

export const storeEventTitle = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setEventTitle(data));
};

export const onResizingHandler = (data: boolean): AppThunk => async (dispatch) => {
    dispatch(slice.actions.onResizing(data));
};

export const stopRecordingAPIHit = (data: boolean, Name: string): AppThunk => async () => {
    const payload = {
        trigger: data ? "start" : "stop",
        name: Name
    };
    await axios.post(`${APP_BASE_URL}/api/v1/record`, payload);
    
};

export const downloadRecording = (data, lastChunk): AppThunk => async () => {

    const formData = new FormData();
    formData.append("context_id", window.location.href.split("/")[4]);
    formData.append('file', data);
    formData.append('last_chunk', lastChunk);

    await axios.patch(`${APP_BASE_URL}/api/v1/record/upload`, formData);

};

export const reducer = slice.reducer;
export default slice;
