import React from "react";
import { somethingWentWrongCover } from "src/constants/cover-images";
import URLs from "src/URL.json";
import { Wrapper, ContentWrapper, ImageWrapper, Title, SubTitle } from "./indexStyled";

interface IErrorFallbackProps {
  error: Error;
}

const SomethingWentWrong = ({ error }: IErrorFallbackProps) => {
  const HandleRedirectHome = () => {
    const url = process.env.NODE_ENV !== "production"
      ? `${URLs.EL_LOCAL_URL}`
      : process.env.REACT_APP_EL_URL;
    window.location.href = url;
  };


  return (
    <Wrapper>
      <ContentWrapper>
        <Title>Oops! Something went wrong!</Title>
        <ImageWrapper>
          <img
            src={somethingWentWrongCover}
            alt="something-went-wrong-cover"
            height="100%"
            width="100%"
          />
        </ImageWrapper>
        <SubTitle>
          We Apologise for the hiccup in your experience. <br />
          It appears that we have encountered a small roadblock. <br />
          Kindly{" "}
          <u
            onClick={HandleRedirectHome}
            style={{ cursor: "pointer", color: "#3087EC" }}
          >
            click here
          </u>{" "}
          to go back to Home page.
        </SubTitle>
        {/* for development */}
        {process.env.REACT_APP_MODE === "DEVELOPMENT" && <SubTitle>
          {error.message}
        </SubTitle>}
        {/* for development */}
      </ContentWrapper>
    </Wrapper >
  );
};

export default SomethingWentWrong;
