import { IParticipant, ITrack, IRoot, } from "./../models/meeting";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
// @ts-ignore
import JitsiMeetJS from 'lib-jitsi-meet';
import mqttService from "src/services/MqttService";
import { setForceRender } from "./videoToggleupdate";
import { ScreenSharing, resourceInfo, setGamificationDrawer, stopSharingSelf } from "./AvToolbar";
import axios from "src/utils/axios";
import { clearReduxPersist, storeUserName, storeEmailId, roomPrefix } from "./persistStore";
import { ErrorMessage, TrainerNotification } from "./myMachines";
import { setAllowPermission } from "./waitingAV";
import store from "src/store";
import { set360view } from "./editor";

const BASE_URL = process.env.REACT_APP_API_URL;
const configInitialState = {
  hosts: {
    domain: "meet.jitsi",
    muc: "muc.meet.jitsi", 
    // use XEP-0030
  },
  useStunTurn: false,
  p2p: {
    enabled: false,
    // stunServer: [
    //   {
    //     url: 'stun:13.57.19.185',
    //   },
    // ],
  },
  forceJVB121Ratio: true,
  disableFocus: true,
  serviceUrl: 'wss://ms1.portego.in/xmpp-websocket',
  // serviceUrl: 'wss://ms7.portego.live/xmpp-websocket',
  // wss://ms7.portego.live/xmpp-websocketb
  // bosh: "https://ms7.portego.live/http-bind",
  enableLayerSuspension: true,
  resolution: 720,
  constraints: {
    video: {
      aspectRatio: 4 / 3,
      height: {
        ideal: 480,
        max: 480,
        min: 180,
      },
    },
  },
  desktopSharingFrameRate: {
    min: 30,
    max: 30,
  },
  disableAudioLevels: true,
};
const initialState: IRoot = {
  config: configInitialState,
  conference: {
    connectionStatus: "",
    conferenceInstance: {},
  },
  room: {
    participants: {}, // object key = id
    localTracks: {},
    remoteTracks: [],
    muteRoom: true,
    stopAllVideo: false,
    stopAllScreenShare: false,
    roomInstance: {},
    mediaDevices: [],
  },
  isChatWIndowOpen: false,
  isSettingWindowOpen: false,
  isParticipantsViewWIndowOpen: false,
  roomName: "",
  userName: "",
  emailId: "",
  userLeft: "",
  isLeft: false,
  roomJoined: false,
  notification: {
    type: "default",
    message: "",
  },
  toolBarOptions: {
    showConference: true,
    showEditor: true,
    showVdi: true,
    showVideo: true,
    showAudio: true,
    showScreenShare: true,
    showParticipants: true,
    showChat: true,
    showSettings: true,
    showEnd: true,
    showFullScreen: true,
    showRaiseHand: true,
  },
  toolBarValues: {
    conferenceStatus: false,
    editorStatus: false,
    vdiStatus: false,
    videoStatus: true,
    audioStatus: true,
    screenShareStatus: false,
    participantsStatus: false,
    chatStatus: false,
    settingsStatus: false,
    endStatus: false,
    fullScreenStatus: false,
    raiseHandStatus: false,
  },
  hostDetailsList: [],
  lockMeeting: false,
  skipMeeting: false,
  allowRecording: false,
  setExpiryDateCheckBox: false,
  setExpiryDate: `${new Date(
    new Date().setDate(new Date().getDate() + 1)
  ).toISOString()}`,
  setExpiryDateLoader: false,
  roles: [],
  selectedRole: "",
  openRoleModal: false,
  openKickoutModal: false,
  createdById: "",
  jitsiToken: "",
  selectedUserId: "",
  isNoiseSuppressionActivated: undefined,
};

const slice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    setToolBarOptions(
      state: IRoot,
      action: PayloadAction<{ name: string; value: boolean }>
    ) {
      state.toolBarValues[action.payload.name] = action.payload.value;
    },
    setConferenceInformation(
      state: IRoot,
      action: PayloadAction<{
        userName: string;
        roomName: string;
        emailId: string;
      }>
    ) {
      const { userName, roomName, emailId } = action.payload;
      Object.assign(state, { userName, roomName, emailId });
    },
    setConferenceInstance(
      state: IRoot,
      action: PayloadAction<{ connection: any; status: string }>
    ) {
      state.conference.conferenceInstance = action.payload.connection;
      state.conference.connectionStatus = action.payload.status;
    },
    setRoomInstance(
      state: IRoot,
      action: PayloadAction<{ roomInstance?: any; roomJoined: boolean }>
    ) {
      state.room.roomInstance = action.payload.roomInstance;
      state.roomJoined = action.payload.roomJoined;
    },
    addParticipant(
      state: IRoot,
      action: PayloadAction<{ participant: IParticipant }>
    ) {
      const participants = state.room.participants;
      Object.assign(participants, {
        [action.payload.participant.participantId]: {
          ...action.payload.participant,
        },
      });
      state.room.participants = participants;
    },
    removeParticipant(state: IRoot, action: PayloadAction<{ id: string }>) {
      // const remotetracks= state.room?.participants[action.payload.id]?.tracks['audio']
      // remotetracks.detach(action.payload.id+remotetracks.trackType)
      if (action.payload.id) {
        const participants = state.room.participants;
        state.userLeft = participants[action.payload.id]?.displayName;
        delete participants[action.payload.id];
        state.room.participants = participants;
      }
    },
    addRemoteTrack(state: IRoot, action: PayloadAction<{ track }>) {
      const track = action.payload?.track;
      if (!track) {
        return;
      }
      const trackOwnerId =
        track?.trackOwnerId ||
        track.trackInformationInstance?.getParticipantId();
      state.room.participants[trackOwnerId].tracks[track.trackType] = track;
    },
    addaudioLevel(state: IRoot, action: any) {
      const level = action?.payload?.level;
      const id = action?.payload?.id;
      state.room.participants[id].audioLevel = level;
    },

    removeRemoteTrack(
      state: IRoot,
      action: PayloadAction<{ type; trackOwnerId }>
    ) {
      const { type, trackOwnerId } = action.payload;
      if (type === "desktop" || type === "video") {
        state.room.participants[trackOwnerId].tracks["video"] = {};
        delete state.room.participants[trackOwnerId].tracks["video"];
        state.room.participants[trackOwnerId].tracks["desktop"] = {};
        delete state.room.participants[trackOwnerId].tracks["desktop"];
        return;
      }
      state.room.participants[trackOwnerId].tracks[type] = {};
      delete state.room.participants[trackOwnerId].tracks[type];
    },
    changeRemoteTrackProperties(
      state: IRoot,
      action: PayloadAction<{
        propertyName: string;
        value: true | false;
        trackOwnerId: string;
        trackType: string;
      }>
    ) {
      const { propertyName, value, trackOwnerId, trackType } = action.payload;
      const track = state.room.participants[trackOwnerId]?.tracks[trackType];
      if (track) {
        if (!Object.keys(track).includes(propertyName)) {
          return;
        }
        track[propertyName] = value;
        state.room.participants[trackOwnerId].tracks[trackType] = track;
      }
    },
    addLocalTrack(state: IRoot, action: PayloadAction<{ track }>) {
      state.room.localTracks[action.payload.track.trackType] =
        action.payload.track;
    },
    setSharedAudioTrack(state: any, action: PayloadAction<{track}>){
      state.room.localTracks.sharedAudio = action.payload.track;
    },
    removeLocalTrack(state: IRoot, action: PayloadAction<{ type }>) {
      state.room.localTracks[action.payload.type] = {};
      delete state.room.localTracks[action.payload.type];
    },
    toggleAudio(state: IRoot) {
      state.room.localTracks.audio = {};
    },
    mediaDevices(state: IRoot, action) {
      state.room.mediaDevices = action.payload;
    },
    kickout(
      state: IRoot,
      action: PayloadAction<{ kickout: boolean; id: string }>
    ) {
      state.room.participants[action.payload.id].isKickedOut =
        action.payload.kickout;
    },
    sendNotification(state: IRoot, action: PayloadAction<{ type; message }>) {
      state.notification = {
        type: action.payload.type,
        message: action.payload.message,
      };
    },
    userLeft(state, action) {
      state.isLeft = action.payload;
    },
    hostDetails(state, action) {
      state.hostDetailsList = action.payload;
    },
    setLockMeeting(state, action) {
      state.lockMeeting = action.payload;
    },
    setSkipMeeting(state, action) {
      state.skipMeeting = action.payload;
    },
    setAllowRecording(state, action) {
      state.allowRecording = action.payload;
    },
    setExpiryDate(state, action) {
      state.setExpiryDate = action.payload;
    },
    setExpiryDateCheckBox(state, action) {
      state.setExpiryDateCheckBox = action.payload;
    },
    setExpiryDateLoader(state, action) {
      state.setExpiryDateLoader = action.payload;
    },
    setRoleList(state, action) {
      state.roles = action.payload;
    },
    selectedRole(state, action) {
      state.selectedRole = action.payload;
    },
    openRoleModal(state, action) {
      state.openRoleModal = action.payload;
    },
    openKickoutModal(state, action) {
      state.openKickoutModal = action.payload;
    },
    createdById(state, action) {
      state.createdById = action.payload;
    },
    resetParticipantRoles(state, action) {
      state.room.participants = action.payload;
    },
    roleUpdate(state, action) {
      const { data, participants } = action.payload;

      let replaceObjectId;
      for (const key in participants) {
        const currentObject = participants[key];

        if (currentObject.userId === data?.participantId) {
          replaceObjectId = currentObject?.participantId;
          break;
        }
      }
      if (replaceObjectId) {
        state.room.participants[replaceObjectId].role = data?.roleName;
        state.room.participants[replaceObjectId].eventHost = (data?.roleName === "Host");
      }
    },
    setJitsyToken(state, action) {
      state.jitsiToken = action.payload;
    },
    selectedUserId(state, action) {
      state.selectedUserId = action.payload;
    },
    setNoiseSuppressionActivated(state, action){
      state.isNoiseSuppressionActivated = action.payload;
    },
  },
});

/* eslint-disable */
export const toolBarAction =
  (toolbarNameToModify: string, newValue: boolean): AppThunk =>
    async (dispatch) => {
      dispatch(
        slice.actions.setToolBarOptions({
          name: toolbarNameToModify,
          value: newValue,
        })
      );
    };
export const setConferenceInformation =
  (userName: string, roomName: string, emailId: string): AppThunk =>
    async (dispatch) => {
      dispatch(storeUserName(userName));
      dispatch(storeEmailId(emailId));
      dispatch(
        slice.actions.setConferenceInformation({ userName, roomName, emailId })
      );
    };
const APP_BASE_URL = process.env.REACT_APP_API_URL;
export const createConferenceInstance =
  (JitsiMeetJS, config, eventId): AppThunk =>
    async (dispatch) => {
      const initOptions = {
        disableAudioLevels: false,
        desktopSharingChromeExtId: "mbocklcggfhnbahlnepmldehdhpjfcjp",
        desktopSharingChromeDisabled: false,
        desktopSharingChromeSources: ["screen", "window"],
        // desktopSharingChrome: ["screen", "window"],
        // desktopSharingSourceDevice: ["screen"],
        desktopSharingChromeMinExtVersion: "0.1",
        desktopSharingFirefoxDisabled: true,
        useIPv6: false,
        disableSimulcast: true,
        disableRtx: true,
        disableH264: false,
        preferH264: true,
        enableTcc: true,
      };
      JitsiMeetJS.init(initOptions);
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.INFO);

      const res = await axios.post(`${APP_BASE_URL}/api/v1/conference/generate-token`, { event_id: eventId });
      dispatch(roomPrefix(res?.data?.data?.roomPrefix));
      const token = res?.data?.data?.token;
      
      let connection: any;
      try {
        connection = new JitsiMeetJS.JitsiConnection(
          "einstonlabs4",
          token,
          JSON.parse(JSON.stringify(config))
        );
      } catch (e) {
        throw new Error(e);
      }

      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        () => dispatch(connectionStateAction("SUCCESS", connection))
      );
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        () => dispatch(connectionStateAction("FAILURE", connection))
      );
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        () => dispatch(connectionStateAction("DISCONNECTED", connection))
      );
      connection.connect();
    };
const connectionStateAction =
  (status: string, connection: any): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.setConferenceInstance({ connection, status }));
      // dispatch(
      //   slice.actions.sendNotification({
      //     type: status === 'SUCCESS' ? 'success' : 'error',
      //     message: `CONNECTION ${status}`,
      //   }),
      // )
      setTimeout(() => {
        dispatch(
          slice.actions.sendNotification({
            type: "",
            message: "",
          })
        );
      }, 2000);
    };
const removeConferenceEventListeners =
  (connection, JitsiMeetJS) => async () => {
    connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED
    );
    connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED
    );
    connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED
    );
  };
export const createAndJoinRoom =
  (
    roomName,
    JitsiMeetJS,
    conferenceInstance,
    userName,
    userId,
    name,
    emailId,
    avatar
  ): AppThunk =>
    async (dispatch) => {
      const roomOptions = {
        openBridgeChannel: "true",
        recordingType: "jibri",
      };
      const room = conferenceInstance.initJitsiConference(
        roomName.toLowerCase(),
        roomOptions
      );
      const localUserId = room.myUserId(); // to avoid duplicate tracks in TRACK_ADDED
      room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () =>
        dispatch(conferenceJoinedAction(room))
      );
      room.on(JitsiMeetJS.events.conference.USER_JOINED, (id, user) => {
        dispatch(newUserJoinedAction(user));
      });
      room.on(JitsiMeetJS.events.conference.USER_LEFT, (id) => {
        dispatch(userLeftAction(id));
      });
        room.on(JitsiMeetJS.events.conference.TRACK_ADDED, (track) => {
          // window.alert("track added");
          dispatch(remoteTrackAddedAction(track, localUserId));
          // RenderAudio({track,localUserId})
          // array.push(track)
        });
      // room.on(JitsiMeetJS.events.conference.TRACK_AUDIO_OUTPUT_CHANGED, (id ,newId) =>{
      //   console.log("TRACK_AUDIO_OUTPUT_CHANGED",id,newId);
      // })
      // room.on(JitsiMeetJS.events.conference.TRACK_ADDED, (track)=>{
      //   RenderAudio(track);
      // });
      room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
        dispatch(remoteTrackRemovedAction(track));
      });
      room.on(JitsiMeetJS.events.track.TRACK_STREAMING_STATUS_CHANGED, (track, status) => {
        window.alert(status);
      });
      room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track) => {
        dispatch(remoteTrackMuteChanged(track));
      });
      room.on(
        JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
        (userID, audioLevel) => {
          dispatch(userAudioLevel(userID, audioLevel, localUserId));
        }
      );
      room.setDisplayName(`${userName}/${userId}/${name}/${emailId}/${avatar}`);
      room.join();
    };
/**
 * Executes when the user joins the conference
 * @param room
 * @returns
 */
const conferenceJoinedAction =
  (room): AppThunk =>
    async (dispatch) => {
      const roomJoined: boolean = true;
      const roomInstance: any = room;
      dispatch(slice.actions.setRoomInstance({ roomInstance, roomJoined }));
      setTimeout(() => {
        dispatch(
          slice.actions.setRoomInstance({ roomInstance, roomJoined: false })
        );
      }, 2000);
      JitsiMeetJS.createLocalTracks({
        devices: ["audio"],
      })
        .then(async (tracks: any) => {
          let trackData;
          for (let i = 0; i < tracks.length; i++) {
            trackData = await getTrackData(tracks[i]);
            if (!trackData) {
              return;
            }
            const remote = document.getElementById("localuser");
            if (trackData.trackType === "video") {
              const video = document.createElement("video");
              video.autoplay = false;
              video.id = `localVideo`;
              video.width = 1700;
              video.height = 550;
              remote.appendChild(video);
              tracks[i].attach(document.getElementById(`localVideo`));
            }
            room.addTrack(tracks[i]);
            dispatch(slice.actions.addLocalTrack({ track: trackData }));
          }
        })
        .catch((e) => {
          throw new Error(e);
        });
    };
/**
 * Executes when a new user joined the room
 * @param user
 * @returns
 */
const newUserJoinedAction =
  (user): AppThunk =>
    async (dispatch) => {
      const newParticipant = {
        displayName: user._displayName.split("/")[0],
        userId: user._displayName.split("/")[1],
        eventHost: (user._displayName.split("/")[2] === "Host"),
        role: user._displayName.split("/")[2],
        emailId: user._displayName.split("/")[3],
        participantId: user._id,
        isLocal: false,
        isAudioShared: false,
        isVideoShared: false,
        isScreenShared: false,
        userRole: user._role,
        isHidden: user._hidden,
        tracks: {},
        isKickedOut: false,
        isModerator: false,
        status: "",
        isAudioNoisy: false,
        talkingWhileMuted: false,
        connectionStats: {},
        participantInformationInstance: user,
        audioLevel: null, // instance all
        avatar: user._displayName.split("/").slice(4).join("/")
      };
      dispatch(slice.actions.addParticipant({ participant: newParticipant }));

      const participantValue = JSON.parse(
        JSON.stringify(user?._conference?.room?.members)
      );
      const hostList = [];

      for (const member in participantValue) {
        if (
          !member.includes("focus") &&
          participantValue[member].affiliation === "owner"
        ) {
          hostList.push(member);
        }
      }
      dispatch(slice.actions.hostDetails(hostList));
    };
/**
 * Executes when a new user left action
 * @param id
 * @returns
 */
const userLeftAction =
  (id): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.removeParticipant({ id }));
    };
export const remoteTrackAddedAction =
  (track, localUserId): AppThunk =>
    async (dispatch) => {
      const remoteUserId = track?.getParticipantId();
      if (remoteUserId === localUserId) {
        return;
      }
      const trackData: any = await getTrackData(track);
      try {
        dispatch(slice.actions.addRemoteTrack({ track: trackData }));
      } catch (e) {
        throw new Error(e);
      }
    };

const userAudioLevel =
  (userId: any, audioLevel: any, localUserId: any) => async (dispatch) => {
    if (userId === localUserId) {
      return;
    }
    const payload: any = {
      id: userId,
      level: audioLevel,
    };
    dispatch(slice.actions.addaudioLevel(payload));
  };
const remoteTrackRemovedAction =
  (track): AppThunk =>
    async (dispatch) => {
      if (track.getParticipantId()) {
        const payload = {
          type: "",
          trackOwnerId: "",
        };
        let payloadType;
        if (track.type === "video") {
          if (track.videoType === "desktop") {
            payloadType = "desktop";
          } else {
            payloadType = "video";
          }
        } else {
          payloadType = "audio";
        }
        payload.type = payloadType;
        payload.trackOwnerId = track.getParticipantId();
        dispatch(slice.actions.removeRemoteTrack(payload));
      }
    };
export const remoteTrackMuteChanged =
  (IncomingTrack): AppThunk =>
    (dispatch, getState) => {
      setTimeout(async () => {
        const trackDataType = await IncomingTrack?.getVideoType();
        let trackType;
        if (IncomingTrack.type === "video") {
          if (trackDataType === "desktop") {
            trackType = "desktop";
          } else {
            trackType = "video";
          }
        } else {
          trackType = "audio";
        }
        dispatch(setForceRender());
        const participantId = await IncomingTrack.getParticipantId();
        const room: any = getState().meeting.room;
        let prevTrack: boolean = false;
         Object.values(room?.participants[participantId].tracks).forEach((item: any) => {
          if(item.trackType === trackType) prevTrack=true;
        });
        if(prevTrack){
          dispatch(
            slice.actions.changeRemoteTrackProperties({
              propertyName: "isMuted",
              value: IncomingTrack.isMuted(),
              trackOwnerId: participantId,
              trackType,
            })
          );
        } else {
          const trackData: any = await getTrackData(IncomingTrack);
          dispatch(slice.actions.addRemoteTrack({ track: trackData }));
        }
      }, 1000)
    };
export const videotoggle =
  (localtrack): AppThunk =>
    async () => {
      Object.values(localtrack).forEach((item: any) => {
        if (item.trackType === "video") {
          if (item.trackInformationInstance.isMuted()) {
            item.trackInformationInstance.unmute();
          } else {
            item.trackInformationInstance.mute();
          }
        }
      });
    };
export const audiotoggle =
  (localtrack): AppThunk =>
    async () => {
      localtrack &&
        Object.values(localtrack).forEach((item: any) => {
          if (item.trackType === "audio") {
            if (item.trackInformationInstance.isMuted()) {
              item.trackInformationInstance.unmute();
            } else {
              item.trackInformationInstance.mute();
            }
          }
        });
    };

export const getAvailableDevices = (): AppThunk => (dispatch) => {
  JitsiMeetJS.mediaDevices.enumerateDevices((devices: any) => {
    dispatch(slice.actions.mediaDevices(devices));
  });
};

// export const setSpeaker = (room,localtrack,id): AppThunk => async () => {
// room.roomInstance.setAudioOutput(id)
// localtrack &&
//   Object.values(localtrack).forEach((item: any) => {
//     if (item.trackType === 'audio') {
//       try{
//         item.trackInformationInstance.setAudioOutput({audioOutputDeviceId:id});
// room.roomInstance.setSinkId(id)
// JitsiMeetJS.mediaDevices.setAudioOutputDevice(id);
// console.log("output",JitsiMeetJS.mediaDevices.getAudioOutputDevice())
// item.trackInformationInstance.on(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED);
// room.roomInstance.on(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,(devices)=>{
//       console.log("devices",devices)
// })
//     }catch(error){
//       throw new Error(error);
//     }
//   }
//   // console.log("id check",item.trackInformationInstance.getDeviceId())

// });


export const setMicrophone =
  (localtrack, id): AppThunk =>
    async (dispatch, getState) => {
      const room: any = getState().meeting.room;
      JitsiMeetJS.createLocalTracks({
        // create a new track for the new device id
        devices: ["audio"],
        micDeviceId: "default",
      })
        .then(async (tracks: any) => {
          let trackData;
          for (let i = 0; i < tracks.length; i++) {
            trackData = await getTrackData(tracks[i]);
            if (!trackData) {
              return;
            }
            await room.roomInstance.replaceTrack(localtrack, tracks[i]);
            dispatch(slice.actions.setNoiseSuppressionActivated(false));
            dispatch(slice.actions.addLocalTrack({ track: trackData }));
          }
        })
        .catch((e) => {
          throw new Error(e);
        });
    };

  export const changeMicrophone =
  (localtrack, id): AppThunk =>
    async (dispatch, getState) => {
      const room: any = getState().meeting.room;
      JitsiMeetJS.createLocalTracks({
        // create a new track for the new device id
        devices: ["audio"],
        micDeviceId: id,
      })
        .then(async (tracks: any) => {
          let trackData;
          for (let i = 0; i < tracks.length; i++) {
            trackData = await getTrackData(tracks[i]);
            if (!trackData) {
              return;
            }
            await room.roomInstance.replaceTrack(localtrack.audio.trackInformationInstance, tracks[i]);
            dispatch(slice.actions.setNoiseSuppressionActivated(false));
            dispatch(slice.actions.addLocalTrack({ track: trackData }));
          }
        })
        .catch((e) => {
          throw new Error(e);
        });
    };
export const RemotetrackMute =
  (ID): AppThunk =>
    async (dispatch) => {
      const response = await axios.get(
        `${BASE_URL}/api/v1/events/mute?id=${ID}&action=MUTE&all=false`
      );
      if (response.data.status) {
        dispatch(TrainerNotification(response.data.message));
      }
    };
export const RemotetrackUnmute =
  (ID): AppThunk =>
    async (dispatch) => {
      const response = await axios.get(
        `${BASE_URL}/api/v1/events/mute?id=${ID}&action=UNMUTE&all=false`
      );
      if (response.data.status) {
        dispatch(TrainerNotification(response.data.message));
      }
    };
export const RemotetrackMuteAllAPI =
  (): AppThunk =>
    async (dispatch) => {
      const response = await axios.get(
        `${BASE_URL}/api/v1/events/mute?action=MUTE&all=true`
      );
      if (response.data.status) {
        dispatch(TrainerNotification(response.data.message));
      }
    };
/**
 *! Create a video/audio element and appends it to the owner node. Only for Remote Tracks.
 * @param track | Track
 * @returns
 */
export const createTrackNodeAndAppend =
  (track): AppThunk =>
    async () => {
      const trackOwnerId = track.getParticipantId();
      const trackType = track.getType();
      const trackOwnerContainer = document.getElementById(trackOwnerId);
      const trackId = trackOwnerId + "-" + trackType;
      const newTrack = document.createElement(trackType);
      newTrack.id = trackId;
      newTrack.autoplay = true;
      trackOwnerContainer.appendChild(newTrack);
      track.attach(document.getElementById(trackId));
    };
/* eslint-disable */

export const toggleDesktopTrack = (
  hitUnshareAPI = false,
  sharing = false,
  alreadyScreenShared = false,
): AppThunk => async (dispatch, getState) => {

  const room: any = getState()?.meeting?.room;
    if (sharing) {
    dispatch(startScreenShare(room, alreadyScreenShared));
  } else if (room?.localTracks?.desktop) {
    dispatch(ScreenSharing(false));
    dispatch(stopScreenShare(room, hitUnshareAPI));
  }
};

const startScreenShare =
  (room, alreadyScreenShared): AppThunk =>
  async (dispatch, getState) => {
    const screenTracks: any = await JitsiMeetJS.createLocalTracks({
      devices: ["desktop"],
      // resolution: "720",
      // interval: 500,
      // checkAgain: () => true,
    })
      .then((data) => data)
      .catch((e) => dispatch(ErrorMessage("Permission denied by system")));

    if (screenTracks?.length === 2) {
       if (alreadyScreenShared) {
        dispatch(stopSharingSelf());
      }
      const response = await axios.put(
        `${BASE_URL}/api/v1/events/screen-share/SHARE`
      );
      try {
        if (response.data.status) {
          dispatch(ScreenSharing(true));
          const audioTrackData: any = await getTrackData(screenTracks[0]);
          const trackData: any = await getTrackData(screenTracks[1]);
          if (!trackData) {
           throw new Error("Failed to get track data");
          }

          room.roomInstance.addTrack(screenTracks[1]);
          room.roomInstance.addTrack(screenTracks[0]);
          dispatch(slice.actions.addLocalTrack({ track: trackData }));
          dispatch(
            slice.actions.setSharedAudioTrack({ track: audioTrackData })
          );
        }
      } catch (e) {
        throw new Error(e);
      }
    } else {
      if (screenTracks.length) {
       
        if (alreadyScreenShared) {
          dispatch(stopSharingSelf());
        }
        const response = await axios.put(
          `${BASE_URL}/api/v1/events/screen-share/SHARE`
        );
        try {
          if (response.data.status) {
            dispatch(ScreenSharing(true));
            const trackData: any = await getTrackData(screenTracks[0]);
            if (!trackData) {
             throw new Error("Failed to get track data");
            }
            if(room?.localTracks?.desktop) {
              room?.roomInstance?.replaceTrack(room?.localTracks?.desktop?.trackInformationInstance, screenTracks[0]);
              dispatch(slice.actions.addLocalTrack({ track: trackData }));
            } else {
              room.roomInstance.addTrack(screenTracks[0]);
              dispatch(slice.actions.addLocalTrack({ track: trackData }));
            }
          }
        } catch (e) {
          throw new Error(e);
        }
      }
    }
  };

const stopScreenShare = (room: any, sharingAPIhit: boolean): AppThunk => async (dispatch, getState) => {
  const oldTrack = room.localTracks.desktop;
  const oldAudioTrack = room.localTracks.sharedAudio;
  await oldTrack.trackInformationInstance.mute();
  await room.roomInstance.replaceTrack(oldTrack.trackInformationInstance, null);
  // await oldTrack.trackInformationInstance.dispose();
  // dispatch(removeLocalTrackAction(room.localTracks, "desktop"));

  if(oldAudioTrack) {
    oldAudioTrack.trackInformationInstance.dispose();
    room.roomInstance.removeTrack(oldAudioTrack.trackInformationInstance);
    dispatch(removeLocalTrackAction(room.localTracks, "sharedAudio"));
  }

  // const Room: any = getState().meeting?.room;
  // const sharingID: any = Object.values(Room.participants).filter((value: any) => Object.keys(value?.tracks).includes("desktop")).length;

  // if (sharingID == 0 && sharingAPIhit) {
    await axios.put(`${BASE_URL}/api/v1/events/screen-share/UNSHARE`);
  // }
};

export const removeLocalTrackAction =
  (localTracks, type): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.removeLocalTrack({ type }));
    };

export const leaveRoomAction = (
  roomInstance,
  conferenceInstance,
  userName,
  localTracks,
  room,
): AppThunk => async (dispatch) => {
  if (localTracks.video || localTracks.desktop) {
    dispatch(stopScreenShare(room, true));
  }
  dispatch(removeConferenceEventListeners(conferenceInstance, JitsiMeetJS));
  for (let i = 0; i < localTracks.length; i++) {
    localTracks[i].dispose();
  }
  roomInstance.leave();
  dispatch(clearReduxPersist());
  dispatch(slice.actions.userLeft(true));
  conferenceInstance.disconnect();
  mqttService.closeConnection();
};
export const updateRoomleft = (data: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.userLeft(data));
};

// get roles
export const getRolesAPI = (Id: string, userRole: string) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/events/roles`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        contextId: Id,
      },
    });
    if (response?.status === 200 && response?.data?.status) {
      const filteredRoles = response?.data?.data.filter((role) => role?.name !== userRole);
      dispatch(slice.actions.setRoleList(filteredRoles || []));
      dispatch(slice.actions.selectedRole(filteredRoles[0]?.id || ""));
    } else {
      dispatch(slice.actions.setRoleList([]));
      dispatch(slice.actions.selectedRole(""));
    }
  } catch (error) {
    dispatch(slice.actions.setRoleList([]));
    dispatch(slice.actions.selectedRole(""));
    throw new Error(error);
  }
};

// change role 
export const changeRoleAPI = (id: string, roleId: string, enqueueSnackbar: any, participants: any) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}/api/v1/roles/${id}`, {
      roleId
    });
    
    if (response?.status === 200 && response?.data?.status) {
      enqueueSnackbar(response?.data?.message, {
        variant: "success",
        preventDuplicate: false,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      dispatch(slice.actions.openRoleModal(false));
    } else {
      throw new Error(response?.data?.message || "Failed to change role");
    }
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Failed to change role", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
    throw new Error(error);
  }
};

// kickout participant
export const kickoutparticpant = (id): AppThunk => async (dispatch) => {

  await axios.patch(`${BASE_URL}/api/v1/participants/kick-out/${id}?destroyMachines=true`)
    .then(response => {
      if (response.data.status) {
        dispatch(TrainerNotification(response.data.message));
      } else {
        dispatch(TrainerNotification(response.data.message, "error"));
      }
    }
    ).catch(errorResponse => {
      dispatch(TrainerNotification(errorResponse.message, "error"));
    })

};
const getTrackData = (track) => {
  return new Promise((resolve) => {
    try {
      const trackData: ITrack = {
        trackInformationInstance: track,
        isMuted: track?.isMuted(),
        // muted: track.mute(),
        trackType: track.type,
        // deviceId : track.getDeviceId(),
        trackId: track.track.id,
        trackDeviceName: track.track.label,
        containerAttached: track.containers,
        trackOwnerId: track?.getParticipantId(),
        isLocal: track?.isLocal(),
      };
      setTimeout(async () => {
        const trackVideoType = await track.getVideoType();
        if (track.type === "video" && trackVideoType === "desktop") {
          trackData.trackType = trackVideoType;
        }
        resolve(trackData);
      }, 1000);
    } catch (error) {
      throw new Error(error);
    }
  });
};

export const attachingTrack = (Data) => async (dispatch) => {
  try {
    const trackData: any = await getTrackData(Data);
    dispatch(slice.actions.addRemoteTrack({ track: trackData }));
  } catch (e) {
    throw new Error(e);
  }
};

export const setSkipAndLockMeeting1 =
  (data, id, type, enqueueSnackbar) => async (dispatch) => {
    try {
      const publishResponse = await axios.put(
        `${BASE_URL}/api/v1/events/${id}/preference`,
        data
      );
      if (!publishResponse?.data?.status) {
        throw new Error(publishResponse?.data?.message);
      }
      if (publishResponse?.data?.status) {
        if (type === "lock" || type === "skip") {
          enqueueSnackbar(publishResponse?.data?.message, {
            variant: "success",
            preventDuplicate: true,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
        if (type === "lock") {
          dispatch(slice.actions.setLockMeeting(data.disableWaitingRoom));
        } else if (type === "skip") {
          dispatch(slice.actions.setSkipMeeting(data.skipWaitingRoom));
        } else {
          dispatch(
            slice.actions.setAllowRecording(data.allowParticipantToRecord)
          );
        }
      }
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export const setRecordingExpiryDate =
  (data: any, id: any, enqueueSnackbar: any) => async (dispatch) => {
    try {
      dispatch(slice.actions.setExpiryDateLoader(true));
      const publishResponse = await axios.put(
        `${BASE_URL}/api/v1/events/${id}`,
        data
      );
      if (!publishResponse?.data?.status) {
        throw new Error(publishResponse?.data?.message);
      }
      if (publishResponse?.data?.status) {
        dispatch(slice.actions.setExpiryDateLoader(false));
        dispatch(slice.actions.setExpiryDateCheckBox(false));
        enqueueSnackbar(publishResponse?.data?.message, {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      dispatch(slice.actions.setExpiryDateLoader(false));
      dispatch(slice.actions.setExpiryDateCheckBox(false));
      enqueueSnackbar(error?.message || error || "Failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export const getPreferences = (id: any) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/events?id=${id}`
    );
    if (response?.status === 200) {
      dispatch(slice.actions.setAllowRecording(response?.data.data[0]?.preferences.allowParticipantToRecord));
      dispatch(slice.actions.setExpiryDate(response?.data.data[0]?.recordingExpiryDate));
      dispatch(slice.actions.setLockMeeting(response?.data.data[0]?.preferences.skipWaitingRoom));
      dispatch(slice.actions.setLockMeeting(response?.data.data[0]?.preferences.disableWaitingRoom));
    } else {
      throw new Error();
    }
  } catch (error) {
    throw new Error();
  }
};

export const ParticipantRecordMsg = (enqueueSnackbar, data) => async (dispatch) => {
  const participants = store.getState().meeting?.room?.participants;
  dispatch(slice.actions.roleUpdate({ data: data?.response, participants }));

  const getToken = localStorage.getItem("token");
  if (getToken) {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/refresh-token`, { access_token: localStorage.getItem("token") })
      .then(data => {
        dispatch(setAllowPermission(data.data.data.role));

        localStorage.setItem("token", data.data.data.access_token);
      })
      .catch(err => {
        throw new Error(err);
      });
  }
  (process.env.REACT_APP_MODE === "DEVELOPMENT" && enqueueSnackbar("Participants are allowed to record", {
    variant: "success",
    preventDuplicate: true,
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  }))
};


// clear all states if role is participants
export const clearAllDrawerStates = (data) => async (dispatch) => {
  if (data?.response?.roleName === "Participant") {
    dispatch(setGamificationDrawer(false));
    dispatch(resourceInfo(false));
    dispatch(set360view(false));
  }
};

export const reducer = slice.reducer;
export const meetingActions = slice.actions;
export default slice;
