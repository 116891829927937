import { createSlice } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
import axios from "src/utils/axios";
import defaultAxios from "axios";
import { resourceInfo } from "./AvToolbar";
import { changeState, newTokenUpdate, set360view } from "./editor";
import { storeCurrentTab } from "./myMachines";
import { storeAccessActiveMachine } from "./persistStore";

interface IData {
  id?: string;
  name?: string;
  state?: string;
}
interface IVncDetails {
  data?: IData;
  name?: string;
}
interface IRoot {
  VIEW360: {
    [key: string]: any;
  };
  VIEW360Token: {
    [key: string]: string;
  };
  selectedUser: string;
  VNCDialogStatus: boolean;
  VNCmachineDetails: IVncDetails;
  VNCAccess: boolean;
  is360Accessed: boolean;
  isVDIStarted: {
    key?: string;
    value?: boolean;
  }
}

const initialState: IRoot = {
  VIEW360: {},
  VIEW360Token: {},
  selectedUser: "",
  VNCDialogStatus: false,
  VNCmachineDetails: {},
  VNCAccess: false,
  is360Accessed: false,
  isVDIStarted: {}
};

const slice = createSlice({
  name: "360View",
  initialState,
  reducers: {
    setView360(state, action) {
      state.VIEW360[action.payload.key] = action.payload.value;
    },
    getvdiToken(state, action) {
      state.VIEW360Token[action.payload.key] = action.payload.value;
    },
    selectedUserData(state, action) {
      state.selectedUser = action.payload;
    },
    setDialog(state, action) {
      state.VNCDialogStatus = action.payload;
    },
    setVNCDetails(state, action) {
      state.VNCmachineDetails = action.payload;
    },
    clearData(state, actions) {
      delete state.VIEW360[actions.payload.id];
    },
    setVNCAccess(state, action) {
      state.VNCAccess = action.payload;
    },
    handleIs360ViewAccess(state, action) {
      state.is360Accessed = action.payload;
    },
    handleIsStarted(state, action) {
      state.isVDIStarted = {...state.isVDIStarted, [action.payload.key] : action.payload.value};
    },
    handleResetIsStarted(state) {
      state.isVDIStarted = {};
    }
  },
});

export const ParticipantAction = slice.actions;
const APP_BASE_URL = process.env.REACT_APP_API_URL;

export const View360 =
  (value, ID): AppThunk =>
    async (dispatch) => {
      // Removing the old Token when another PC is called
      dispatch(
        slice.actions.getvdiToken({
          key: ID,
          value: "",
        })
      );

      const response: any = await axios.get(
        `${APP_BASE_URL}/api/v1/service?id=${value}`
      );
      if (response.data.status) {
        dispatch(
          slice.actions.setView360({ key: ID, value: response.data.data[0] })
        );
        const data = [
          {
            serviceId: response.data.data[0].id,
            accessType: "read",
          },
        ];

        const responseToken = await defaultAxios.post(
          `${APP_BASE_URL}/api/v1/aacl/token/generate`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        dispatch(
          slice.actions.getvdiToken({
            key: ID,
            value: responseToken.data.data[0].data.token,
          })
        );
      }
    };

export const generateTokenVDI = (userId, serviceId, isAccessible) => async (dispatch) => {
  const data = [{ accessType: isAccessible === "access" ? "write" : "read", serviceId }];
  const responseToken = await defaultAxios.post(`${APP_BASE_URL}/api/v1/aacl/token/generate`, data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  dispatch(slice.actions.getvdiToken({ key: userId, value: responseToken.data.data[0].data.token }));
};

export const removeToken =
  (ID): AppThunk =>
    async (dispatch) => {
      dispatch(
        slice.actions.getvdiToken({
          key: ID,
          value: "",
        })
      );
    };

export const selectedUsers =
  (Name): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.selectedUserData(Name));
    };

export const VNCDialog =
  (status): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.setDialog(status));
    };

  export const VNCAccess =
  (status): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setVNCAccess(status));
  };

export const VNCDetails =
  (data, name): AppThunk =>
    async (dispatch) => {
      const newValue = {
        data: data,
        name: name,
      };
      dispatch(slice.actions.setVNCDetails(newValue));
    };

export const clearData =
  (id, item): AppThunk =>
    async (dispatch) => {
      dispatch(slice.actions.clearData({ id, item }));
    };

export const AccessMach =
  (id): AppThunk =>
    async (dispatch) => {
      const response: any = await axios.get(
        `${APP_BASE_URL}/api/v1/service?id=${id}`
      );
      if (response.data.status) {
        dispatch(newTokenUpdate());
        dispatch(changeState(false));
        dispatch(set360view(false));
        dispatch(
          storeAccessActiveMachine({
            deviceId: response.data.data[0].id,
            ipAddress: response.data.data[0].dns?.ip,
            accessType: "owner",
            address: response.data.data[0]?.dns?.address,
            os: response.data.data[0]?.properties?.os,
          })
        );
        dispatch(storeCurrentTab(0));
        dispatch(resourceInfo(false));
        dispatch(changeState(true));
      }
    };

    export const handleIs360ViewAccess = (data) => (dispatch) => {
      dispatch(slice.actions.handleIs360ViewAccess(data));
    };

    export const handleIsStarted = (data) => (dispatch) => {
      dispatch(slice.actions.handleIsStarted({key: data.key, value: data.value}));
    };

    export const handleResetIsStarted = () => (dispatch) => {
      dispatch(slice.actions.handleResetIsStarted());
    };

export const reducer = slice.reducer;
export default slice;
