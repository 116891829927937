import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import axios1 from 'axios';
import URLS from 'src/URL.json';
import { setAllowPermission, storeEventLink, storeEventTitle } from './waitingAV';
import { BrandingData, handleIsCollabAvailable, setConference, setSession, setWaitingUser } from './persistStore';
import { getVDIPresenter } from './editor';
import { handleRefreshToken } from 'src/services/UserService';

interface IBranding {
  enabled?: boolean;
  name?: string;
  logo?: string;
  tagline?: string;
  links?: {
    privacy?: string;
    terms?: string;
  };
  callback?: string;
  customDomain?: {
    enabled?: boolean;
    domain?: string;
  };
  email?: string;
}
interface IWaitingRoom {
  eventId: string
  sessionToken: string
  sessionverifiedToken: string
  ptoken: string
  waitingmessage: string
  ftoken: string
  status: string
  Accesstoken: string
  checkLogin: boolean;
  checkMessage1: boolean;
  // email:string,
  // password:string
  waitingDialog: {
    token: string
    name: string
    participant_id: string
    participant_email: string,
    isInvited?: boolean
  }[]
  creditDialog: {
    active: boolean
    message: string
    url: string
  }
  notificationSound: boolean
  eventName?: string;
  raiseHand: {
    active: boolean
    value: {
      name: string
      participant_id: string
    }[]
  };
  userReaction: {
    active: boolean
    value: {
      reaction: string
      participant_id: string
    }[]
  }
  timeLeft: number;
  branding: IBranding;
}

const initialState: IWaitingRoom = {
  eventId: '',
  sessionToken: '',
  sessionverifiedToken: '',
  ptoken: '',
  waitingmessage: '',
  ftoken: '',
  status: 'failed',
  Accesstoken: '',
  checkLogin: false,
  checkMessage1: false,
  //   email:"",
  //   passsword:"",
  waitingDialog: [],
  notificationSound: false,
  creditDialog: {
    active: false,
    message: '',
    url: '',
  },
  eventName: "",
  raiseHand: {
    active: false,
    value: []
  },
  userReaction: {
    active: false,
    value: []
  },
  timeLeft: 5,
  branding: {
    enabled: false,
    name: "",
    tagline: "",
    logo: process.env.REACT_APP_LOADER_LOGO,
    links: {
      privacy: "",
      terms: "",
    },
    callback: "",
    customDomain: {
      enabled: false,
      domain: "",
    },
    email: "",
  },
};

const slice = createSlice({
  name: 'Waiting room',
  initialState,
  reducers: {
    createSession(state, action) {
      state.sessionToken = action.payload;
    },
    verifiedToken(state, action) {
      state.sessionverifiedToken = action.payload;
    },
    pollingToken(state, action) {
      state.ptoken = action.payload;
    },
    waitingMessage(state, action) {
      state.ptoken = action.payload;
    },
    finalToken(state, action) {
      state.ftoken = action.payload;
    },
    joinStatus(state, action) {
      state.status = action.payload;
    },
    storeeventId(state, action) {
      state.eventId = action.payload;
    },
    AccessToken(state, action) {
      state.Accesstoken = action.payload;
    },
    setCheckLogin(state, action) {
      state.checkLogin = action.payload;
    },
    setCheckMessage1(state, action) {
      state.checkMessage1 = action.payload;
    },
    setDialogData(state, action) {
      if (action.payload.key) {
        if (
          state.waitingDialog.find(
            (val) => val.participant_id === action.payload.value.participant_id,
          )
        ) {
          state.waitingDialog.forEach((val, index) => {
            if (val.participant_id === action.payload.value.participant_id) {
              state.waitingDialog[index].token = action.payload.value.token;
            }
          });
        } else {
          state.waitingDialog.push(action.payload.value);
        }
      } else {
        state.waitingDialog = action.payload.value;
      }
      if (state.waitingDialog.length === 0) {
        state.notificationSound = false;
      } else if (state.waitingDialog.length === 1 && !state.notificationSound) {
        state.notificationSound = true;
      }
    },
    removeDialog(state, action) {
      state.waitingDialog = state.waitingDialog.filter(
        (value) => value.participant_id !== action.payload,
      );
    },
    leavingRoom(state, action) {
      state.waitingDialog = state.waitingDialog.filter(
        (value) => value.participant_id !== action.payload,
      );
    },
    storeCreditDialog(state, action) {
      state.creditDialog = action.payload;
    },
    setEventName(state, action) {
      state.eventName = action.payload;
    },
    //raise hand
    setRaiseHandData(state, action) {
      if (action.payload.raise) {
        state.raiseHand.active = true;
        state.raiseHand.value = [...state.raiseHand.value, action.payload.data];
      } else {
        state.raiseHand.value = state.raiseHand.value.filter(value => value.participant_id != action.payload.data);
      }
    },
    setRaiseHandDataClose(state, action) {
      state.raiseHand.active = action.payload;
    },
    //user reaction
    setUserReactionData(state, action) {
      state.userReaction.value = [...state.userReaction.value, action.payload.data];
    },
    setUserReactionClearData(state) {
      state.userReaction.value = [];
    },
    setTimeLeft(state, action) {
      state.timeLeft = action.payload;
    },
    getBrnadingData(state, action) {
      state.branding = action.payload;
    },
  },
});

export const reducer = slice.reducer;
const URL =
  process.env.NODE_ENV !== 'production'
    ? URLS.EL_LOCAL_URL
    : process.env.REACT_APP_EL_URL;
const APP_BASE_URL = process.env.REACT_APP_API_URL;
export const EventId = (data) => async (dispatch) => {
  dispatch(slice.actions.storeeventId(data));
};

export const Verify = (data, session) => async (dispatch) => {
  const eventId = window.location.href.split("/")[4].split('?')[0];
  const verifytoken = {
    token: data,
  };

  const response = await axios.post(
    `${APP_BASE_URL}/api/v1/session/verify`,
    verifytoken,
  );
  dispatch(slice.actions.setEventName(response?.data?.data?.eventName ?? ""));
  dispatch(slice.actions.verifiedToken(response.data.data.token));

  if (!response.data.data.token) {
    window.location.href = `${URL}/source=play?event=${eventId}&session=${session}`;
  }
};

export const waiting = (sessionverifiedToken, id) => async (dispatch) => {
  const data = {
    eventId: id
  };

  const config = {
    headers: { BearerToken: `${sessionverifiedToken}` },
  };

  const response = await axios.post(
    `${APP_BASE_URL}/api/v1/participants/waiting`,
    data,
    config,
  );
  if (response.data.status) {
    dispatch(slice.actions.pollingToken(response.data.data.token));
  } else {
    if (response.data.message === "You have already running session in other browser") {
      dispatch(slice.actions.setCheckLogin(true));
      dispatch(slice.actions.setCheckMessage1(true));
    } else if (response.data.message === "Joining with waiting room is not allowed") {
      dispatch(slice.actions.setCheckLogin(true));
      dispatch(slice.actions.setCheckMessage1(false));
    }
  }
};

export const waiting1 = (id, name, email, ParticipantId) => async (
  dispatch,
) => {
  let data = {};
  if (id === '' && name === '' && email === '' && ParticipantId === '') {
    const tempData = localStorage.getItem('waiting');
    data = JSON.parse(tempData);
  } else {
    data = {
      eventId: id,
      participantId: ParticipantId,
      properties: {
        name: name,
        emailId: email,
      },
    };
  }
  if (data) {
    localStorage.setItem('waiting', JSON.stringify(data));
    const response = await axios.post(
      `${APP_BASE_URL}/api/v1/participants/waiting`,
      data,
    );
    if (response.data.status) {
      dispatch(slice.actions.pollingToken(response.data.data.token));
    } else {
      if (response.data.message === "You have already running session in other browser") {
        dispatch(slice.actions.setCheckLogin(true));
        dispatch(slice.actions.setCheckMessage1(true));
      } else if (response.data.message === "Joining with waiting room is not allowed") {
        dispatch(slice.actions.setCheckLogin(true));
        dispatch(slice.actions.setCheckMessage1(false));
      }
    }
  }
};

export const waiting2 = (sessionverifiedToken, id) => async (dispatch) => {
  const data = {
    eventId: id,
  };
  const config = {
    headers: { BearerToken: `${sessionverifiedToken}` },
  };
  const response = await axios.post(
    `${APP_BASE_URL}/api/v1/participants/waiting?forceJoin=true`,
    data,
    config
  );
  if (response.data.status) {
    dispatch(slice.actions.pollingToken(response.data.data.token));
  } else {
    dispatch(slice.actions.setCheckLogin(true));
  }
};

export const waiting3 = (id, name, email, ParticipantId) => async (
  dispatch,
) => {
  let data = {};
  if (id === '' && name === '' && email === '' && ParticipantId === '') {
    const tempData = localStorage.getItem('waiting');
    data = JSON.parse(tempData);
  } else {
    data = {
      eventId: id,
      participantId: ParticipantId,
      properties: {
        name: name,
        emailId: email,
      },
    };
  }
  if (data) {
    localStorage.setItem('waiting', JSON.stringify(data));
    const response = await axios.post(
      `${APP_BASE_URL}/api/v1/participants/waiting?forceJoin=true`,
      data,
    );
    if (response.data.status) {
      dispatch(slice.actions.pollingToken(response.data.data.token));
    } else {
      dispatch(slice.actions.setCheckLogin(true));
    }
  }
};

export const setCheckLogin1 = (value) => async (dispatch) => {
  dispatch(slice.actions.setCheckLogin(value));
};

let count = 0;

export const polling = (ptoken) => async (dispatch) => {
  if (count === 0 && ptoken) {
    const data = {
      token: ptoken,
    };

    const response = await axios.post(
      `${APP_BASE_URL}/api/v1/participants/polling`,
      data,
    );
    dispatch(slice.actions.joinStatus(response.data.data.status));
    if (response.data.data.status === 'accepted' && count === 0) {
      localStorage.setItem('token', response.data.data.token);
      handleRefreshToken(dispatch);
      count = 1;
      localStorage.removeItem('waiting');
      dispatch(setSession(false));
      dispatch(setWaitingUser(false));
      dispatch(setAllowPermission(response.data.data.role));
      if (response.data.data.presentorVdi && response.data.data.presentorIp) {
        dispatch(getVDIPresenter({
          presenterId: response.data.data.presentorVdi,
          presenterIp: response.data.data.presentorIp
        }, true));
      }
      dispatch(setConference(true));
      dispatch(handleIsCollabAvailable(response.data?.data?.collaboration));
    } else if (response.data.data.status === 'rejected' && count === 0) {
      count = 1;
      localStorage.removeItem('waiting');
    }
  }
};

export const pollingAccept = (tok, num, waitingData = []) => async (
  dispatch,
) => {
  if (tok.length === 1) {
    const data = [
      {
        token: tok[0],
        isAllowed: num,
      },
    ];

    const response = await axios.post(
      `${APP_BASE_URL}/api/v1/participants/accept`,
      data,
    );
    dispatch(slice.actions.finalToken(response.data.data.token));
  } else if (tok.length > 1) {
    const data = [];
    tok.forEach(async (item) => {
      data.push({ token: item.token, isAllowed: num });
    });
    const response = await axios.post(
      `${APP_BASE_URL}/api/v1/participants/accept`,
      data,
    );
    dispatch(slice.actions.finalToken(response.data.data.token));
  }
  dispatch(slice.actions.setDialogData({ key: false, value: waitingData }));
};

export const fetchEvent = (id) => async (dispatch) => {
  await axios
    .get(`${APP_BASE_URL}/api/v1/events?id=${id}`)
    .then((res) => {
      dispatch(storeEventLink(res.data.data[0].inviteLink));
      dispatch(storeEventTitle(res.data.data[0].title));
    });
};

export const storeWaitingDialog = (data) => async (dispatch) => {
  if (
    data.domain === 'PARTICIPANT' &&
    data.response.contextId === window.location.href.split('/')[4]
  ) {
    const value = {
      token: data.response.token,
      name: data.response.participant_name,
      participant_id: data.response.participant_id,
      participant_email: data.response.participant_email,
      isInvited: data.response.isInvited,
    };
    if (data.intent === 'PARTICIPANT_JOIN_REQUEST') {
      dispatch(slice.actions.setDialogData({ key: true, value: value }));
    } else if (data.intent === 'PARTICIPANT_JOIN_UPDATE') {
      dispatch(slice.actions.removeDialog(value.participant_id));
    }
  }
};

export const insufficientCreditsDialog = (data) => async (dispatch) => {
  dispatch(
    slice.actions.storeCreditDialog({
      active: true,
      message: data.response.data.message,
      url: data.response.data.url,
    }),
  );
};

export const clearCreditDialog = () => async (dispatch) => {
  dispatch(
    slice.actions.storeCreditDialog({ active: false, message: '', url: '' }),
  );
};

export const hitWaitingAPI = (VIEW_ALL_PARTICIPANT) => async () => {
  if (VIEW_ALL_PARTICIPANT) {
    setTimeout(async () => {
      await axios.get(`${APP_BASE_URL}/api/v1/participants/waiting`);
    }, 2000);
  }
};

export const LeavingRoomfromWaiting = () => async () => {
  const redirectLink = localStorage.getItem("redirect");
  if (redirectLink) {
    window.location.href = `${redirectLink}`;
  } else {
    if (process.env.NODE_ENV !== 'production') {
      window.location.href = `${URLS.EL_LOCAL_URL}`;
    } else {
      window.location.href = `${URL}`;
    }
  }
};
export const LeavingRoomAfterJoiningApp = (url: string, localUrl: string) => async () => {
  let timer = setTimeout(() => {
    if (process.env.NODE_ENV !== 'production') {
      window.location.href = localUrl;
    } else {
      // window.location.href = `${ APP_BASE_URL/event/}`;
      window.location.href = url;

    }
  }, 30000);

  // Attach event listeners to reset timer on mouse movement
  document.addEventListener('mous&emove', resetTimer);
  document.addEventListener('keydown', resetTimer);

  function resetTimer() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (process.env.NODE_ENV !== 'production') {
        window.location.href = localUrl;
      } else {
        window.location.href = url;
      }
    }, 30000);
  }
};


export const currentlyPresenterScreen = () => async () => {
  await axios.get(`${APP_BASE_URL}/api/v1/participants/get-presentor-vdi`);
};


export const getEventNameAPI = (eventId: string) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${APP_BASE_URL}/api/v1/events?id=${eventId}`
    );
    if (response?.status === 200) {
      dispatch(slice.actions.setEventName(response?.data.data[0]?.title ?? ""));
    } else {
      throw new Error();
    }
  } catch (error) {
    throw new Error();
  }
};

//raise hand
export const storeReaction = (data1, raiseHand) => async (dispatch) => {
  if (
    raiseHand
  ) {
    const value = {
      name: data1.response.participantName,
      participant_id: data1.response.participantId,
    };
    dispatch(slice.actions.setRaiseHandData({ data: value, raise: raiseHand }));
    
  } else {
    const value = data1.response.participantId;
    dispatch(slice.actions.setRaiseHandData({ data: value, raise: raiseHand }));
  }
};

export const storeReactionClose = (data1) => async (dispatch) => {
  dispatch(slice.actions.setRaiseHandDataClose(data1));
};

//user reaction
export const manageUserReaction = (data1) => async (dispatch) => {
  const value = {
    reaction: data1.response.reaction,
    participant_id: data1.response.participantId,
  };
  dispatch(slice.actions.setUserReactionData({ data: value }));
  setTimeout(() => {
    dispatch(slice.actions.setUserReactionClearData());
  }, 3000);
};
export const setTimeLeft = (data) => async (dispatch) => {
  dispatch(slice.actions.setTimeLeft(data));
};

export const createSession = (token, eventid) => async (dispatch) => {
  const event = {
    eventId: eventid
  };
  const response = await axios1.post(`${process.env.REACT_APP_API_URL}/api/v1/session`, event, {
    headers: { Authorization: `Bearer ${token}` }
  },);
  dispatch(slice.actions.createSession(response.data.data.token));
};

export const getBrandingDataAPI = (id) => async (dispatch) => {

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/branding/${id}`);
    if (res.status === 200 && res?.data?.status) {
      const response = res?.data?.data?.branding;
      const obj = {
        enabled: response?.enabled,
        name: response?.name || "",
        tagline: response?.tagline || "",
        logo: (response?.logo && response?.enabled) ? response?.logo : process.env.REACT_APP_LOADER_LOGO,
        links: {
          privacy: (response?.links?.privacy && response?.enabled) ? response?.links?.privacy : `${process.env.REACT_APP_EL_URL}/pages/privacy-policy`,
          terms: (response?.links?.terms && response?.enabled) ? response?.links?.terms : `${process.env.REACT_APP_EL_URL}/pages/terms-and-conditions`,
        },
        callback: response?.callback || "",
        customDomain: {
          enabled: response?.customDomain?.enabled || false,
          domain: response?.customDomain?.domain || "",
        },
        email: response?.email || "",
      };
      try {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          (link as HTMLLinkElement).rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }

        (link as HTMLLinkElement).href = (response?.logo && response?.enabled) ? response?.logo : process.env.REACT_APP_LOADER_LOGO;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log({ error }); 
      }
      dispatch(BrandingData(obj));
    } else {
      dispatch(slice.actions.getBrnadingData(initialState?.branding));
      dispatch(BrandingData(initialState?.branding));
      throw new Error(res?.data?.message || "Failed to fetch branding data!");
    }
  } catch (err) {
    dispatch(slice.actions.getBrnadingData(initialState?.branding));
    dispatch(BrandingData(initialState?.branding));
    throw new Error(err);
  }
};
export default slice;
