import { createSlice } from '@reduxjs/toolkit';
import axios from "../utils/axios";
import { meetingActions } from './meeting';
interface ICommunicatorState {
    eventData?: boolean,
    threadId: number,
  }

const initialState: ICommunicatorState = {
    eventData: true,
    threadId:null
  };

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    getEventDetails (state, action) {
        state.eventData = action.payload;
      },
      getThreadId(state, action){
        state.threadId = action.payload;
      }
  }
});

export const reducer = slice.reducer;

export const getEventDetails = () => async (dispatch) => {
    setTimeout(() => {
        dispatch(slice.actions.getEventDetails(false));
        
    }, 2000);

};

export const getThreadDetails = (data) => async (dispatch) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/events?id=${data}`);
    dispatch(slice.actions.getThreadId(response.data.data[0].threadId));
  dispatch(meetingActions.createdById(response.data.data[0]?.userId));

};

export default slice;
