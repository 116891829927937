import { createSlice } from '@reduxjs/toolkit';

interface IForceUpdate {
    forceRender?: boolean,
}

const initialState: IForceUpdate = {
    forceRender: false
};

const slice = createSlice({
    name: 'forceUpdate',
    initialState,
    reducers: {
        forceUpdate(state) {
            state.forceRender = !state.forceRender;
        }
    }
});

export const reducer = slice.reducer;

export const setForceRender = () => async (dispatch) => {
    dispatch(slice.actions.forceUpdate());
};

export default slice;
