import axios from 'axios';


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_LATENCY_LOC,
  headers: {}
});

axiosInstance.interceptors.request.use(
  async (request) => {
    let token = "";
    try{
      token =localStorage.getItem("token") || "eyJ0eXAiOiJqd3QiLCJhbGciOiJSUzI1NiIsImtpZCI6IldUZzJJay0wMlQ3MHVIODgyRTJLV1ZQNTJZTlIyS3ZnX0g4QW9BeDdlTE0ifQ.eyJleHAiOjE2NTA4ODQ2MTEsImlhdCI6MTY1MDg4MjgxMCwic3ViIjoiMjkwYTg1NGUtZjNhOC00NmU4LTljMTYtOTM4Y2Q3ODcyMWRlIiwibmFtZSI6IkRocnV2IiwiZW1haWwiOiJkaHJ1di5zdWhhZ2l5YUBnbWFpbC5jb20ifQ.ai_XO7qFUehPQk-qO9SkqdZE4ByYlm27JVx30ZpJZXCQKW7t6IUOhcEvWL8Mr20TbhKrXBowAjlqOkQ9KCiZfi1vIhEXdrGb6C8heEjT0JtIIkTkglGuD1-Yh4rf7m5dJEJuf7dEt4iSfMivnPs2-BUFHmlaN0u1exsiY21-VzN2eBUVHjVg60Fh_OZn1jNqSNFxg-Dg6ixsf2JMAf6yZUk3OSpIvwSlqegkz_K4q0WV7z5C5Xx7WloefaUB7zLfmA-rXlcbx5Szc7M7lkMC52lcrEujqRnAaWJpVig7QRiZDGVQtufjSfJJaUNKjQIUr_62wPbCOzJ9tt8rbhGqDA";
    }catch{
      token = "<Not Required>";
    }
    request.headers = {...request.headers, Authorization: `Bearer ${token}`};
    return request;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== `${process.env.REACT_APP_EL_URL}/login` && error.config.url !== `${process.env.REACT_APP_API_URL}/api/v1/aacl/token/generate`) {
      window.location.href = `${process.env.REACT_APP_EL_URL}/login`;
      localStorage.removeItem('token');
    }
    return Promise.reject(
      (error.response && error.response.data) || 'Session Expired!'
    );
  }
);

export default axiosInstance;
