import { combineReducers } from '@reduxjs/toolkit';
import { reducer as messengerReducer } from 'src/slices/messenger';
import { reducer as infraReducer } from 'src/slices/infrastructure';
import { reducer as CommunicatorReducer } from 'src/slices/Communicator';
import { reducer as avToolbarReducer } from 'src/slices/AvToolbar';
import { reducer as eventReducer } from 'src/slices/events';
import { reducer as vdiserviceReducer } from 'src/slices/vdiserviceTable';
import { reducer as serviceAllocationReducer } from 'src/slices/events/Resource_Assignment';
import { reducer as meetingReducer } from 'src/slices/meeting';
import { reducer as presenterReducer } from 'src/slices/presenter';
import { reducer as waitingRoomReducer } from 'src/slices/waitingRoom';
import { reducer as editorReducer } from 'src/slices/editor';
import { reducer as registration } from 'src/slices/registration';
import { reducer as AVSetting } from 'src/slices/waitingAV';
import { reducer as forceUpdate } from 'src/slices/videoToggleupdate';
import { reducer as accessPermissionDialog } from 'src/slices/myMachines';
import { reducer as oprations } from 'src/slices/oprations';
import { reducer as gamification } from 'src/slices/gamification';
import { reducer as codeGamification } from 'src/slices/codeGamification';
import { reducer as gamificationDrawer } from 'src/slices/gamificationDrawer';
import { reducer as persistStore } from 'src/slices/persistStore';
import { reducer as View360 } from "src/slices/360View";
import { reducer as keyshortcuts } from "src/slices/keyshortcuts";
import { reducer as networkState } from "src/slices/networkState";
import { reducer as participants } from "src/slices/participants";

const rootReducer = combineReducers({
  messages: messengerReducer,
  infra: infraReducer.reducer,
  vdiService: vdiserviceReducer.reducer,
  Communicator: CommunicatorReducer,
  avToolbar: avToolbarReducer,
  events: eventReducer,
  resourceAllocation: serviceAllocationReducer.reducer,
  meeting: meetingReducer,
  presenter: presenterReducer,
  waitingRoom: waitingRoomReducer,
  editor: editorReducer,
  registration: registration,
  AVSetting: AVSetting,
  forceUpdate: forceUpdate,
  accessPermissionDialog: accessPermissionDialog,
  oprations: oprations,
  gamification: gamification,
  codeGamification: codeGamification,
  gamificationDrawer: gamificationDrawer,
  persistStore: persistStore,
  VIEW360: View360,
  keyshortcuts: keyshortcuts,
  networkState:networkState,
  participants,
});

export default rootReducer;
