import { createSlice } from '@reduxjs/toolkit';
import axios from "src/utils/axios";
import { addNoteFromAI } from './editor';

interface IPresenter {
    sizing?: { width: number, height: number },
    zoomSizing?: { width: number, height: number },
    recording: boolean,
    searching: boolean,
    sendNote: boolean,
    notePrompt: {
        active: boolean,
        data: string
    },
    activeMachineshot: boolean,
    showRecordingStarted: boolean;
    showRecordingStopped : boolean;
    isPipMouseOver: boolean;
}

const initialState: IPresenter = {
    sizing: { width: 600, height: 400 },
    zoomSizing: { width: 600, height: 400 },
    recording: false,
    searching: false,
    sendNote: false,
    notePrompt: {
        active: false,
        data: ""
    },
    activeMachineshot: false,
    showRecordingStarted: false,
    showRecordingStopped : false,
    isPipMouseOver: false
};

const slice = createSlice({
    name: 'presenter',
    initialState,
    reducers: {
        getSizing(state, action) {
            state.sizing = action.payload;
        },
        getZoomSizing(state, action) {
            state.zoomSizing = action.payload;
        },
        recordingState(state, action) {
            state.recording = action.payload;
        },
        showRecordingStartedDialog(state, action) {
            state.showRecordingStarted = action.payload;
        },
        showRecordingStoppedDialog(state, action) {
            state.showRecordingStopped = action.payload;
        },
        searching(state, action) {
            state.searching = action.payload;
        },
        sendingNote(state, action) {
            state.sendNote = action.payload;
        },
        sendPrompt(state, action) {
            state.notePrompt = action.payload;
        },
        activeMachineScreenshot(state, action) {
            state.activeMachineshot = action.payload;
        },
        handlePIPMouseover(state, action) {
            state.isPipMouseOver = action.payload;
        }
    }
});

export const reducer = slice.reducer;

const BASE_URL = process.env.REACT_APP_API_URL;

export const storeSizing = (data) => async (dispatch) => {
    dispatch(slice.actions.getSizing(data));
};

export const storeZoomSizing = (data) => async (dispatch) => {
    dispatch(slice.actions.getZoomSizing(data));
};

export const recordingStatus = (data) => async (dispatch) => {
    dispatch(slice.actions.recordingState(data));
};

export const showRecordingStartedDialog = (data) => async (dispatch) => {
    dispatch(slice.actions.showRecordingStartedDialog(data));
};

export const showRecordinStoppedDialog = (data) => async (dispatch) => {
    dispatch(slice.actions.showRecordingStoppedDialog(data));
};

export const fetchTheQueryAns = (data) => async (dispatch) => {
    dispatch(slice.actions.searching(true));
    try {
        const response = await axios.patch(`${BASE_URL}/api/v1/events/note-query`, data);
        if (response.data.status) {
            dispatch(addNoteFromAI(window.btoa(response.data.data[0]?.text)));
        }
    } catch (error) {
        throw new Error("Failed to fetch the data");
    } finally {
        dispatch(slice.actions.searching(false));
    }

};

export const sendContentToParticipant = (data) => async (dispatch) => {
    dispatch(slice.actions.sendingNote(true));
    const response = await axios.post(`${BASE_URL}/api/v1/events/send-note`, { text: data });
    if (response.data.status) {
        setTimeout(() => {
            dispatch(slice.actions.sendingNote(false));
        }, 1000);
    }
};

export const screenshotActiveMachine = (data) => async (dispatch) => {
    dispatch(slice.actions.activeMachineScreenshot(data));
};

export const notePromptSend = (value, data) => async (dispatch) => {
    dispatch(slice.actions.sendPrompt({ active: value, data: data }));
};

export const handlePIPMouseover = (data) => (dispatch) => {
    dispatch(slice.actions.handlePIPMouseover(data));
};

export default slice;
