import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import defaultAxios from "axios";
import { closePIPWindow } from "./Communicator";
import { setLastView, storeAccessActiveMachine, ELastView, showNotification } from "./persistStore";
import { storeVdiSharingName } from "./waitingAV";
interface IVdiInfo {
  access?: string;
  device?: string;
  ip?: string;
  address?: string;
  os?: string;
}

interface IVdiInfoRead {
  access?: string;
  device?: string;
  ip?: string;
  address?: string;
  os?: string;
}

interface ICursorPosition {
  participantId?:{
    pid?: string;
    pname?:string;
    lc?:{
      x?:string;
      y?:string;
    };
    bottom?:string;
    height?:string;
    left?:string;
    right?:string;
    top?:string;
    width?:string;
    reactX?: string;
    reactY?: string;
  };
}
interface IEditor {
  toggleEditor: boolean;
  vdiToken: string;
  vdiInfo: IVdiInfo;
  vdistate: boolean;
  set360View: boolean;
  presenterIcon: boolean;
  internalPositionCheck: boolean;
  vdiInfoRead: IVdiInfoRead;
  vdiReadToken: string;
  snackMsg: {
    active: boolean;
    value: string;
  };
  textEditorMsg: string;
  autoSaved: boolean;
  openAccessControlInfo: boolean;
  cursorPosition: ICursorPosition;
  isPresenting: boolean;
  showNotificationTimeIdData: string;
}

const initialState: IEditor = {
  toggleEditor: false,
  vdiToken: "",
  vdiInfo: {},
  vdistate: false,
  set360View: false,
  presenterIcon: false,
  internalPositionCheck: false,
  vdiInfoRead: {},
  vdiReadToken: "",
  snackMsg: {
    active: false,
    value: "",
  },
  textEditorMsg: "",
  autoSaved: false,
  openAccessControlInfo: false,
  cursorPosition: {},
  isPresenting: false,
  showNotificationTimeIdData: ""
};

const slice = createSlice({
  name: "presenter",
  initialState,
  reducers: {
    getEditor(state, action) {
      state.toggleEditor = action.payload;
    },
    getvdiToken(state, action) {
      state.vdiToken = action.payload;
    },
    getvdiInfo(state, action) {
      state.vdiInfo = action.payload;
    },
    stateChange(state, action) {
      state.vdistate = action.payload;
    },
    set360View(state, action) {
      state.set360View = action.payload;
    },
    enablePresenter(state, action) {
      state.presenterIcon = action.payload;
    },
    getvdiTokenRead(state, action) {
      state.vdiReadToken = action.payload;
    },
    getVDIInfo(state, action) {
      state.vdiInfoRead = action.payload;
    },
    updateVDIInfoDevice(state, action) {
      state.vdiInfoRead.device = action.payload;
    },
    storeSnakMsg(state, action) {
      state.snackMsg = action.payload;
    },
    setNotes(state, action) {
      state.textEditorMsg = action.payload;
    },
    setAutoSaved(state, action) {
      state.autoSaved = action.payload;
    },
    openAccessControlInfo(state, action) {
      state.openAccessControlInfo = action.payload;
    },
    addNote(state, action) {
      const str1 = window.atob(state.textEditorMsg);
      const str2 = window.atob(action.payload);
      state.textEditorMsg = window.btoa(str1.concat(str2));
    },
    positionCheck(state, action) {
      state.internalPositionCheck = action.payload;
    },
    cursorPositionCheck(state, action) {
      state.cursorPosition[action.payload.id] = action.payload.value;
    },
    isPresenting(state, action) { 
      state.isPresenting = action.payload;
    }, 
    showNotificationTimeId(state, action) {
      state.showNotificationTimeIdData = action.payload;
    },
    resetCursorPosition(state) {
      state.cursorPosition = {};
    }
  },
});

export const reducer = slice.reducer;
const APP_BASE_URL = process.env.REACT_APP_API_URL;

export const getvdiInfo = (data) => async (dispatch) => {
  if (data !== "") {
    dispatch(slice.actions.getvdiInfo(data));
  }
};

export const getTokenByclickingPresenter = (active = []) => async (dispatch) => {

  if (active.length > 0) {
    const data = [{
      serviceId: active[0].deviceId,
      accessType: active[0].accessType === "view_only" ? "read" : "write"
    }];

    const response = await defaultAxios.post(
      `${APP_BASE_URL}/api/v1/aacl/token/generate`, data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch(slice.actions.getvdiToken(response.data.data[0].data.token));
  }
};

export const changeState = (data) => async (dispatch) => {
  dispatch(setLastView(data ? ELastView.MACHINES : ELastView.CONFERENCE));
  dispatch(slice.actions.stateChange(data));
};

export const set360view = (data) => async (dispatch) => {
  if (data) {
    dispatch(setLastView(ELastView.VIEW360));
  }
  dispatch(slice.actions.set360View(data));
};

export const presenterEnabled = (data, persistStore) => async (dispatch) => {
  if (
    persistStore.activeMachine.length > 0 &&
    persistStore.lastView === ELastView.MACHINES &&
    persistStore.activeMachine[0].deviceId === data.response.data.serviceId &&
    data.response.data.updateUI
  ) {
    dispatch(
      getvdiInfo({
        device: data.response.data.serviceId,
        access: data.response.data.access,
        ip: data.response.data.ipAddress || persistStore.activeMachine[0].ipAddress,
        os: data?.response?.data?.os || persistStore.activeMachine[0].os,
        address: data?.response?.data?.address || persistStore.activeMachine[0].address,
      })
    );
    dispatch(storeAccessActiveMachine({
      deviceId: data.response.data.serviceId,
      accessType: data.response.data.access,
      ipAddress: data.response.data.ipAddress || persistStore.activeMachine[0].ipAddress,
      os: data?.response?.data?.os || persistStore.activeMachine[0].os,
      address: data?.response?.data?.address || persistStore.activeMachine[0].address,
    }));

    dispatch(slice.actions.getvdiToken(""));
    const tokenData = [
      {
        serviceId: data.response.data.serviceId,
        accessType:
          data.response.data.access === "view_only" ? "read" : "write",
      },
    ];
    const response = await defaultAxios.post(
      `${APP_BASE_URL}/api/v1/aacl/token/generate`,
      tokenData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    if (response.data.data[0].data.token) {
      dispatch(slice.actions.getvdiToken(response.data.data[0].data.token));
    }
  }
  dispatch(slice.actions.enablePresenter(true));
};

export const getVDIPresenter =
  (data, value = false) =>
    async (dispatch) => {
      if (data !== "" && !value) {
        dispatch(
          slice.actions.getVDIInfo({
            device: data.response.data.serviceId,
            access: "",
            ip: data.response.data.ipAddress,
            os: data?.response?.data?.presentorOs,
            address: data?.response?.data?.presenterAddress
          })
        );
        dispatch(slice.actions.getvdiTokenRead(""));
        dispatch(closePIPWindow(false));
      }
      else if (value) {
        if (data.presenterId && data.presenterIp) {
          dispatch(
            slice.actions.getVDIInfo({
              device: data.presenterId,
              access: "",
              ip: data.presenterIp,
              os: data?.presenterOs,
              address: data?.presenterAddress
            })
          );
          dispatch(closePIPWindow(false));
        }
      }
    };

export const getReadTokenByClickingPIP = (VDI) => async (dispatch) => {
  if (VDI.device) {
    const payload = [
      {
        serviceId: VDI.device,
        accessType: "read",
      },
    ];
    const response = await defaultAxios.post(
      `${APP_BASE_URL}/api/v1/aacl/token/generate`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch(slice.actions.getvdiTokenRead(response.data.data[0].data.token));
  }
};

export const showNotificationTimeId = (data) => (dispatch) => {
  dispatch(slice.actions.showNotificationTimeId(data));
};

export const presenterAccess = (data) => async (dispatch) => {
  await axios.put(`${APP_BASE_URL}/api/v1/access/make-presenter-vdi/${data.device}`);
  dispatch(slice.actions.getVDIInfo({ access: "", device: data.device, ip: data.ip, os: data?.os, address: data?.address }));
  const id = setTimeout(() => {
    dispatch(showNotification("present"));
  }, 1000);
  dispatch(showNotificationTimeId(id));
};

export const removePresenterAccess = (data) => async (dispatch, getState) => {
  const respone = await axios.delete(
    `${APP_BASE_URL}/api/v1/access/remove-presenter-vdi/${data}`
  );

  if (respone.data.status) {
    dispatch(slice.actions.getVDIInfo({}));
  }
  const id = getState().editor.showNotificationTimeIdData;
  clearTimeout(id);
  dispatch(showNotification(""));
};

export const removePresenterAccessUpdate = () => async (dispatch) => {
  dispatch(slice.actions.getvdiTokenRead(""));
  dispatch(slice.actions.getVDIInfo({}));
};

export const removeToken = () => async (dispatch) => {
  dispatch(slice.actions.getvdiTokenRead(""));
};
export const newTokenUpdate = () => async (dispatch) => {
  dispatch(slice.actions.getvdiToken(""));
};

export const setSnakMsg = (snack, data) => async (dispatch) => {
  if (snack) {
    dispatch(storeVdiSharingName(data.response.data.participant_name));
    dispatch(
      slice.actions.storeSnakMsg({
        active: true,
        value: `${data.response.data.participant_name} sharing the VDI`,
      })
    );
  } else {
    dispatch(
      slice.actions.storeSnakMsg({
        active: true,
        value: `Stopped sharing the VDI`,
      })
    );
  }
  dispatch(slice.actions.isPresenting(snack));
  setTimeout(() => {
    dispatch(slice.actions.storeSnakMsg({ active: false, value: "" }));
  }, 3000);
};

export const storeNotes = (data) => async (dispatch) => {
  dispatch(slice.actions.setNotes(data));
};

export const fetchNoteData = (id) => async (dispatch) => {
  const response = await axios.get(
    `${APP_BASE_URL}/api/v1/events/notes?id=${id}`
  );
  if (response.data.status) {
    dispatch(slice.actions.setNotes(response.data.data[0]?.text || ""));
  }
};

export const storeNotesInDb = (data) => async (dispatch) => {
  const response = await axios.put(`${APP_BASE_URL}/api/v1/events/notes/1`, {
    text: data,
  });
  if (response.data.status) {
    dispatch(slice.actions.setAutoSaved(true));
    setTimeout(() => {
      dispatch(slice.actions.setAutoSaved(false));
    }, 1000);
  }
};

export const accessControlInfo = (data) => async (dispatch) => {
  dispatch(slice.actions.openAccessControlInfo(data));
};

export const addNoteFromAI = (data) => async (dispatch) => {
  dispatch(slice.actions.addNote(data));
};
export const setPositionCheck = (data) => async (dispatch) => {
  dispatch(slice.actions.positionCheck(data));
};

export const setCursorPosition = (data) => async (dispatch) => {
  dispatch(slice.actions.cursorPositionCheck({id:data.pid, value:data}));
};

export const resetCursorPosition = () => (dispatch) => {
  dispatch(slice.actions.resetCursorPosition());
};

export default slice;
