import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import { getSavedQuestions, handleResultsTime } from "./gamificationDrawer";

const APP_BASE_URL = process.env.REACT_APP_API_URL;
interface ITestcase {
  explanation: string;
  score_deduction: boolean;
  score: number;
  input: string;
  output: string;
  is_example: boolean;
  disable: boolean;
  is_corner_case: boolean;
  test_case_passed: boolean;
  test_case_id: number;
}

interface ILanguage {
  id: string;
  name: string;
}

interface IGetQuestion {
  id: string;
  type: string;
  level: string;
  score: string;
  time: string;
  details: boolean;
  difficulty: string;
  question: string;
  info: string;
  subType: string;
  test_case_id: number;
}

interface IGetOneQuestion {
  type: string;
  parentId: string;
  question: string;
  info: string;
  score: number;
  isRequired: boolean;
  time: number;
  difficulty: string,
  codes: [
    {
      language_id: number;
      language_name: string;
      code: string;
      solution: string;
    }
  ];
  test_cases: [
    {
      explanation: string;
      score_deduction: boolean;
      score: number;
      input: string;
      output: string;
      is_example: boolean;
      disable: boolean;
      is_corner_case: boolean;
      test_case_passed: boolean;
      test_case_id: string;
    }
  ];
}

interface ICodeQuestion {
  normalTestCase?: ITestcase[];
  language?: ILanguage[];
  getQuestion: IGetQuestion[];
  getParticularQuestion: IGetOneQuestion[];
  metaData: {
    items: number;
    page: number;
    pages: number;
    pagesize: number;
  };
  id: string;
  validated: boolean;
  checkTestCase: boolean;
  testLoader: boolean;
  codeBoolean: boolean;
  searchBoolean: boolean;
  codeLength: number;
}

const initialState: ICodeQuestion = {
  normalTestCase: [],
  language: [],
  getQuestion: [],
  getParticularQuestion: [],
  metaData: {
    items: 0,
    page: 1,
    pages: 1,
    pagesize: 5,
  },
  id: "",
  validated: false,
  checkTestCase: false,
  testLoader: false,
  codeBoolean: false,
  searchBoolean: true,
  codeLength: 0,
};

const slice = createSlice({
  name: "codeQuestion",
  initialState,
  reducers: {
    setNormalTestCase(state, action) {
      state.normalTestCase = action.payload;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setGetQuestion(state, action) {
      state.getQuestion = action.payload;
    },
    setOneQuestion(state, action) {
      state.getParticularQuestion = action.payload;
    },
    setpagination(state, action) {
      state.metaData = action.payload;
    },
    setPage(state, action) {
      state.metaData.page = action.payload;
    },
    setPageSize(state, action) {
      state.metaData.pagesize = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    setCodeBoolean(state, action) {
      state.codeBoolean = action.payload;
    },
    setSearchBoolean(state, action) {
      state.searchBoolean = action.payload;
    },
    setCodeLength(state, action) {
      state.codeLength = action.payload;
    },
    setCheckTestCase(state, action) {
      state.checkTestCase = action.payload;
    },
    setValidated(state, action) {
      state.validated = action.payload;
    },
    setTestLoader(state, action) {
      state.testLoader = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const normalTestCase1 = (list) => async (dispatch) => {
  dispatch(slice.actions.setNormalTestCase(list));
};
export const setId1 = (id) => async (dispatch) => {
  dispatch(slice.actions.setId(id));
};
export const setCodeBoolean1 = (boolValue) => async (dispatch) => {
  dispatch(slice.actions.setCodeBoolean(boolValue));
};
export const setCodeLength1 = (Value) => async (dispatch) => {
  dispatch(slice.actions.setCodeLength(Value));
};

export const setSearchBoolean1 = (Value) => async (dispatch) => {
  dispatch(slice.actions.setSearchBoolean(Value));
};

export const setValidated1 = (Value) => async (dispatch) => {
  dispatch(slice.actions.setValidated(Value));
};

export const setLanguage1 = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${APP_BASE_URL}/api/v1/question/languages`
    );
    const list = [];
    response.data.data.map((item) => {
      list.push(item);
    });
    dispatch(slice.actions.setLanguage(list));
  } catch (error) {
    throw new Error(error);
  }
};

export const submitCodeQuestion =
  (enqueueSnackbar, data, pageSize, page) => async (dispatch) => {
    try {
      const submitQuestionResponse = await axios.post(
        `${APP_BASE_URL}/api/v1/question`,
        data
      );

      if (submitQuestionResponse?.data?.status) {
        enqueueSnackbar("Question Saved successfully", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        dispatch(getParticularQuestion1(submitQuestionResponse.data.data));
        dispatch(slice.actions.setId(submitQuestionResponse.data.data));
        dispatch(getSavedCodeQuestions(pageSize, page, enqueueSnackbar));
      }

      if (!submitQuestionResponse?.data?.status) {
        throw new Error(submitQuestionResponse?.data?.message);
      }
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Question creation failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export const getSavedCodeQuestions = (pageSize, page, enqueueSnackbar) => async (dispatch) => {
  try {
    const savedQuestionCodeResponse = await axios.get(
      `${APP_BASE_URL}/api/v1/question?type=code&meta=true&pageSize=${pageSize}&page=${page}&status=draft&subType=system`
    );
    if (!savedQuestionCodeResponse?.data?.status) {
      throw new Error(savedQuestionCodeResponse?.data?.message);
    }
    const metaData = {
      items: savedQuestionCodeResponse.data.metaData.items,
      page: savedQuestionCodeResponse.data.metaData.page,
      pages: savedQuestionCodeResponse.data.metaData.pages,
      pagesize: savedQuestionCodeResponse.data.metaData.pagesize,
    };
    dispatch(slice.actions.setpagination(metaData));
    dispatch(
      slice.actions.setGetQuestion(savedQuestionCodeResponse?.data?.data)
    );
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Unable to fetch saved question",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const getSearchCodeQuestions = (pageSize, page, question, enqueueSnackbar) => async (dispatch) => {
  try {
    const savedQuestionCodeResponse = await axios.get(
      `${APP_BASE_URL}/api/v1/question?type=code&meta=true&pageSize=${pageSize}&page=${page}&question=${question}&status=draft`
    );
    if (!savedQuestionCodeResponse?.data?.status) {
      throw new Error(savedQuestionCodeResponse?.data?.message);
    }
    const metaData = {
      items: savedQuestionCodeResponse.data.metaData.items,
      page: savedQuestionCodeResponse.data.metaData.page,
      pages: savedQuestionCodeResponse.data.metaData.pages,
      pagesize: savedQuestionCodeResponse.data.metaData.pagesize,
    };
    dispatch(slice.actions.setpagination(metaData));
    dispatch(
      slice.actions.setGetQuestion(savedQuestionCodeResponse?.data?.data)
    );
    dispatch(setSearchBoolean1(false));
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Unable to fetch saved question",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const getFilterCodeQuestions = (pageSize, page, type, value, enqueueSnackbar) => async (dispatch) => {
  let linkData = "";
   linkData = `${APP_BASE_URL}/api/v1/question?type=code&meta=true&pageSize=${pageSize}&page=${page}&${type}=${value}&status=draft`;
   if(type === "All"){
    linkData = `${APP_BASE_URL}/api/v1/question?type=code&meta=true&pageSize=${pageSize}&page=1&status=draft`;
   }
  try {
    const savedQuestionCodeResponse = await axios.get(
      linkData
    );
    if (!savedQuestionCodeResponse?.data?.status) {
      throw new Error(savedQuestionCodeResponse?.data?.message);
    }
    const metaData = {
      items: savedQuestionCodeResponse.data.metaData.items,
      page: savedQuestionCodeResponse.data.metaData.page,
      pages: savedQuestionCodeResponse.data.metaData.pages,
      pagesize: savedQuestionCodeResponse.data.metaData.pagesize,
    };
    dispatch(slice.actions.setpagination(metaData));
    dispatch(
      slice.actions.setGetQuestion(savedQuestionCodeResponse?.data?.data)
    );
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Unable to fetch saved question",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const addCodeQuestion =
  (testId, id, enqueueSnackbar) => async (dispatch) => {
    try {
      const publishResponse = await axios.put(
        `${APP_BASE_URL}/api/v1/test/${testId}/add-question/${id}`
      );
      if (!publishResponse?.data?.status) {
        throw new Error(publishResponse?.data?.message);
      }
      if (publishResponse?.data?.status) {
        enqueueSnackbar("Question added successfully", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
      dispatch(getParticularQuestion1(publishResponse?.data?.data?.id));
      dispatch(getSavedQuestions(enqueueSnackbar));
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Question add failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
export const addCodeQuestionPublish =
  (testId, id, enqueueSnackbar, codeLength, pageSize, time) => async (dispatch) => {
    try {
      const publishResponse = await axios.put(
        `${APP_BASE_URL}/api/v1/test/${testId}/add-question/${id}`
      );
      if (!publishResponse?.data?.status) {
        throw new Error(publishResponse?.data?.message);
      }
      dispatch(publishCodeQuestion(publishResponse?.data?.data?.id, enqueueSnackbar, codeLength+1, pageSize, time));
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Question add failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export const getParticularQuestion1 = (id) => async (dispatch) => {
  dispatch(slice.actions.setId(id));
  try {
    const response = await axios.get(
      `${APP_BASE_URL}/api/v1/question?id=${id}`
    );
    dispatch(slice.actions.setOneQuestion(response?.data?.data));
    dispatch(slice.actions.setCheckTestCase(false));
  } catch (error) {
    throw new Error(error);
  }
};

export const publishCodeQuestion =
  (id, enqueueSnackbar, codeLength, pageSize, time) => async (dispatch) => {
    try {
      const publishResponse = await axios.put(
        `${APP_BASE_URL}/api/v1/question/${id}/publish`
      );
      if (!publishResponse?.data?.status) {
        throw new Error(publishResponse?.data?.message);
      }
      if (publishResponse?.data?.status) {
        enqueueSnackbar("Question published successfully", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        dispatch(handleResultsTime(time));
        dispatch(slice.actions.setCodeLength(codeLength+1));
        dispatch(getSavedCodeQuestions(pageSize,1,enqueueSnackbar));
      }
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Failed to publish", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export const clearParticularQuestion = () => async (dispatch) => {
  dispatch(slice.actions.setOneQuestion([]));
};

export const setCheckTestCase1 = () => async (dispatch) => {
  dispatch(slice.actions.setCheckTestCase(false));
};

export const accessMetaData = (pageSize, data) => async (dispatch) => {
  dispatch(slice.actions.setPage(data));
  dispatch(slice.actions.setPageSize(pageSize));
};
export const sortSavedCodeQuestions = (arr) => async (dispatch) => {
  dispatch(
    slice.actions.setGetQuestion(arr)
  );
};

export const updateCodingQuestion = (id, data, enqueueSnackbar) => async () => {
  try {
    const publishResponse = await axios.put(
      `${APP_BASE_URL}/api/v1/question/${id}`, data
    );
    if (!publishResponse?.data?.status) {
      throw new Error(publishResponse?.data?.message);
    }
    if (publishResponse?.data?.status) {
      enqueueSnackbar("Question updated successfully", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Question update failed", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const updateCodingQuestion1 = (id, data, enqueueSnackbar, pageSize, page) => async (dispatch) => {
  try {
    const publishResponse = await axios.post(
      `${APP_BASE_URL}/api/v1/question/${id}/code`, data
    );
    if (!publishResponse?.data?.status) {
      throw new Error(publishResponse?.data?.message);
    }
    if (publishResponse?.data?.status) {
      enqueueSnackbar("Question updated successfully", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      dispatch(getSavedCodeQuestions(pageSize, page, enqueueSnackbar));
      dispatch(getParticularQuestion1(id));
    }
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Question update failed", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const addCodeTestCase =
  (id, enqueueSnackbar) => async (dispatch) => {
    const data = {
      explanation: "",
      score_deduction: false,
      score: 10,
      input: "",
      output: "",
      is_example: true,
      disable: false,
      is_corner_case: false,
    };
    try {
      const submitQuestionResponse = await axios.post(
        `${APP_BASE_URL}/api/v1/question/${id}/test_case`,
        data
      );

      if (submitQuestionResponse?.data?.status) {
        enqueueSnackbar("Testcase added successfully", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

      }
      dispatch(getParticularQuestion1(id));
      if (!submitQuestionResponse?.data?.status) {
        throw new Error(submitQuestionResponse?.data?.message);
      }
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Testcase creation failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export const testTestCase =
  (id, data, enqueueSnackbar, list) => async (dispatch) => {
    try {
      dispatch(slice.actions.setTestLoader(true));
      const submitQuestionResponse = await axios.post(
        `${APP_BASE_URL}/api/v1/question/validate/${id}`,
        data
      );
      if (submitQuestionResponse?.data?.status) {
        enqueueSnackbar("Testcase checked successfully", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        dispatch(slice.actions.setCheckTestCase(true));
        const data1 = submitQuestionResponse.data.data.submission;
        const list2 = [];
        data1.map((item, i) => {
          list2.push(
            {
              explanation: list[i]?.explanation,
              score_deduction: list[i]?.score_deduction,
              score: list[i]?.score,
              input: list[i]?.input,
              output: list[i]?.output,
              is_example: list[i]?.is_example,
              disable: list[i]?.disable,
              is_corner_case: list[i]?.is_corner_case,
              test_case_passed: item?.test_case_passed,
              test_case_id: item?.test_case_id,
            }
          );
        });
        dispatch(slice.actions.setNormalTestCase(list2));
        dispatch(slice.actions.setValidated(submitQuestionResponse.data.data.validated));
      }
      if (!submitQuestionResponse?.data?.status) {
        throw new Error(submitQuestionResponse?.data?.message);
      }
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Testcase checking failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    dispatch(slice.actions.setTestLoader(false));
  };

export const updateTestCase1 =
  (testCaseId, id, data, enqueueSnackbar) => async (dispatch) => {
    try {
      const updateTestcaseResponse = await axios.put(
        `${APP_BASE_URL}/api/v1/question/${id}/test-case/${testCaseId}`,
        data
      );
      if (updateTestcaseResponse?.data?.status) {
        enqueueSnackbar("Testcase updated successfully", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        dispatch(getParticularQuestion1(id));
      }
      if (!updateTestcaseResponse?.data?.status) {
        throw new Error(updateTestcaseResponse?.data?.message);
      }
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Testcase updating failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export const deleteTestCase1 =
  (testCaseId, id, enqueueSnackbar) => async (dispatch) => {
    try {
      const updateTestcaseResponse = await axios.delete(
        `${APP_BASE_URL}/api/v1/question/${id}/test-case/${testCaseId}`);
      if (updateTestcaseResponse?.data?.status) {
        enqueueSnackbar("Testcase delete successfully", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        dispatch(getParticularQuestion1(id));
      }
      if (!updateTestcaseResponse?.data?.status) {
        throw new Error(updateTestcaseResponse?.data?.message);
      }
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Testcase deletion failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export default slice;
