export const notFoundPageCover = "https://cdn.einstonlabs.com/static/playground/404-page.svg";
export const alreadyInvitedCover = "https://cdn.einstonlabs.com/static/playground/already-invited-to-event-cover.svg";
export const noActivitiesFound = "https://cdn.einstonlabs.com/static/playground/no-activities-found.svg";
export const noMachineGroupsFoundCover = "https://cdn.einstonlabs.com/static/playground/no-machine-groups-found-cover.svg";
export const noMachinesAllocatedCover = "https://cdn.einstonlabs.com/static/playground/no-machines-allocated-cover.svg";
export const noMachinesAssigned = "https://cdn.einstonlabs.com/static/playground/no-machines-assigned-cover.svg";
export const noParticipantsFound = "https://cdn.einstonlabs.com/static/playground/no-participants-found-cover.svg";
export const noParticipantsResultFound = "https://cdn.einstonlabs.com/static/playground/no-participants-result-found-cover.svg";
export const noQuestionsFound = "https://cdn.einstonlabs.com/static/playground/no-questions-found-cover.svg";
export const noResultsFound = "https://cdn.einstonlabs.com/static/playground/no-results-found-cover.svg";
export const notAllowedToEventCOver = "https://cdn.einstonlabs.com/static/playground/not-allowed-to-event-cover.svg";
export const noTestCasesFound = "https://cdn.einstonlabs.com/static/playground/no-test-cases-found-cover.svg";
export const registrationPageCOver = "https://cdn.einstonlabs.com/static/playground/registration-page-cover.svg";
export const somethingWentWrongCover = "https://cdn.einstonlabs.com/static/playground/something-went-wrong.svg";
