import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface INetworkState {
  speed: number;
  latency: number | null
  speedLoading: boolean
}

const initialState: INetworkState = {
  speed: 0,
  latency: null,
  speedLoading: false,
};

export const slice = createSlice({
  name: 'speed',
  initialState,
  reducers: {
    setSpeed: (state, action: PayloadAction<number>) => {
      state.speed = action.payload;
    },
    setLatency: (state, action: PayloadAction<number | null>) => {
      state.latency = action.payload;
  
    },
    setLoaderNetwork(state, action) {
      state.speedLoading = action.payload;
    },
  },
});

export const { setSpeed, setLatency, setLoaderNetwork } = slice.actions;

export const reducer = slice.reducer;
export default slice;
