import { styled, Box, Typography } from "@mui/material";

export const NoFoundPagesContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "1.5rem",
    background: "white",
    margin: "auto"
}));

export const NoFoundPagesRedirectHome = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "23px",
    fontWeight: 400,
    lineHeight: "130%",
    color: "gray",
    display: "inline",
    [theme.breakpoints.down('sm')]: {
        fontSize: "1rem",
        lineHeight: "32px",
        fontWeight: "400",
    }
}));

export const NoFoundPagesWrapper = styled(Box)(() => ({
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

export const NoFoundPagesText = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "2rem",
    fontWeight: "600",
    lineHeight: "55px",
    letterSpacing: "0em",
    textAlign: "center",
    width: "80%",
    [theme.breakpoints.down('sm')]: {
        fontSize: "1.5rem",
        lineHeight: "32px",
        fontWeight: "500",
        width: "89%"
    }
}));

export const Wrapper = styled(Box)(() => ({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
}));
export const ContentWrapper = styled(Box)(() => ({
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "20px",
    background: "#FFFFFF",
    padding: "2rem",
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
    width: "350px",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));

export const Title = styled(Typography)(({ theme }) => ({
    width: "auto",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#000000",
    fontSize: "18px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
        fontWeight: 600,
        fontSize: "15px",
    },
}));
export const SubTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "25px",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
    },
}));
