import { createSlice } from "@reduxjs/toolkit";

interface IVdi {
  id: string;
  userId?: string;
  name: string;
  select?: boolean
  emailId: string;
  contextId: string;
  properties: {
    status: string;
    type: string;
    devicesAssigned: Array<string>;
  };
  notes: string;
  // status: string;
  lock: false;
  createdDate: string
}

interface IVdiservice {
  isOpen: boolean;
  selectColumn: string;
  Visibility: { isOpen: boolean; title: string; subTitle: string };
  updatedData: IVdi[];
  numberofParticipants: number;
  selectedParticipants: number;
  filtereddata: any[];
  groupByData: any[];
  selectAll: boolean,
  columns: any[],
  dataNotFound: boolean,
  type: string,
  tableType: string,
  contextId: string,
  subId: string,
  skeleton: {
    group: boolean,
    instance: boolean,
  }
}

const initialState: IVdiservice = {
  isOpen: false,
  selectColumn: "",
  Visibility: { isOpen: false, title: "", subTitle: "" },
  updatedData: [],
  numberofParticipants: 0,
  selectedParticipants: 0,
  filtereddata: [],
  groupByData: [],
  selectAll: false,
  columns: [],
  dataNotFound: true,
  type: "",
  tableType: "",
  contextId: "",
  subId: "",
  skeleton: {
    group: true,
    instance: true,
  }
};

const vdiserviceslice = createSlice({
  name: "vdiservice",
  initialState,
  reducers: {
    setContextId(state, action) {
      state.subId = action.payload.sub;
      state.contextId = action.payload.contextId;
    },
    toggleSetings(state) {
      state.isOpen = !state.isOpen;
    },

    //  participantsData(state,action){
    //   state.participant=action.payload;
    //  },

    selectedtableColumn(state, action) {
      state.selectColumn = action.payload;
    },

    toggledialogbox(state, action) {
      state.Visibility = action.payload;
    },

    updatedTabledata(state, action) {
      state.updatedData = action.payload;
    },

    numberof_Participants(state, action) {
      state.numberofParticipants = action.payload;
    },

    selected_Participants(state, action) {
      state.selectedParticipants = action.payload;
    },

    filteredData(state, action) {
      state.filtereddata = action.payload;
      state.dataNotFound = false;
    },

    selectAllParticipants(state) {
      state.selectAll = !state.selectAll;
    },
    updatedColumns(state, action) {
      state.columns = action.payload;
    },
    setDataNotFound(state, action) {
      state.dataNotFound = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setTableType(state, action) {
      state.tableType = action.payload;
    },
    setSkeleton(state, action) {
      state.skeleton[action.payload.key] = action.payload.value;
    }
  },
});


export const getColumns = (data) => async (dispatch) => {
  dispatch(vdiserviceslice.actions.updatedColumns(data));
};

export const fetchFilteringData = (id = [], assignDevicesData = [], participantsData = [], allServices = []) => async (dispatch) => {
  if (id.length !== 0 || assignDevicesData.length !== 0 || participantsData.length !== 0) {
    dispatch(vdiserviceslice.actions.filteredData(
      id.map(item => {
        if (item.type === "Device Name") {
          return {
            type: "Device Name",
            value: allServices.filter(val => val.id === item.value)[0]
          };
        }
        else if (item.type === "Owner Name") {
          return {
            type: "Owner Name",
            value: participantsData.filter(val => val.id === item.value)[0]
          };
        }
        else if (item.type === "Group Name") {
          return {
            type: "Group Name",
            value: allServices.filter(val => (val.properties.groupId === item.value && (val.access.find(a => (a.access !== "host")))))
          };
        }
        else if (item.type === "Device Status") {
          if (item.value === "MAITAINANCE") {
            return {
              type: "Device Status",
              value: allServices.filter(val => (val.state !== "STOPPED" && val.state !== "STARTED" && val.state !== "REGISTERED"))
            };
          }
          return {
            type: "Device Status",
            value: allServices.filter(val => (val.state === item.value))
          };
        }
        else {
          return null;
        }
      })
    ));

  }
};

export const reducer = vdiserviceslice;
