import { createSlice } from "@reduxjs/toolkit";
import { IUserData, IMessagesData, IUserByIdData, ICreatedGroup, IUserStore, IAttachment } from 'src/models/messenger';
import axios from "src/utils/axios";
import { leaveRoomAction } from "./meeting";
import { setSendMessage } from "./persistStore";
  
interface IMessenger {
  user_id: string;
  name: string;
  userData: IUserData[],
  pinnedData: IUserData[],
  hiddenData: IUserData[],
  messageByIdData: IMessagesData[],
  userById: IUserByIdData,
  createdGroup: ICreatedGroup[],
  message: { id: number, edit: boolean, value: string },
  openCodeEditor: boolean,
  attachmentLength: number,
  send_threadId: string,
  userStore: IUserStore[],
  threadId: number,
  size: {
    width: number, height: number
  },
  zoomSize: {
    width: number, height: number
  },
  inputRow: number,
  chatToggle: boolean,
  unread: boolean,
  messageData: string,
  errMsg:string,
  loader:boolean,
  attachment: IAttachment[],
  left:boolean,
  poorConnectionWarn: boolean,
  connectionWarnAlreadyShown: boolean,
}

const initialState: IMessenger = {
  user_id: null,
  name: "",
  userData: [],
  pinnedData: [],
  hiddenData: [],
  messageByIdData: [],
  userById: null,
  attachmentLength: 0,
  createdGroup: [],
  openCodeEditor: false,
  message: {
    id: null,
    edit: false,
    value: null
  },
  send_threadId: null,
  userStore: [],
  threadId: null,
  size: {
    width: 300, height: 400
  },
  zoomSize: {
    width: 300, height: 400
  },
  inputRow: 1,
  chatToggle: false,
  unread: false,
  messageData: '',
  errMsg:'',
  loader:false,
  attachment: [],
  left:false,
  poorConnectionWarn: false,
  connectionWarnAlreadyShown: false,
};

const slice = createSlice({
  name: "messenger",
  initialState,
  reducers: {
    userData(state, action) {
      state.userData = action.payload;
    },
    pinnedData(state, action) {
      state.pinnedData = action.payload;
    },
    hiddenData(state, action) {
      state.hiddenData = action.payload;
    },
    messageByIdData(state, action) {
      state.messageByIdData = action.payload;
    },
    userById(state, action) {
      state.userById = action.payload;
    },
    createdGroup(state, action) {
      state.createdGroup = action.payload;
    },
    editMessage(state, action) {
      state.message = action.payload;
    },
    setUserId(state, action) {
      state.user_id = action.payload.sub;
      state.name = action.payload.name;
    },
    setSendThreadId(state, action) {
      state.send_threadId = action.payload;
    },
    storeAllUser(state, action) {
      state.userStore = action.payload;
    },
    storeThreadId(state, action) {
      state.threadId = action.payload;
    },
    codeEdit(state, action) {
      state.openCodeEditor = action.payload;
    },
    attachmentLengthStore(state, action) {
      state.attachmentLength = action.payload;
    },
    sizeController(state, action) {
      state.size = action.payload;
    },
    zoomSizeController(state, action) {
      state.zoomSize = action.payload;
    },
    storeInputRow(state, action) {
      state.inputRow = action.payload;
    },
    changeChatToggleValue(state, action) {
      state.chatToggle = action.payload;
      if (state.chatToggle) {
        state.unread = false;
      }
    },
    unreadToggle(state) {
      if (!state.chatToggle) {
        state.unread = true;
      }
      else {
        state.unread = false;
      }
    },
    storeMessage(state, action) {
      state.messageData = action.payload;
    },
    setLoading(state, action) {
      state.loader = action.payload;
    },

    storeErrorMessage(state,action){
      state.errMsg=action.payload;
    },

    storeAttachments(state, action) {
      state.attachment = [...state.attachment, action.payload];
    },
    clearStoreAttachments(state, action) {
      state.attachment = action.payload;
    },
    left(state,action){
      state.left=action.payload;
    },
    setPoorConnectionWarn(state, action) {
      state.poorConnectionWarn = action.payload;
    },
    setConnectionWarnAlreadyShown(state, action) {
      state.connectionWarnAlreadyShown = action.payload;
    }
  }
});

export const reducer = slice.reducer;
export const actions=slice.actions;
const BASE_URL = process.env.REACT_APP_API_URL;

const link = `${BASE_URL}/api/v1/messaging/threads`;

export const messageApiById = (index) => async (dispatch) => {
  
  if (index) {
    const response = await axios.get(`${link}/${index}/message`,
      {
        headers: {
          'Cache-Control': 'no-cache  ',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      });       
    dispatch(slice.actions.messageByIdData(response?.data.data));
    
    dispatch(slice.actions.unreadToggle());
  }
};

export const sendMessageById = (data) => async (dispatch) => {

  if (data.attachment.length > 0 && data.msg !== '') {
    const message = {
      eventType: "event_message",
      contentType: data.contentType,
      content: data.msg,
      attachment: data.attachment,
      userId: data.userId,
      createdBy: data.userName
    };
    const response = await axios.post(`${link}/${data.id}/message`, message);
    if (response.status === 200) {
      dispatch(messageApiById(data.id));
    }
  }
  else if (data.attachment.length > 0) {
    const message = {
      eventType: "event_message", 
      contentType: data.contentType,
      attachment: data.attachment,
      userId: data.userId,
      createdBy: data.userName
    };
    const response = await axios.post(`${link}/${data.id}/message`, message);
    if (response.status === 200) {
      dispatch(messageApiById(data.id));
    }
  }
  else {
    const message = {
      eventType: "event_message",
      contentType: data.contentType,
      content: data.msg,
      userId: data.userId,
      createdBy: data.userName
    };
    const response = await axios.post(`${link}/${data.id}/message`, message);
    if (response.status === 200) {
      dispatch(messageApiById(data.id));
    }
  }
};

export const joinMeetingMessage = (data) => async (dispatch) => {
  dispatch(setSendMessage(true));
  const message = {
    eventType: "join_message",
    content: `${data.userName} joined the meeting.`,
  };
  const response = await axios.post(`${link}/${data.id}/message`, message);
  if (response.status === 200) {
    dispatch(messageApiById(data.id));
  }
};

export const denyHost = () => async (dispatch) => {
  dispatch(slice.actions.left(true));
};

export const leftMessages = (data) => async (dispatch) => {
  dispatch(slice.actions.left(true));
  const message = {
    eventType: "left_message",
    content: `${data.userName} left the meeting.`,
  };

  const response = await axios.post(`${link}/${data.id}/message`, message);
  if (response.status === 200) {
    dispatch(messageApiById(data.id));
  }

  dispatch(
    leaveRoomAction(
      data.roomInstance,
      data.conferenceInstance,
      data.userName,
      data.localTracks,
      data.room
    )
  );
};

export const emojiMessage = (data) => async (dispatch) => {


  await axios.post(`${link}/${data.id}/message/${data.messageId}/${data.emoji.userId}`, data.emoji);

   dispatch(messageApiById(data.id));

};

export const deleteEmoji = (data) => async (dispatch) => {
  await axios.put(`${link}/${data.id}/message/${data.messageId}/${data.userId}`);
  dispatch(messageApiById(data.id));
};

export const editTrue = (data) => async (dispatch) => {
  dispatch(slice.actions.editMessage(data));
};

export const editFalse = (data) => async (dispatch) => {
  const message = {
    content: data.value.msg,
    attachment: data.value.attachment,
    userId: data.value.userId,
  };
  await axios.put(`${link}/${data.userId}/message/${data.id}`, message);
  dispatch(slice.actions.editMessage(data));

  dispatch(messageApiById(data.userId));

};

export const addUserId = (data) => async (dispatch) => {
  dispatch(slice.actions.setUserId(data));
};

export const storeSendThreadId = (data) => async (dispatch) => {
  dispatch(slice.actions.setSendThreadId(data));
};

export const openCodeEditorController = (data) => async (dispatch) => {
  dispatch(slice.actions.codeEdit(data));
};

export const attachmentLengthController = (data) => async (dispatch) => {
  dispatch(slice.actions.attachmentLengthStore(data));
};

export const storeSize = (data) => (dispatch) => {
  dispatch(slice.actions.sizeController(data));
};

export const storeZoomSize = (data) => (dispatch) => {
  dispatch(slice.actions.zoomSizeController(data));
};

export const inputRowController = (data) => (dispatch) => {
  dispatch(slice.actions.storeInputRow(data));
};

export const chatToggleHandler = (data) => (dispatch) => {
  dispatch(slice.actions.changeChatToggleValue(data));
};

export const saveMessageData = (data) => (dispatch) => {
  dispatch(slice.actions.storeMessage(data));
};

export const saveAttachments = (data) => (dispatch) => {
  dispatch(slice.actions.clearStoreAttachments(data));
};

export const setPoorConnectionWarn = (data) => (dispatch) => {
  dispatch(slice.actions.setPoorConnectionWarn(data));
};

export const setConnectionWarnAlreadyShown = (data) => (dispatch) => {
  dispatch(slice.actions.setConnectionWarnAlreadyShown(data));
};

export const fileuploading = (data) => async (dispatch) => {

  dispatch(slice.actions.setLoading(true));

  const formData = new FormData();
  formData.append('image', data);

  const uploadImageResponse = await axios.post(`${BASE_URL}/api/v1/events/image`, formData).then(data=>data).catch((err)=>dispatch(slice.actions.storeErrorMessage(err.message)));
  dispatch(slice.actions.setLoading(false));

  if (uploadImageResponse.data.image) {
    const fileData = {
      id: Date.now(),
      fileName: data.name,
      fileSize: Number((data.size / 1024 / 1024).toFixed(2)),
      unit: 'MB',
      link: uploadImageResponse.data.image,
      fileType: data.name.split('.')[1],
    };

    dispatch(slice.actions.storeAttachments(fileData));
        
  }

};
