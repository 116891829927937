import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import { setDialogQuickAction, updateSavedQuestions } from "./AvToolbar";

const APP_BASE_URL = process.env.REACT_APP_API_URL;
interface IOption {
  option_id?: string;
  option: string;
  isCorrect?: boolean;
  percentage?: string;
}

interface IQuestionInput {
  type?: string;
  question?: string;
  score?: string;
  time?: string;
  isRequired?: boolean;
  isPublish?: boolean;
  options?: IOption[];
}

interface ICodeDetails {
  language_id: number;
  language_name: string;
  code: string;
}

interface IQuestion {
  id: string;
  type: string;
  question: string;
  info: string
  score: number;
  time: number;
  isRequired: boolean;
  isPublish?: boolean;
  options?: IOption[];
  isQuestionShown?: boolean;
  index?: number;
  subType?: string;
  code?: ICodeDetails[];
  no?: number;
  allsubmissionsCount?: number;
  correctSubmissionCount?: number;
  incorrectSubmissionCount?: number;
  participantsCount?: number;
}

interface ICodeSolution {
  code?: string;
  language_id: number;
  language_name: string;
  solution: string;
}
interface IAllSubmissions {
  code?: string;
  isCorrect?: boolean;
  participantId?: string;
  participantName?: string;
  option?: string;
  optionId?: string;
  test_cases?: ITestCases[];
}

interface ITestCases {
  test_case_id?: number;
  input?: string;
  output?: string;
  test_case_passed?: boolean;
}
interface IResults {
  [key: string]: {
    type?: string;
    allsubmissionsCount?: number;
    correctSubmissionCount?: number;
    incorrectSubmissionCount?: number;
    participantsCount?: number;
    options?: IOption[];
    solution?: ICodeSolution[];
    allsubmissions?: IAllSubmissions[];
    test_cases?: ITestCases[];
  };
}

interface IQuickActionMedia {
  _id: string;
  media_id: string;
  text: string;
  url: string;
  svg?: any;
}

interface IQuickAction {
  status: string;
  id: string;
  question: string;
  score: number;
  type: string;
  time: number;
  isRequired: boolean;
  subType: string;
  options: IOption[];
  media: IQuickActionMedia[];
}

interface ITestCaseDetails {
  test_case_id: number,
  test_case_passed: boolean,
  test_case_results: {
    stdout: string,
    time: string,
    memory: number,
    stderr: any,
    token: string,
    compile_output: any,
    message: any,
    status: {
      id: number,
      description: string
    }
  },
  input: string;
  expected_output: string;
}

interface ICodeSubmissionResponseData {
  total_test_cases?: number;
  submission?: ITestCaseDetails[];
  accepted?: boolean;
}

interface ILatestResult {
  id?: string;
  questionId?: string;
  no?: number;
  type?: string;
  allsubmissionsCount?: number;
  correctSubmissionCount?: number;
  incorrectSubmissionCount?: number;
  participantsCount?: number;
  isCorrect?: boolean;
  option_id?: string;
  testId?: string;
}

interface ICustomInputResponse {
  stdout?: string; 
  time?: string;
  stderr?: any; 
  token?: string;
  compile_output?: any;
  status?: { 
    id: number;
    description: string;
  }
}

interface IAssessmentData {
  name?: string;
  status?: string;
  id?: string;
  type?: string;
}

interface IRoot {
  saveData: IQuestion[];
  publish: IQuestion[];
  publishedResult: IQuestion[];
  editQuestion: IQuestion;
  viewResultBoolean: boolean;
  testId: string;
  showLoader: boolean;
  getResults: IResults;
  inputValueMcq: IQuestionInput;
  quickActions: IQuickAction[];
  currentQuickAction: IQuickAction[];
  canSelectAnyQuestionValue: boolean;
  currentTab: string;
  isQuestion: string;
  codeSolution: string;
  codeSubmissionResponseData: ICodeSubmissionResponseData;
  showSelectedTestCaseData: number;
  showCompileError: boolean;
  compilationError: ITestCaseDetails;
  showCompileSnackbarData: boolean;
  updateTestCase: ICodeSubmissionResponseData;
  resultsLatestTimer: number;
  showLatestResponse: ILatestResult;
  highlightlatestResponse: string;
  selectedResultDetailId: string;
  showLastPublishedQuestion: boolean;
  updateHighlight: boolean;
  customInputResponse: ICustomInputResponse;
  showCustomTestInputDialog: boolean;
  codeLanguageId: string;
  assessmentListData: IAssessmentData[];
  viewResultId: string;
  showAssessmentLoaderData: boolean;
  showResultLoader: boolean;
  assessmentDuration: number;
  assessmentId: string;
  isPublishedAssessment: boolean;
  disablePublish: boolean;
}

const initialState: IRoot = {
  saveData: [],
  publish: [],
  publishedResult: [],
  editQuestion: null,
  viewResultBoolean: false,
  testId: "",
  showLoader: false,
  getResults: {},
  inputValueMcq: {},
  quickActions: [],
  currentQuickAction: [],
  canSelectAnyQuestionValue: true,
  currentTab: "Quick Access",
  isQuestion: "",
  codeSolution: "",
  codeSubmissionResponseData: {},
  showSelectedTestCaseData: null,
  showCompileError: false,
  compilationError: null,
  showCompileSnackbarData: false,
  updateTestCase: {},
  resultsLatestTimer: 0,
  showLatestResponse: {},
  highlightlatestResponse: "",
  selectedResultDetailId: "",
  showLastPublishedQuestion: false,
  updateHighlight: false,
  customInputResponse: {},
  showCustomTestInputDialog: false,
  codeLanguageId: "",
  assessmentListData: [],
  viewResultId: "",
  showAssessmentLoaderData: false,
  showResultLoader: false,
  assessmentDuration: null,
  assessmentId: "",
  isPublishedAssessment: false,
  disablePublish: false
};

const slice = createSlice({
  name: "gamificationDrawer",
  initialState,
  reducers: {
    SavedQuestions(state, action) {
      state.saveData = action.payload;
    },

    closePublish(state) {
      state.publish = [];
    },
    updateEditQuestion(state, action) {
      state.editQuestion = action.payload;
    },
    updatePublish(state, action) {
      if (!state.publish[action.payload]?.isRequired) {
        state.publish[action.payload].isQuestionShown = false;
      }
    },
    Test(state, action) {
      state.testId = action.payload;
    },
    ShowLoader(state, action) {
      state.showLoader = action.payload;
    },
    showPublishedQuestion(state, action) {
      if (state.publish.every((question) => question.id !== action.payload.id) && !!action.payload?.id) {
        state.publish.push(action.payload);
      }
    },
    GetPublishedResult(state, action) {
      state.publishedResult = action.payload;
    },
    StoreResults(state, action) {
      state.getResults[action.payload.id] = action.payload.data;
    },
    MaintainInputValues(state, action) {
      state.inputValueMcq = action.payload;
    },
    QuickActions(state, action) {
      state.quickActions = action.payload;
    },
    updateQuickAction(state, action) {
      state.currentQuickAction.push(action.payload);
    },
    removeQuickAction(state, action) {
      state.currentQuickAction = action.payload;
    },
    CanSelectAnyQuestion(state, action) {
      state.canSelectAnyQuestionValue = action.payload;
    },
    ToggleQuestionVisibility(state, action) {
      const savedQuestions = JSON.parse(JSON.stringify(state.saveData));
      for (const question of savedQuestions) {
        if (action.payload.id === question.id) {
          question.isQuestionShown = !question.isQuestionShown;
        } else if (question.isQuestionShown) {
          question.isQuestionShown = false;
        }
      }
      state.saveData = savedQuestions;
    },
    currentTab(state, action) {
      state.currentTab = action.payload;
    },
    isQuestion(state, action) {
      state.isQuestion = action.payload;
    },
    codeSolution(state, action) {
      state.codeSolution = action.payload;
    },
    codeSubmissionResponse(state, action) {
      state.codeSubmissionResponseData = action.payload;
    },
    showSelectedTestCase(state, action){
      state.showSelectedTestCaseData = action.payload;
    }, 
    isCompileError(state, action) {
      state.showCompileError = action.payload;
    },
    updateCompilationError(state, action) {
      state.compilationError = action.payload;
    },
    showCompileSnackbar(state, action) {
      state.showCompileSnackbarData = action.payload;
    },
    updateTestCase(state, action) {
      state.updateTestCase[action.payload.id] = action.payload.testCases;
    },
    resetTestCase(state) {
      state.updateTestCase = {};
    },
    updateCodeInPublish(state, action) {
      if (state?.publish[action.payload.index]?.code) {
        state.publish[action.payload.index].code = state.publish[action.payload.index]?.code?.map((code) => {
          if (code.language_id === action.payload.languageId) {
            code.code = window.btoa(action.payload.code);
          }
          return code;
        });
      }
    },
    addLanguage(state, action) {
      if (state?.publish[action.payload.index]?.code) {
        const language = action.payload.language;
        const formattedLang: ICodeDetails = { language_id: language.id, language_name: language.name, code: '' };
        state.publish[action.payload.index].code.push(formattedLang);
      }
    },
    handleResultsTime(state, action) {
      state.resultsLatestTimer = action.payload;
    },
    handleLiveResultResponse(state, action) {
      let resultsCopy = JSON.parse(JSON.stringify(state.publishedResult));
      resultsCopy = resultsCopy?.map((result) => {
        if(result?.id === action.payload?.id) {
          if (action.payload?.type !== "quick") {
            result.allsubmissionsCount = action.payload?.allsubmissionsCount;
            result.correctSubmissionCount = action.payload?.correctSubmissionCount;
            result.incorrectSubmissionCount = action.payload?.incorrectSubmissionCount;
            result.participantsCount = action.payload?.participantsCount;
          } else {
            result.options = action.payload?.options;
          }
        }
        return result;
      });
      state.publishedResult = resultsCopy;
    },
    handleShowLatestResponse(state, action) {
      state.showLatestResponse = action.payload;
    },
    handleHighlightlatestResponse(state, action) {
      state.highlightlatestResponse = action.payload;
    },
    handleSelectedResultDetailId(state, action) {
      state.selectedResultDetailId = action.payload;
    },
    handleUpdateHighlight(state, action) {
      state.updateHighlight = action.payload;
    },
    handleCustomInput(state, action) {
      state.customInputResponse = action.payload;
    },
    handleShowCustomTestInputDialog(state, action) {
      state.showCustomTestInputDialog = action.payload;
    },
    handleCodeLanguageId(state, action) {
      state.codeLanguageId = action.payload;
    },
    handleAssessmentData(state, action) {
      state.assessmentListData = action.payload;
    },
    handleViewResultId(state, action) {
      state.viewResultId = action.payload;
    },
    showAssessmentLoader(state, action) {
      state.showAssessmentLoaderData = action.payload;
    },
    handleResultLoader(state,action) {
      state.showResultLoader = action.payload;
    },
    handleAssessmentDuration(state, action) {
      state.assessmentDuration = action.payload;
    },
    handleAssessmentId(state, action) {
      state.assessmentId = action.payload;
    },
    handleIsPublishedAssessment(state, action) {
      state.isPublishedAssessment = action.payload;
    },
    handleDisablePublish(state, action) {
      state.disablePublish = action.payload;
    }
  },
});

export const reducer = slice.reducer;

export const deleteSaveQuestions =
  (id, enqueueSnackbar) => async (dispatch) => {
    try {
      const deleteQuestion = await axios.delete(
        `${APP_BASE_URL}/api/v1/question/${id}`
      );

      if (!deleteQuestion?.data?.status) {
        throw new Error("Question delete failed");
      }
      enqueueSnackbar(
        deleteQuestion.data.message || "Question is deleted successfully",
        {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      dispatch(getSavedQuestions(enqueueSnackbar));
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Question delete failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

export const closePublish = () => async (dispatch) => {
  dispatch(slice.actions.closePublish());
};

export const updatePublish = (index) => (dispatch) => {
  dispatch(slice.actions.updatePublish(index));
};

export const Test = (enqueueSnackbar) => async (dispatch, getState) => {
  try {
    const testResponse = await axios.get(`${APP_BASE_URL}/api/v1/test`);

    if (!testResponse?.data?.status) {
      throw new Error("Test creation failed");
    }

    const testId = testResponse?.data?.data?.assesments?.filter((test) => test?.type === "flexible");

    dispatch(slice.actions.Test(testId[0]?.id));
    if(!getState().gamificationDrawer.viewResultId){
      dispatch(slice.actions.handleViewResultId(testId[0]?.id));
    }
    dispatch(slice.actions.handleAssessmentData(testResponse?.data?.data?.assesments));
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Test creation is failed", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const submitQuestion =
  (enqueueSnackbar, data, publish) => async (dispatch) => {
    try {
      const submitQuestionResponse = await axios.post(
        `${APP_BASE_URL}/api/v1/question`,
        data,
        {
          params: { publish },
        }
      );

      if (!submitQuestionResponse?.data?.status) {
        throw new Error("Question creation failed");
      }

      dispatch(getSavedQuestions(enqueueSnackbar));
      dispatch(slice.actions.updateEditQuestion(null));

      if (publish) {
        enqueueSnackbar("Question published successfully", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      dispatch(handleResultsTime(data.time));
      }
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Question creation failed", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
export const handleResultsTime = (time) => (dispatch) => {
  dispatch(slice.actions.handleResultsTime(time));
};

export const clearEditQuestion = () => (dispatch) => {
  dispatch(slice.actions.updateEditQuestion(null));
};

export const handleLoader = (data) => (dispatch) => {
  dispatch(slice.actions.ShowLoader(data));
};

export const handleResultLoader = (data) => (dispatch) => {
  dispatch(slice.actions.handleResultLoader(data));
};

export const getSavedQuestions = (enqueueSnackbar) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.ShowLoader(true));
    const testId = getState().gamificationDrawer.testId;
    const savedQuestionResponse = await axios.get(
      `${APP_BASE_URL}/api/v1/question`,
      {
        params: { status: "draft", subType: "custom", parentId: testId },
      }
    );

    if (!savedQuestionResponse?.data?.status) {
      throw new Error("Unable to fetch saved question");
    }

    dispatch(slice.actions.SavedQuestions(savedQuestionResponse?.data?.data));
    dispatch(updateSavedQuestions(savedQuestionResponse?.data?.data));
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Unable to fetch saved question",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  } finally {
    dispatch(slice.actions.ShowLoader(false));
  }
};

export const questionPublish = (enqueueSnackbar, id, time) => async (dispatch) => {
  try {
    const publishResponse = await axios.put(
      `${APP_BASE_URL}/api/v1/question/${id}/publish`
    );

    if (!publishResponse?.data?.status) {
      throw new Error("Question Publish Failed");
    }

    dispatch(handleResultsTime(time));
    dispatch(getSavedQuestions(enqueueSnackbar));
    dispatch(getPublishedData(enqueueSnackbar));
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Question publish failed", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const updatePublishedQuestions = (data) => (dispatch) => {
  if (!data.response.data.questions) {
    return;
  }

  const value = data.response.data.questions;
  for (const question of value) {

    if (question?.type === "mcq") {
      const publishedSingleData = {
        id: question.id,
        time: question.time,
        type: question.type,
        question: question.question,
        score: question.score,
        isRequired: question.isRequired,
        options: question.options,
      };
      dispatch(slice.actions.showPublishedQuestion(publishedSingleData));

    } else if (question?.type === "code") {
      const publishedSingleData = {
        id: question.id,
        time: question.time,
        type: question.type,
        score: question.score,
        isRequired: question.isRequired,
        question: question.question,
        info: question.info,
        code: question.code
      };
      dispatch(slice.actions.showPublishedQuestion(publishedSingleData));
    }
  }
  dispatch(slice.actions.Test(data.response.data.id));
};

export const getEditQuestion = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    const response = await axios.get(`${APP_BASE_URL}/api/v1/question`, {
      params: { id: data.id },
    });

    if (!response.data?.status) {
      throw new Error("Could not fetch question details");
    }
    if (response.data?.status) {
      const [questionData] = response.data.data;
      const formattedQuestion = {
        question: questionData.question,
        id: questionData.id,
        isRequired: questionData.isRequired,
        options: questionData.options,
        score: questionData.score,
        time: questionData.time,
        type: questionData.type,
        status: questionData.status,
      };

      dispatch(slice.actions.updateEditQuestion(formattedQuestion));
    }
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Could not fetch question details",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const updateQuestion =
  (enqueueSnackbar, data, selectedQuestion) => async (dispatch) => {
    try {
      if (selectedQuestion) {
        let questionData = JSON.parse(JSON.stringify(data));
        delete questionData.options;

        questionData = {
          isRequired: questionData.isRequired,
          question: questionData.question,
          score: questionData.score,
          time: questionData.time,
          type: "mcq",
        };
        const updateQuestionResponse = await axios.put(
          `${APP_BASE_URL}/api/v1/question/${selectedQuestion.id}`,
          questionData,
          {}
        );

        if (!updateQuestionResponse?.data?.status) {
          throw new Error("Failed to update Question");
        }

        for (const option of selectedQuestion.options) {
          if (
            data.options.some(
              (op) =>
                op.option_id === option.option_id &&
                (op.option !== option.option ||
                  op.isCorrect !== option.isCorrect)
            )
          ) {
            const [updatedData] = data.options.filter(
              (op) => op.option_id === option.option_id
            );

            const optionUpdateResponse = await axios.put(
              `${APP_BASE_URL}/api/v1/question/${selectedQuestion.id}/option/${option.option_id}`,
              {
                option: updatedData.option,
                isCorrect: updatedData.isCorrect,
              }
            );

            if (!optionUpdateResponse?.data?.status) {
              throw new Error("Failed to update Option");
            }
          } else if (
            data.options.every((e) => e.option_id !== option.option_id)
          ) {
            const optionDeleteResponse = await axios.delete(
              `${APP_BASE_URL}/api/v1/question/${selectedQuestion.id}/option/${option.option_id}`
            );
            if (!optionDeleteResponse?.data?.status) {
              throw new Error("Failed to delete Option");
            }
          }
        }

        for (const option of data.options) {
          if (
            selectedQuestion.options.every(
              (op) => op.option_id !== option.option_id
            )
          ) {
            const optionAddResponse = await axios.post(
              `${APP_BASE_URL}/api/v1/question/${selectedQuestion.id}/option`,
              {
                option: option.option,
                isCorrect: option.isCorrect,
              }
            );

            if (!optionAddResponse?.data?.status) {
              throw new Error("Failed to add option");
            }
          }
        }
      }

      dispatch(slice.actions.updateEditQuestion(null));
      dispatch(getSavedQuestions(enqueueSnackbar));
      enqueueSnackbar("Edited question successfully", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 5000,
      });
    } catch (error) {
      enqueueSnackbar(error?.message || error || "Failed to edit question", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
      });
    }
  };

export const submitUserAnswers = (enqueueSnackbar, data, only_public_case) => async (dispatch) => {
  try {
    const submitUserAnswersResponse = await axios.post(
      `${APP_BASE_URL}/api/v1/question/submission`,
      data, {params: { only_public_case }}
    );

    if (!submitUserAnswersResponse?.data?.status) {
      throw new Error("Answer submission failed");
    }
    if (data?.code) {
      if (only_public_case) {
        dispatch(slice.actions.codeSubmissionResponse(submitUserAnswersResponse?.data?.data));
        dispatch(slice.actions.updateTestCase({ id: data.questionId, testCases: submitUserAnswersResponse?.data?.data?.submission }));
        dispatch(slice.actions.showSelectedTestCase(submitUserAnswersResponse?.data?.data?.submission[0]?.test_case_id));
        const isError = submitUserAnswersResponse?.data?.data?.submission?.filter((testcase) => testcase.test_case_results?.stderr !== null);
        dispatch(slice.actions.isCompileError(!!isError?.length));
        dispatch(slice.actions.updateCompilationError(isError?.length ? isError[0] : null));
        dispatch(showCompileSnackbar(true));
        setTimeout(() => { dispatch(showCompileSnackbar(false)); }, 5000);
      } else {
      enqueueSnackbar(submitUserAnswersResponse?.data?.message, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    } else {
      enqueueSnackbar("Submitted successfully !", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Answer submission failed", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const handleResetCode = () => (dispatch) => {
  dispatch(slice.actions.codeSubmissionResponse({}));
  dispatch(slice.actions.isCompileError(false));
  dispatch(dispatch(slice.actions.codeSolution("")));
};

export const showSelectedTestCase = (testcase) => (dispatch) => {
  dispatch(slice.actions.showSelectedTestCase(testcase));
};

export const getPublishedData = (enqueueSnackbar) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.handleResultLoader(true));
    const testId = getState()?.gamificationDrawer?.viewResultId;
    const resultsQuestionsResponse = await axios.get(`${APP_BASE_URL}/api/v1/question/results`, { params: { parentId: testId } });

    if (!resultsQuestionsResponse?.data?.status) {
      throw new Error("Unable to fetch results");
    }

    dispatch(slice.actions.GetPublishedResult(resultsQuestionsResponse?.data?.data));
    if (getState()?.gamificationDrawer?.updateHighlight && Array.isArray(resultsQuestionsResponse?.data?.data)) {
      const resultsData: IQuestion[] = resultsQuestionsResponse?.data?.data;
      dispatch(slice.actions.handleHighlightlatestResponse(resultsData[resultsData.length - 1]?.id));
      dispatch(slice.actions.handleUpdateHighlight(false)); 
    }

  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Unable to fetch results",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  } finally {
    dispatch(slice.actions.handleResultLoader(false));
  }
};

export const resetPublishedData = () => (dispatch) => {
  dispatch(slice.actions.GetPublishedResult([]));
};

export const getResultsData = (enqueueSnackbar, id) => async (dispatch) => {
  try {
    const resultResponse = await axios.get(
      `${APP_BASE_URL}/api/v1/question/results/${id}`
    );

    if (!resultResponse?.data?.status) {
      throw new Error(
        resultResponse?.data?.message || "Could not fetch results"
      );
    }

    dispatch(
      slice.actions.StoreResults({ id: id, data: resultResponse?.data?.data })
    );
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Could not fetch results", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const handleLiveResultResponse = (data) => (dispatch) => {
  dispatch(slice.actions.handleLiveResultResponse(data));
};

export const maintainInputValues = (data) => (dispatch) => {
  dispatch(slice.actions.MaintainInputValues(data));
};

export const getQuickActions = (enqueueSnackbar) => async (dispatch) => {
  try {
    const quickActionResponse = await axios.get(
      `${APP_BASE_URL}/api/v1/question`,
      {
        params: { subType: "system", type: "quick" },
      }
    );

    if (!quickActionResponse?.data?.status) {
      throw new Error(
        quickActionResponse?.data?.message || "Unable to get quick actions"
      );
    }

    dispatch(slice.actions.QuickActions(quickActionResponse?.data?.data));
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Unable to get quick actions", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const addQuickAction =
  (testId, id, enqueueSnackbar, currentTab) => async (dispatch) => {
    try {
      const addQuickActionResponse = await axios.put(
        `${APP_BASE_URL}/api/v1/test/${testId}/add-question/${id}`
      );

      if (!addQuickActionResponse?.data?.status) {
        throw new Error(
          addQuickActionResponse?.data?.message || "Unable to get quick actions"
        );
      }

      enqueueSnackbar("Quick action published successfully", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 5000,
      });
      dispatch(handleResultsTime(30));
      if (currentTab === "Results") {
        dispatch(getPublishedData(enqueueSnackbar));
      }
    } catch (error) {
      enqueueSnackbar(
        error?.message || error || "Unable to get quick actions",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

export const updateQuickAction = (data) => (dispatch) => {
  const quickAction = data.response?.data?.questions[0];

  if (data.intent === "TEST_OPTIONAL") {
    dispatch(slice.actions.updateQuickAction(quickAction));
    dispatch(slice.actions.Test(data.response.data.id));
  }
};

export const SubmitQuickAction =
  (enqueueSnackbar, data, remainingQuestions) => async (dispatch) => {
    try {
      const quickActionResponse = await axios.post(
        `${APP_BASE_URL}/api/v1/question/submission`,
        data
      );

      if (!quickActionResponse?.data?.status) {
        throw new Error(
          quickActionResponse?.data?.message || "Unable to submit quick actions"
        );
      }
      dispatch(slice.actions.removeQuickAction(remainingQuestions));
    } catch (error) {
      enqueueSnackbar(
        error?.message || error || "Unable to submit quick actions",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

export const canSelectAnyQuestion = (data) => (dispatch) => {
  dispatch(slice.actions.CanSelectAnyQuestion(data));
};

export const toggleQuestionVisibility = (data) => (dispatch) => {
  dispatch(slice.actions.ToggleQuestionVisibility(data));
};

export const handleCurrentTabState = (data, updateHighlight = false) => (dispatch) => {
  dispatch(slice.actions.handleUpdateHighlight(updateHighlight));
  dispatch(slice.actions.currentTab(data));
};

export const handleIsQuestion = (data) => (dispatch) => {
  dispatch(slice.actions.isQuestion(data));
};

export const handleCodeSolution = (code) => (dispatch) => {
  dispatch(slice.actions.codeSolution(code));
};

export const showCompileSnackbar = (data) => (dispatch) => {
  dispatch(slice.actions.showCompileSnackbar(data));
};

export const updateCompilationError = (data) => (dispatch) => {
  dispatch(slice.actions.updateCompilationError(data));
};

export const updateTestCase = (id, testCases) => (dispatch) => {
  dispatch(slice.actions.updateTestCase({ id, testCases }));
};

export const updateCodeInPublish = (code, index, languageId) => (dispatch) => {
  dispatch(slice.actions.updateCodeInPublish({ code, index, languageId }));
};

export const resetTestCase = () => (dispatch) => {
  dispatch(slice.actions.resetTestCase());
};

export const addLanguage = (data) => (dispatch) => {
  dispatch(slice.actions.addLanguage(data));
}; 

export const handleShowLatestResponse = (data) => (dispatch) => {
  dispatch(slice.actions.handleShowLatestResponse(data));
};

export const handleHighlightlatestResponse = (data) => (dispatch) => {
  dispatch(slice.actions.handleHighlightlatestResponse(data));
};

export const handleSelectedResultDetailId = (id) => (dispatch) => {
  dispatch(slice.actions.handleSelectedResultDetailId(id));
};

export const handleCustomInput = (data, enqueueSnackbar) => async (dispatch) => {

  try {
    dispatch(slice.actions.ShowLoader(true));
    const customInputResponse = await axios.patch(`${APP_BASE_URL}/api/v1/question/custom-input`, data);

    if (!customInputResponse?.data?.status) {
      throw new Error(
        customInputResponse?.data?.message || "Unable to test custom input"
      );
    }
    dispatch(slice.actions.handleCustomInput(customInputResponse?.data?.data));
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Unable to test custom input",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  } finally {
    dispatch(slice.actions.ShowLoader(false));
  }
};

export const clearActions = (enqueueSnackbar, id) => async() => {
  try{
    const response = await axios.post(`${APP_BASE_URL}/api/v1/question/preempt/${id}`);
    if (!response?.data?.status) {
      throw new Error(
        response?.data?.message || "Failed to clear action"
      );
    }
    if (response?.data?.status) {
      enqueueSnackbar(
        response?.data?.message || "Successfully cleared action",
        {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  } catch (error) {
    enqueueSnackbar(
      // error?.message || error || 
      "Failed to clear actions",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const clearQuickActions = () => (dispatch) => {
  dispatch(setDialogQuickAction(false));
  dispatch(slice.actions.updateQuickAction(null));
};

export const resetCustomInputResponse = () => (dispatch) => {
  dispatch(slice.actions.handleCustomInput({}));
};

export const handleShowCustomTestInputDialog = (data) => (dispatch) => {
  dispatch(slice.actions.handleShowCustomTestInputDialog(data));
};

export const handleCodeLanguageId = (id) => (dispatch) => {
  dispatch(slice.actions.handleCodeLanguageId(id));
};

export const getAssessmentList = (enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch(slice.actions.showAssessmentLoader(true));
    const getAssessmentResponse = await axios.get(`${APP_BASE_URL}/api/v1/test`);

    if (!getAssessmentResponse?.data?.status) {
      throw new Error(getAssessmentResponse?.data?.message || "Could not fetch assessment details");
    }

    dispatch(slice.actions.handleAssessmentData(getAssessmentResponse?.data?.data?.assesments));

  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Could not fetch assessment details",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  } finally {
    dispatch(slice.actions.showAssessmentLoader(false));
  }
};

export const resetAssessmentList = () => (dispatch) => {
  dispatch(slice.actions.handleAssessmentData([]));
};

export const handleIsPublishedAssessment = (data) => (dispatch) => {
  dispatch(slice.actions.handleIsPublishedAssessment(data));
};

export const handleAssessmentPublish = (testId, totalTime, enqueueSnackbar) => async (dispatch) => {
  try {
    const publishAssessmentResponse = await axios.put(`${APP_BASE_URL}/api/v1/test/${testId}/updateStatus`, { status: "live"});

    if (!publishAssessmentResponse?.data?.status) {
      throw new Error(publishAssessmentResponse?.data?.message || "Could not fetch assessment details");
    }

    dispatch(handleIsPublishedAssessment(true));
    dispatch(handleResultsTime(totalTime));
    dispatch(getAssessmentList(enqueueSnackbar));

  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Could not publish assessment",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const handleViewResultId = (data) => (dispatch) => {
  dispatch(slice.actions.handleViewResultId(data));
};

export const getSingleAssessmentDetails = (parentId, enqueueSnackbar) => async (dispatch) => {
  try {

    const getAssessmentResponse = await axios.get(`${APP_BASE_URL}/api/v1/question`, {params: { parentId }});

    if (!getAssessmentResponse?.data?.status) {
      throw new Error(getAssessmentResponse?.data?.message || "Could not fetch assessment data");
    }

    const questions = getAssessmentResponse?.data?.data;
    let totalTime = 0;
    if(questions?.length){
      for(const data of questions) {
        totalTime = totalTime + data?.time;
      }
    }

    dispatch(handleAssessmentPublish(parentId, totalTime, enqueueSnackbar));

  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Could not publish assessment",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        }
      }
    );
  }
};

export const handleAssessmentDuration = (data) => (dispatch) => {
  dispatch(slice.actions.handleAssessmentDuration(data));
}; 

export const handleRestoreAssessment = (enqueueSnackbar) => async () => {
  try {

    const assessmentRestoreResponse = await axios.get(`${APP_BASE_URL}/api/v1/test/running-test`);

    if (!assessmentRestoreResponse?.data?.status) {
      throw new Error(assessmentRestoreResponse?.data?.message || "Could not fetch assessment data");
    }

  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Could not fetch assessment details",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        }
      }
    );
  }
};

export const handleEndTest = (testId, enqueueSnackbar, handleEndTestSuccess) => async () => {
  try {
    const endTestResponse = await axios.post(`${APP_BASE_URL}/api/v1/test/${testId}/submit`);

    if (!endTestResponse?.data?.status) {
      throw new Error(endTestResponse?.data?.message || "Could not end assessment");
    }

    handleEndTestSuccess();

  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Could not end assessment",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        }
      }
    );
  }
};

export const handleAssessmentId = (data) => (dispatch) => {
  dispatch(slice.actions.handleAssessmentId(data));
};

export const handleDisablePublish = (data) => (dispatch) => {
  dispatch(slice.actions.handleDisablePublish(data));
};

export const checkLiveTest = (enqueueSnackbar) => async (dispatch) => {
  try {
    const testResponse = await axios.get(`${APP_BASE_URL}/api/v1/test?liveTest=true`);

    if (!testResponse?.data?.status) {
      throw new Error("Could not fetch live test details");
    }

    if(testResponse?.data?.data?.liveTest) {
      dispatch(handleDisablePublish(true));
    } else {
      dispatch(handleDisablePublish(false));
    }
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Could not fetch live test details", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export default slice;
