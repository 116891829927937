import { createSlice } from '@reduxjs/toolkit';

interface ICommunicatorState {
  chatMinimize?: boolean,
  presenterMinimize?: boolean,
}

const initialState: ICommunicatorState = {
  chatMinimize: true,
  presenterMinimize: false,
};

const slice = createSlice({
  name: 'Communicator',
  initialState,
  reducers: {
    toggleCommunicator(state, action) {
      if (action.payload === 'chat') {
        state.chatMinimize = !state.chatMinimize;
      }
      else if (action.payload === 'presenter') {
        state.presenterMinimize = !state.presenterMinimize;
      }
    },
    togglePopup(state, action) {
      state.chatMinimize = action.payload;

    },
    closePIPwindows(state, action){
      state.presenterMinimize = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const toggleCommunicator = (data) => async (dispatch) => {
  dispatch(slice.actions.toggleCommunicator(data));
};

export const setPopUp = (data) => async (dispatch) => {
  dispatch(slice.actions.togglePopup(data));
};

export const closePIPWindow = (data = true) => async (dispatch) => {
  dispatch(slice.actions.closePIPwindows(data));
};

export default slice;
