import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import type { AppThunk } from "src/store";

interface IPlan {
  description: string,
  id: string,
  locations: string,
  os: string,
  permission: any[],
  planCode: string,
  properties: {
    costPerHour: number,
    monthlyHours: number,
    baseCurrency: string,
    CPU: number,
    RAM: number,
    HDD: number,
    discount: number
  },
  provider: string,
  status: boolean,
}

interface ICustomImageNamesLst {
  id?: string,
  name?: string,
}


interface IInfrastructure {
  osData: string[],
  provider: string[],
  location: string[],
  plan: IPlan[],
  createGroup: {
    name: string,
    contextId: string,
    os: string,
    provider: string,
    location: string,
    instanceCount: number,
    instantiationType: string,
    plan: IPlan
    customImg: string
  },
  editable: boolean,
  index: number,
  groupId: string,
  imageNamesList: ICustomImageNamesLst[]
}


const initialState: IInfrastructure = {
  osData: [],
  provider: [],
  location: [],
  plan: [],
  createGroup: {
    name: "",
    contextId: "",
    os: "",
    provider: "",
    location: "",
    instanceCount: 1,
    instantiationType: "on_assignment",
    plan:
    {
      description: "",
      id: "",
      locations: "",
      os: "",
      permission: [],
      planCode: "",
      properties: {
        costPerHour: 0,
        monthlyHours: 0,
        baseCurrency: "",
        CPU: 0,
        RAM: 0,
        HDD: 0,
        discount: 0
      },
      provider: "",
      status: false
    },
    customImg: "0"
  },
  editable: false,
  index: null,
  groupId: "",
  imageNamesList: []
};

const infraslice = createSlice({
  name: "infrastructure",
  initialState,
  reducers: {
    setGroupValue(state, action) {
      state.createGroup[action.payload.key] = action.payload.value;
    },
    resetGroupValue(state, action) {
      state.createGroup = action.payload;
    },
    // call_failure(state, action) {
    //   state.failure = action.payload;
    // },
    osData(state, action) {
      state.osData = action.payload;
    },
    providerData(state, action) {
      state.provider = action.payload;
    },
    locationData(state, action) {
      state.location = action.payload;
    },
    plan(state, action) {
      state.plan = action.payload;
    },
    setEditable(state, action) {
      state.editable = action.payload;
    },
    setOldesGroupValue(state, action) {
      state.createGroup = action.payload;
    },
    setIndexNumber(state, action) {
      state.index = action.payload;
    },
    setGroupId(state, action) {
      state.groupId = action.payload;
    },
    imageNamesList(state, action) {
      state.imageNamesList = action.payload;
    },
  },
});

export const setGroupData = (data) => async (dispatch) => {
  dispatch(infraslice.actions.setGroupValue(data));
};

const BASE_URL = process.env.REACT_APP_API_URL;

export const groupOsFetch = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/plans?getUnique=os`
    );
    dispatch(infraslice.actions.osData(response.data.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const groupProviderFetch = (os): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/plans?os=${os}&getUnique=provider`
    );
    dispatch(
      infraslice.actions.providerData(response.data.data)
    );
     if(response.data.data.length === 1){
      dispatch(
        setGroupData({
          key: "provider",
          value: response.data.data,
        })
      );
      dispatch(groupLocationFetch(os, response.data.data));
     }
  } catch (err) {
    throw new Error(err);
  }
};

export const groupLocationFetch = (os, provider): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/plans?os=${os}&provider=${provider}&getUnique=locations`
    );
    dispatch(
      infraslice.actions.locationData(response.data.data)
    );
  } catch (err) {
    throw new Error(err);
  }
};

export const resetCreateGroup = () => async (dispatch) => {
  const data = {
    name: "",
    os: "",
    provider: "",
    location: "",
    instanceCount: 1,
    instantiationType: "on_assignment",
    plan:
    {
      description: "",
      id: "",
      locations: "",
      os: "",
      permission: [],
      planCode: "",
      properties: {
        costPerHour: 0,
        monthlyHours: 0,
        baseCurrency: "",
        CPU: 0,
        RAM: 0,
        HDD: 0,
        discount: 0
      },
      provider: "",
      status: false,
      customImg: "0"
    }
  };
  dispatch(infraslice.actions.resetGroupValue(data));
};
export const groupPlanFetch = (os, provider, location): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/plans?os=${os}&provider=${provider}&locations=${location}`
    );
    dispatch(infraslice.actions.plan(response.data.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const setEditable = (data) => async (dispatch) => {
  dispatch(infraslice.actions.setEditable(data));

};

export const setIndexNumber = (data) => async (dispatch) => {
  dispatch(infraslice.actions.setIndexNumber(data));
};

export const setGroupValue = (data) => async (dispatch) => {

  const response = await axios.get(
    `${BASE_URL}/api/v1/plans?os=${data.userData[data.userData.length - 1].os}&provider=${data.userData[data.userData.length - 1].provider}&locations=${data.userData[data.userData.length - 1].location}`
  );
  dispatch(infraslice.actions.plan(response.data.data));
  dispatch(setIndexNumber(response.data.data.findIndex(value => value.id === data.userData[data.userData.length - 1].planId)));

  dispatch(infraslice.actions.setGroupId(data.id));

  const createGroup = {
    name: data.name,
    contextId: data.contextId,
    os: data.userData[data.userData.length - 1].os,
    provider: data.userData[data.userData.length - 1].provider,
    location: data.userData[data.userData.length - 1].location,
    instanceCount: data.properties.instanceCount,
    instantiationType: "on_assignment",
    plan:
    {
      description: "",
      id: "",
      locations: "",
      os: "",
      permission: [],
      planCode: "",
      properties: response.data.data.filter(value => value.id === data.userData[data.userData.length - 1].planId)[0].properties,
      provider: "",
      status: false
    },
    customImg: "",
  };
  dispatch(infraslice.actions.setOldesGroupValue(createGroup));
};


// get custom images name list
export const getImagesNamesListAPI = (os: string, provider: string, locations: string) => async (dispatch) => {
  await axios
    .get(`${BASE_URL}/api/v1/custom-image?os=${os}&provider=${provider}&locations=${locations}&state=CREATED`)
    .then((res) => {
      if (res.status === 200) {
        const response = res?.data?.data;
        const list = [];
        for (const k in response) {
          list.push({
            id: response[k]?.id,
            name: response[k]?.name,
          });
        }
        dispatch(infraslice.actions.imageNamesList(list));
      } else {
        dispatch(infraslice.actions.imageNamesList([]));
        throw new Error();
      }
    })
    .catch((err) => {
      dispatch(infraslice.actions.imageNamesList([]));
      throw new Error(err);
    });
};

export const reducer = infraslice;
