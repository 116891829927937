import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
// import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store, { persistor } from "src/store";
import { PersistGate } from 'redux-persist/integration/react';
import jwt_decode from "jwt-decode";
import { initializeApp } from "firebase/app";
import { getAnalytics, setUserId } from "firebase/analytics";

// import UserService from "./services/UserService";

// const renderApp = () => ReactDOM.render(
//   <React.StrictMode>
//     <HelmetProvider>
//     <Provider store={store}>
//         <App />
//     </Provider>
//   </HelmetProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const token = localStorage.getItem("token");
  let userInfo;
  if(token) {
    userInfo = jwt_decode(token);
  }

const firebaseConfig = {
  apiKey: "AIzaSyAK1BuwVShzrkXseIZFiO9N3UTbQRfcU88",
  authDomain: "densio-9112a.firebaseapp.com",
  projectId: "densio-9112a",
  storageBucket: "densio-9112a.appspot.com",
  messagingSenderId: "208284461722",
  appId: "1:208284461722:web:4c8d05e59723775c3fcd23",
  measurementId: "G-V28GNBHYJE"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
setUserId(analytics, userInfo?.sub);

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// UserService.initKeycloak(renderApp);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
