import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { IRegistration } from 'src/models/registration';
import { setWaitingUser } from './persistStore';

const initialState: IRegistration = {
  header: {
    isVisible: true,
    backgroundColor: 'blue',
    logo:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJJ5cB1GHXJj8ZhR7hukhrDB1kkMuaWPCx40oflpP0A2FxRUX2W6UjIkbc7NM1QexOcf8&usqp=CAU',
    text: 'Header',
    textColor: 'white',
    buttons: [
      {
        backgroundColor: 'white',
        textColor: 'blue',
        link: '',
        text: 'login',
      },
    ],
  },
  footer: {
    isVisible: true,
    backgroundColor: 'black',
    textColor: 'white',
    text: 'Footer',
    isLink: true,
    link: '',
  },
  content: {
    layout: 'mid',
    backgroundColor: 'white',
    textColor: 'black',
    buttonColor: 'blue',
    buttonText: 'Join',
    buttonTextColor: 'white',
    sliderContent: [
      {
        type: 'event/custom',
        image:
          'https://images.unsplash.com/photo-1511707171634-5f897ff02aa9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bW9iaWxlJTIwcGhvbmV8ZW58MHx8MHx8&w=1000&q=80',
      },
    ],
  },
  registrationData: {
    userName: "",
    email: "",
  },
  modals: {
    openModal: false
  }
};

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setRegistrationData(state, action) {
      state.registrationData[action.payload.key] = action.payload.value;
    },
    setOpenIvitedModal(state, action) {
      state.modals.openModal = action.payload;
    },
  },
});


export const RegistrationAction = slice.actions;

// check user invited or not 
export const checkUserInvitedOrNotAPI =
  (eventId: string, email: string, Navigate: any) => async (dispatch) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/participants/check-invited-status`, {
        eventId: eventId,
        participantEmailId: email
      });
      if (res.status === 200 && res?.data?.status && res.data.data.invited === true) {
        // open modal
        dispatch(slice.actions.setOpenIvitedModal(true));
      } else {
        // navigate to waiting room without much benifits
        dispatch(setWaitingUser(true));
        Navigate(`/event/${eventId}/waiting`);
      }
    } catch (err) {
      throw new Error(err);
    }
  };

export const reducer = slice.reducer;

export default slice;
