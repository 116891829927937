import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

interface IParticipant {
  id: string;
  name: string;
  emailId: string;
  role: {
    id: string;
    name: string;
  };
}

interface IDefaultRole {
  id?: string;
  name?: string;
}

interface IOptions {
  name: string;
  id: string;
}

interface IRoot {
  participantsData: IParticipant[];
  participantDetails: IParticipant;
  drawerOpen: boolean;
  defaultRole: IDefaultRole;
  roles: IOptions[];
  showLoader: boolean;
}

const initialState: IRoot = {
  participantsData: [],
  participantDetails: {
    id: "",
    name: "",
    emailId: "",
    role: {
      id: "",
      name: "",
    },
  },
  drawerOpen: false,
  roles: [],
  defaultRole: {},
  showLoader: false,
};

const slice = createSlice({
  name: "participants",
  initialState,
  reducers: {
    setParticipantsDrawer(state, action) {
      state.drawerOpen = action.payload;
    },
    setParticipantsData(state, action) {
      state.participantsData = action.payload;
    },
    setParticipantDetails(state, action) {
      state.participantDetails[action.payload.key] = action.payload.value;
    },
    DefaultRole(state, action) {
      state.defaultRole = action.payload;
    },
    RolesOptions(state, action) {
      state.roles = action.payload;
    },
    setShowLoader(state, action) {
      state.showLoader = action.payload;
    },
    resetParticipantDetails(state, action) {
      state.participantDetails = action.payload;
    },
  },
});

const BASE_URL = process.env.REACT_APP_API_URL;

export const changeParticipantDetails = (data: any) => (dispatch) => {
  dispatch(slice.actions.setParticipantDetails(data));
};

export const setParticipantsDrawer = (data: boolean) => (dispatch) => {
  dispatch(slice.actions.setParticipantsDrawer(data));
};

export const setParticipantsData = (data: any) => (dispatch) => {
  dispatch(slice.actions.setParticipantsData(data));
};

export const resetParticipantDetails = (participantDetails) => (dispatch) => {
  dispatch(slice.actions.resetParticipantDetails(participantDetails));
};

export const getParticipantsData = () => async (dispatch, getState) => {
  const { participantDetails } = getState().participants;
  dispatch(slice.actions.setShowLoader(true));
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/participants`);
    if (!response.data?.status) {
      throw new Error(response.data?.message);
    }
    const filterParticipantData = (data: any) => {
      return data?.id !== participantDetails?.id;
    };
    if (participantDetails?.id) {
      const data = response?.data?.data.filter(filterParticipantData);
      dispatch(slice.actions.setParticipantsData(data));
    } else {
      dispatch(slice.actions.setParticipantsData(response?.data?.data));
    }
    dispatch(slice.actions.setShowLoader(false));
  } catch (error) {
    throw new Error(error);
  }
};

export const Roles = (Id: any) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/events/roles`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          contextId: Id,
        },
      }
    );
    if (!response.data?.status) {
      throw new Error(response.data?.message);
    }
    const [defaultRole] = response.data.data.filter(
      (role) => role.name === "Participant"
    );
    if (defaultRole) {
      dispatch(slice.actions.DefaultRole(defaultRole));
    }
    dispatch(slice.actions.RolesOptions(response.data.data));
  } catch (error) {
    throw new Error(error);
  }
};

export const addParticipant =
  (data, eventId, enqueueSnackbar) => async (dispatch) => {
    try {
      const participantsData = Array.isArray(data) ? data : [{ ...data }];
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/participants?invite=true`,
        participantsData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            contextId: eventId,
          },
        }
      );
      if (!response.data?.status) {
        throw new Error(response.data?.message);
      }
      enqueueSnackbar("Participant added successfully", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      dispatch(getParticipantsData());
    } catch (error) {
      enqueueSnackbar("Failed to add participant", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      dispatch(getParticipantsData());
      throw new Error(error);
    }
  };

export const EditParticipants =
  (participantId, eventId, participantData, participantDetail, enqueueSnackbar) => async (dispatch) => {
    try {
      const editParticipants = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/participants/${participantId}`,
        participantData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            contextId: eventId,
          },
        }
      );
      if (!editParticipants.data?.status) {
        throw new Error(editParticipants.data?.message);
      }
      enqueueSnackbar("Participant details updated", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      dispatch(getParticipantsData());
      dispatch(resetParticipantDetails(participantDetail));
    } catch (error) {
        enqueueSnackbar("Failed to add participant", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
      throw new Error(error);
    }
  };

  export const DeleteParticipant =
  (participantId, eventId) => async (dispatch) => {
    try {
      const deleteParticipant = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/participants?id=${participantId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            contextId: eventId,
          },
        }
      );
      if (!deleteParticipant.data?.status) {
        throw new Error(deleteParticipant.data?.message);
      }
      dispatch(getParticipantsData());
    } catch (error) {
     throw new Error(error);
    }
  };

export const reducer = slice.reducer;
