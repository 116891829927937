import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import {
  changeState,
  getvdiInfo,
  newTokenUpdate,
  removePresenterAccess,
  resetCursorPosition,
} from "./editor";
import { removeAccessActiveMachine } from "./persistStore";
import mqttService from "src/services/MqttService";

interface IData {
  accessFrom: string;
  contextId: string;
  emailId: string;
  id: string;
  isInvited: boolean;
  lastReadMessage: number;
  left: boolean;
  lock: boolean;
  name: string;
  options: {
    canDelete: boolean;
    canEdit: boolean;
  };
  properties: {
    devicesAssigned: [{ access: string; device: string }];
    status: "";
  };

  removed: boolean;
  role: {
    id: string;
    name: string;
  };
  threadId: number;
}

interface IAccess {
  participant_id: string;
  access: string;
  name: string;
}
interface ISystemSpec {
  active: boolean;
  cpu_count: number;
  createdTime: string;
  ram: number;
  raw_format: [];
  ssd: string;
}
interface IServiceAccess {
  access: IAccess[];
  id: string;
  name: string;
  properties: {
    groupId: string;
    groupName: string;
    location: string;
    os: string;
    provider: string;
    systemSpecs: ISystemSpec[];
  };
  state: string;
  dns: {
    address: string;
    ip: string;
  };
  timeline: {
    instantiationTime: string;
    lastStarted: string;
  };
  billing: {
    code: string;
    total_cost: number;
  };
}
interface IActivityData {
  title: string;
  message: string;
  type: string;
  operation: string;
  createdDate: string;
}

interface IAccessPermissionDialog {
  isLoading: boolean;
  state: boolean;
  getPermission: IData[];
  serviceDeviceID: IServiceAccess[];
  ID: string;
  VDItype: string;
  AccessValue: boolean;
  accessChange: [];
  errorMessage: {
    active: boolean;
    message: string;
  };
  screenShareerrorMessage: {
    active: boolean;
    message: string;
  };
  revokeData: [];
  activity: {
    data: IActivityData[];
    metaData: {
      items: number;
      page: number;
      pages: number;
      pagesize: number;
    };
  };

  activityAccess: {
    data: IActivityData[];
    metaData: {
      items: number;
      page: number;
      pages: number;
      pagesize: number;
    };
  };
  notification: {
    active: boolean;
    message: string;
    color: string;
  };
  currentTab: number;
  ram_usage: number;
  cpu_usage: number;
  resizingMsg: {
    active: boolean;
    message: string;
  };
  vdiAccessAlert: {
    active: boolean;
    message: string;
    color: string;
  };
}

const initialState: IAccessPermissionDialog = {
  isLoading: false,
  state: false,
  getPermission: [],
  serviceDeviceID: [],
  ID: "",
  VDItype: "",
  AccessValue: false,
  accessChange: [],
  errorMessage: {
    active: false,
    message: "",
  },
  screenShareerrorMessage: {
    active: false,
    message: "",
  },
  revokeData: [],
  activity: {
    data: [],
    metaData: {
      items: 0,
      page: 0,
      pages: 0,
      pagesize: 0,
    },
  },
  activityAccess: {
    data: [],
    metaData: {
      items: 0,
      page: 0,
      pages: 0,
      pagesize: 0,
    },
  },
  notification: {
    active: false,
    message: "",
    color: "",
  },
  currentTab: 0,
  ram_usage: 0,
  cpu_usage: 0,
  resizingMsg: {
    active: false,
    message: "",
  },
  vdiAccessAlert: {
    active: false,
    message: "",
    color: "",
  },
};

const slice = createSlice({
  name: "accessPermissionDialog",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    accessPermissionDialog(state, action) {
      state.state = action.payload;
    },
    getPermission(state, action) {
      state.getPermission = action.payload;
    },
    deviceID(state, action) {
      state.serviceDeviceID = action.payload;
    },
    serviceID(state, action) {
      state.ID = action.payload;
    },
    VDItype(state, action) {
      state.VDItype = action.payload;
    },
    GiveAccess(state, action) {
      state.accessChange = action.payload;
    },
    AccessValue(state, action) {
      state.AccessValue = action.payload;
    },
    errorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    screenShareerrorMessage(state, action){
      state.screenShareerrorMessage = action.payload;
    },
    revokeAccess(state, action) {
      state.revokeData = action.payload;
    },
    ActivityAPI(state, action) {
      state.activity = action.payload;
    },
    ActivityAccessAPI(state, action) {
      state.activityAccess = action.payload;
    },
    accessNotification(state, action) {
      state.notification = action.payload;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setRamCpuUsages(state, action) {
      state.ram_usage = action.payload.ram;
      state.cpu_usage = action.payload.cpu;
    },
    resizingNotification(state, action) {
      state.resizingMsg = action.payload;
    },
    resetSelectedMachineData(state) {
      state.serviceDeviceID = [];
      state.ID = "";
    },
    setVdiAccessAlert(state, action) {
      state.vdiAccessAlert = action.payload;
    },
  },
});

export const reducer = slice.reducer;
const APP_BASE_URL = process.env.REACT_APP_API_URL;

export const AccessPermissionDialogStatus =
  (data: boolean) => async (dispatch) => {
    dispatch(slice.actions.accessPermissionDialog(data));
  };

export const getServicesPermission = () => async (dispatch) => {
  const response = await axios.get(
    `${APP_BASE_URL}/api/v1/participants?list=true`
  );
  dispatch(slice.actions.getPermission(response.data.data));
};

export const VDItype = (id) => async (dispatch) => {
  dispatch(slice.actions.VDItype(id));
};

export const AccessVDI = (id) => async (dispatch) => {
  dispatch(slice.actions.AccessValue(id));
};

export const deviceID =
  (id, reload = false, data: any = { device: "", access: "" }) =>
  async (dispatch) => {
    try {
      if (!reload) {
        dispatch(slice.actions.setLoading(true));
      }
      const response = await axios.get(
        `${APP_BASE_URL}/api/v1/service?id=${id}`
      );

      dispatch(slice.actions.deviceID(response.data.data));
      dispatch(slice.actions.serviceID(id));
      if (data.device) {
        dispatch(
          getvdiInfo({
            ...data,
            ip: response.data.data[0].dns?.ip,
            address: response.data.data[0]?.dns?.address,
            os: response.data.data[0]?.properties?.os,
          })
        );
      } else {
        dispatch(
          getvdiInfo({
            device: id,
            access: "owner",
            ip: response.data.data[0].dns?.ip,
            address: response.data.data[0]?.dns?.address,
            os: response.data.data[0]?.properties?.os,
          })
        );
      }
      dispatch(storeCurrentTab(0));
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

export const GiveAccess = (data) => async (dispatch) => {
  await axios
    .post(`${APP_BASE_URL}/api/v1/access/give-access`, data)
    .then((response) => {
      dispatch(slice.actions.GiveAccess(response.data.data));
      dispatch(getServicesPermission());
    })
    .catch((err) =>
      dispatch(
        slice.actions.errorMessage({
          active: true,
          message: err.message,
        })
      )
    );

  setTimeout(() => {
    dispatch(slice.actions.errorMessage({ active: false, message: "" }));
  }, 4000);
};

export const revokeAccess = (data) => async (dispatch) => {
  const response = await axios.put(
    `${APP_BASE_URL}/api/v1/access/revoke-access`,
    data
  );
  dispatch(slice.actions.revokeAccess(response.data.data));
};

export const ActivityAPI =
  (data, id, pageCount, pageNumber) => async (dispatch) => {
    try {
      dispatch(slice.actions.setLoading(true));
      const response = await axios.get(
        `${APP_BASE_URL}/api/v1/activity?type=${data}&serviceId=${id}&meta=${true}&page_size=${pageCount}&page=${pageNumber}`
      );
      dispatch(slice.actions.ActivityAPI(response.data));
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

export const ActivityAccessAPI =
  (data, id, pageCount, pageNumber) => async (dispatch) => {
    try {
      dispatch(slice.actions.setLoading(true));
      const response = await axios.get(
        `${APP_BASE_URL}/api/v1/activity?type=${data}&serviceId=${id}&meta=${true}&page_size=${pageCount}&page=${pageNumber}`
      );
      dispatch(slice.actions.ActivityAccessAPI(response.data));
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

export const AccessEnable = (data) => async (dispatch) => {
  if (data.response.data.state === "STARTED") {
    dispatch(deviceID(data.response.data.serviceId, true));
  }
};

export const NotificationMuteUnmute = (data, name) => async (dispatch) => {
  if (data === "MUTE") {
    dispatch(
      slice.actions.accessNotification({
        active: true,
        color: "success",
        message: `${name} have successfully muted you.`,
      })
    );
    setTimeout(() => {
      dispatch(
        slice.actions.accessNotification({
          active: false,
          message: "",
          color: "success",
        })
      );
    }, 3000);
  } else if (data === "UNMUTE") {
    dispatch(
      slice.actions.accessNotification({
        active: true,
        color: "success",
        message: `${name} have successfully unmuted you.`,
      })
    );
    setTimeout(() => {
      dispatch(
        slice.actions.accessNotification({
          active: false,
          message: "",
          color: "success",
        })
      );
    }, 3000);
  }
};

export const vdiAccessNotification = (data) => async (dispatch, getState) => {
  const isPresenting = getState().persistStore.showNotificationData;
  if (isPresenting !== "present") {
    dispatch(
      slice.actions.setVdiAccessAlert({
        active: true,
        message: data,
        color: "success",
      })
    );
    setTimeout(() => {
      dispatch(
        slice.actions.setVdiAccessAlert({
          active: false,
          message: "",
          color: "success",
        })
      );
    }, 5000);
  }
};

export const TrainerNotification =
  (data, color = "success") =>
  async (dispatch) => {
    dispatch(
      slice.actions.accessNotification({
        active: true,
        color: color,
        message: data,
      })
    );
    setTimeout(() => {
      dispatch(
        slice.actions.accessNotification({
          active: false,
          message: "",
          color: "success",
        })
      );
    }, 3000);
  };

export const AccessNotification =
  (data, activeMachine, presenterVDI, ID) => async (dispatch, getState) => {
    if (
      data.response.data.state === "STOPPING" ||
      data.response.data.state === "STOPPED" ||
      data.response.data.state === "RESIZING" ||
      data.response.data.state === "DESTROYING" ||
      data.response.data.state === "RESTARTING"
    ) {
      if (
        activeMachine.length > 0 &&
        data.response.data.serviceId === activeMachine[0].deviceId
      ) {
        dispatch(newTokenUpdate());
        dispatch(removeAccessActiveMachine());
        const user = getState().messages;
        dispatch(
          hideCursor(
            `service-collab-${activeMachine[0].deviceId}`,
            user?.user_id,
            user?.name
          )
        );
      }
      if (presenterVDI?.device === data.response.data.serviceId) {
        dispatch(removePresenterAccess(data.response.data.serviceId));
      }
    }

    if (ID === data.response.data.serviceId) {
      dispatch(deviceID(ID, true));
    }

    if (
      data.response.data.action === "ACCESS_GRANTED" &&
      data.response.data.updateUI
    ) {
      dispatch(
        slice.actions.accessNotification({
          active: true,
          color: "success",
          message: data.response.data.message,
        })
      );
      setTimeout(() => {
        dispatch(
          slice.actions.accessNotification({
            active: false,
            message: "",
            color: "success",
          })
        );
      }, 3000);
    } else if (data.response.data.action === "ACCESS_REVOKED") {
      if (data.response.data.serviceId === ID) {
        dispatch(slice.actions.serviceID(""));
        if (
          activeMachine.length > 0 &&
          data.response.data.serviceId === activeMachine[0].deviceId
        ) {
          dispatch(removeAccessActiveMachine());
          const user = getState().messages;
          dispatch(
            hideCursor(
              `service-collab-${activeMachine[0].deviceId}`,
              user?.user_id,
              user?.name
            )
          );
        }
      }
      dispatch(
        slice.actions.accessNotification({
          active: true,
          color: "error",
          message: data.response.data.message,
        })
      );
    }

    setTimeout(() => {
      dispatch(
        slice.actions.accessNotification({
          active: false,
          message: "",
          color: "success",
        })
      );
    }, 3000);

    if (data.response.data.state === "RESIZING") {
      dispatch(
        slice.actions.resizingNotification({
          active: true,
          message: "Your VDI under upgrading..!",
        })
      );
      dispatch(changeState(false));
      setTimeout(() => {
        dispatch(
          slice.actions.resizingNotification({ active: false, message: "" })
        );
      }, 3000);
    }
  };

export const storeCurrentTab = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentTab(data));
};

export const devicesUsage = (data) => async (dispatch) => {
  if (data?.response?.data?.ram_usage && data?.response?.data?.cpu_utilization) {
    dispatch(
      slice.actions.setRamCpuUsages({
        ram: data?.response?.data?.ram_usage || 0,
        cpu: data?.response?.data?.cpu_utilization || 0,
      })
    );
  }
};

export const resetSelectedMachineData = () => (dispatch) => {
  dispatch(slice.actions.resetSelectedMachineData());
};

export const getUsage = (data) => async () => {
  await axios.get(`${APP_BASE_URL}/api/v1/service/usage/${data}`);
};

export const hideCursor =
  (serviceCollabId, userId, userName) => async (dispatch) => {
    mqttService.publish(
      serviceCollabId,
      JSON.stringify({
        domain: "COLLAB_POINTER",
        intent: "UPDATE",
        response: {
          pid: userId,
          pname: userName,
          lc: {
            x: "-100px",
            y: "-100px",
          },
        },
      })
    );
    setTimeout(() => {
      mqttService.unsubscribe(serviceCollabId);
      dispatch(resetCursorPosition());
    }, 3000);
  };

export const handleCollaborationApi = () => async () => {
  try {
    const response = await axios.patch(
      `${APP_BASE_URL}/api/v1/events/collaboration`
    );

    if (!response?.data?.status) {
      throw new Error(response?.data?.message || "Collaboration failed");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const ErrorMessage = (data) => async (dispatch) => {
  dispatch(
    slice.actions.screenShareerrorMessage({
      active: true,
      message: data,
    })
  );
};

export default slice;
