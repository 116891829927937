import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import routes, { renderRoutes } from "./router";
import ScrollToTop from "./utils/ScrollToTop";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AuthProvider } from "./contexts/Auth0Context";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ReactComponent as SuccessSvg } from "./assets/success.svg";
import { ReactComponent as ErrorSvg } from "./assets/error.svg";
import { ReactComponent as WarningSvg } from "./assets/warning.svg";
import { ReactComponent as InfoSvg } from "./assets/info.svg";
import { SnackbarCloseButton, StyledSnackbarProvider } from "./SnackbarStyled";
import { SnackbarUtilsConfigurator } from "./slices/events/Resource_Assignment";
import { RootState } from "./store";
import { useSelector } from "react-redux";
import { persistor } from "./store";
import ContentWrapper from "./components/ContentWrapper";
import { ErrorBoundary } from 'react-error-boundary';
import SomethingWentWrong from "./content/notFoundPages/somethingwentwrong";


function App() {
  // Restrict Refresh of the page
  const { left } = useSelector((state: RootState) => state.messages);
  const {brandingData} = useSelector((state: RootState) => state.persistStore);
  const { eventTitle} = useSelector((state: RootState) => state.AVSetting
  );
  useEffect(() => {
    if (!left) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
      };
      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  }, [left]);

  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link.setAttribute('href', brandingData?.logo);
    }
  }, []);

  window.onbeforeunload = function () {
    // clear the persisted data using the Redux persistor's purge function
    persistor.purge();
  };

  window.onbeforeunload = function () {
    localStorage.removeItem("waiting"); // remove particular key and value data from local storage
  };

  return (
    <ErrorBoundary FallbackComponent={SomethingWentWrong}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledSnackbarProvider
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
            maxSnack={6}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            iconVariant={{
              success: <SuccessSvg />,
              error: <ErrorSvg />,
              warning: <WarningSvg />,
              info: <InfoSvg />,
            }}
            preventDuplicate={true}
          >
            <SnackbarUtilsConfigurator />
            <BrowserRouter>
              <ScrollToTop />
              <CssBaseline />
              <ContentWrapper title={brandingData?.enabled ? (brandingData?.tagline=== "" ? brandingData?.name: brandingData?.tagline) : eventTitle}>
              <AuthProvider>{renderRoutes(routes)}</AuthProvider>
              </ContentWrapper>
            </BrowserRouter>
          </StyledSnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
    </ErrorBoundary>
  );
}
export default App;
