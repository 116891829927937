import axios from "axios";
import jwt_decode from "jwt-decode";
import { getVDIPresenter } from "src/slices/editor";
import { clearReduxPersist } from "src/slices/persistStore";
import { setAllowPermission } from "src/slices/waitingAV";

let tokenTimeId;

function tokenOpen(token) {
    const data = jwt_decode(token);
    if (data) {
        return data;
    }
}

const doLogout = () => {
    localStorage.removeItem("token");
};

const getToken = () => {
    return localStorage.getItem("token");

};

const baseUrl = process.env.REACT_APP_API_URL;

const updateToken = async (dispatch) => {
    const updateTokenTimer = setInterval(async () => {
        if (getToken()) {
            await axios.post("https://api-dev.einstonlabs.com/api/v1/refresh-token", { access_token: localStorage.getItem("token") })
                .then(data => {
                    dispatch(setAllowPermission(data.data.data.role));
                    localStorage.setItem("token", data.data.data.access_token);
                })
                .catch(err => {
                    clearInterval(updateTokenTimer);
                    localStorage.removeItem("token");
                    dispatch(clearReduxPersist());
                    throw new Error(err);
                });
        } else {
            clearInterval(updateTokenTimer);
        }
    }, 1500000);
};  

export const handleRefreshToken = (dispatch) => {
    const data = jwt_decode(localStorage.getItem("token"));
    let remainingTime;
    if (data) {
        remainingTime = (new Date((data?.exp) * 1000).getTime() - new Date().getTime()) - 120000;
        clearTimeout(tokenTimeId);
        tokenTimeId = setTimeout(() => {
            if (getToken() && (new Date(jwt_decode(localStorage.getItem("token")).exp * 1000).getTime() - new Date().getTime()) <= 120000) {
                refreshOnce(dispatch);
            }
        }, remainingTime);
    }
};

const refreshOnce = async (dispatch) => {
    if (getToken()) {
        await axios.post(`${baseUrl}/api/v1/refresh-token`, { access_token: localStorage.getItem("token") })
            .then(data => {
                dispatch(setAllowPermission(data.data.data.role));
                localStorage.setItem("token", data.data.data.access_token);
                handleRefreshToken(dispatch);
                if (data.data.data.presentorVdi && data.data.data.presentorIp) {
                    dispatch(getVDIPresenter({
                        presenterId: data.data.data.presentorVdi,
                        presenterIp: data.data.data.presentorIp,
                        presenterOs: data?.data?.data?.presenterOs,
                        presenterAddress: data?.data?.data?.presenterAddress,
                    }, true));
                }
            })
            .catch(err => {
                localStorage.removeItem("token");
                dispatch(clearReduxPersist());
                throw new Error(err);
            });
    }
};

const isLoggedIn = () => {
    const data = localStorage.getItem("token");
    return data !== null;
};

const getUsername = () => {
    return tokenOpen(localStorage.getItem("token")).name;
};

const getUserInfo = () => {
    return tokenOpen(localStorage.getItem("token"));
};

const UserService = {
    doLogout,
    isLoggedIn,
    refreshOnce,
    getToken,
    updateToken,
    getUsername,
    getUserInfo,
};

export default UserService;
