import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';

export enum ELastView {
    VIEW360 = "360view",
    MACHINES = "machines",
    CONFERENCE = "conference"
}
interface IMachineDetails {
    deviceId?: string;
    ipAddress?: string;
    accessType?: string;
    address?: string;
    os?: string;
}

interface IBranding {
    enabled?: boolean;
    name?: string;
    logo?: string;
    tagline?: string;
    links?: {
      privacy?: string;
      terms?: string;
    };
    callback?: string;
    customDomain?: {
      enabled?: boolean;
      domain?: string;
    };
    email?: string;
  }

interface IBasicInfo {
    userName?: string;
    emailId?: string;
    avatar?: string;
}

interface IRoot {
    waitingUser: boolean,
    session: boolean,
    activeMachine: Array<IMachineDetails>
    lastView: ELastView
    basicInfo?: IBasicInfo
    sendMessage: boolean,
    conferenceJoined: boolean,
    presenterPosition: { x: number, y: number }
    chatPosition: { x: number, y: number }
    serviceCollabId: string
    isFocused: boolean;
    showNotificationData: string;
    isCollabAvailable: boolean;
    roomPrefix: number;
    brandingData: IBranding;
}
const initialState: IRoot = {
    waitingUser: false,
    session: false,
    activeMachine: [],
    lastView: ELastView.CONFERENCE,
    sendMessage: false,
    conferenceJoined: false,
    presenterPosition: { x: 950, y: 70 },
    chatPosition: { x: window.innerWidth - 50, y: 10 },
    basicInfo: {
        userName: "",
        emailId: "",
        avatar: "",
    },
    serviceCollabId: "",
    isFocused: false,
    showNotificationData: "",
    isCollabAvailable: false,
    roomPrefix: null,
    brandingData: {
        enabled: false,
        name: "",
        tagline: "",
        logo: process.env.REACT_APP_LOADER_LOGO,
        links: {
          privacy: "",
          terms: "",
        },
        callback: "",
        customDomain: {
          enabled: false,
          domain: "",
        },
        email: "",
      },
};
const slice = createSlice({
    name: 'persistStore',
    initialState,
    reducers: {
        setWaitingUser(state, action) {
            state.waitingUser = action.payload;
        },
        setSessionValue(state, action) {
            state.session = action.payload;
        },
        setSendMessageValue(state, action) {
            state.sendMessage = action.payload;
        },
        setConferenceValue(state, action) {
            state.conferenceJoined = action.payload;
        },
        setActiveMachine(state, action) {
            state.activeMachine = [action.payload];
        },
        setClearActiveMachine(state) {
            state.activeMachine = [];
        },
        setCurrentView(state, action) {
            state.lastView = action.payload;
        },
        getPosition(state, action) {
            state.presenterPosition = action.payload;
        },
        getChatPosition(state, action) {
            state.chatPosition = action.payload;
        },
        setUserName(state, action) {
            state.basicInfo.userName = action.payload;
        },
        setEmailId(state, action) {
            state.basicInfo.emailId = action.payload;
        },
        setAvatar(state, action) {
            state.basicInfo.avatar = action.payload;
        },
        setServiceCollabId(state, action) {
            state.serviceCollabId = action.payload;
        },
        handleFocus(state, action) {
            state.isFocused = action.payload;
        },
        showNotification(state, action) {
            state.showNotificationData = action.payload;
        },
        handleIsCollabAvailable(state, action) {
            state.isCollabAvailable = action.payload;
        },
        roomPrefix(state, action) {
            state.roomPrefix = action.payload;
        },
        setBrandingData(state, action) {
            state.brandingData = action.payload;
        },
    }
});

export const setWaitingUser = (data: boolean): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setWaitingUser(data));
};

export const setSession = (data: boolean): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setSessionValue(data));
};

export const setSendMessage = (data: boolean): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setSendMessageValue(data));
};

export const setConference = (data: boolean): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setConferenceValue(data));
};

export const storeAccessActiveMachine = (data: IMachineDetails): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setActiveMachine(data));
};

export const removeAccessActiveMachine = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setClearActiveMachine());
};

export const setLastView = (data: ELastView): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setCurrentView(data));
};

export const storePosition = (data) => async (dispatch) => {
    dispatch(slice.actions.getPosition(data));
};

export const storeChatPosition = (data) => async (dispatch) => {
    dispatch(slice.actions.getChatPosition(data));
};

export const storeUserName = (data) => async (dispatch) => {
    dispatch(slice.actions.setUserName(data));
};

export const storeEmailId = (data) => async (dispatch) => {
    dispatch(slice.actions.setEmailId(data));
};
export const storeAvatar= (data) => async (dispatch) => {
    dispatch(slice.actions.setAvatar(data));
};
export const clearReduxPersist = () => async (dispatch) => {
    dispatch(slice.actions.setWaitingUser(false));
    dispatch(slice.actions.setSessionValue(false));
    dispatch(slice.actions.setConferenceValue(false));
    dispatch(slice.actions.setSendMessageValue(false));
    dispatch(slice.actions.setClearActiveMachine());
    dispatch(slice.actions.setCurrentView(ELastView.CONFERENCE));
    dispatch(slice.actions.setBrandingData(initialState?.brandingData));
};

export const setServiceCollab = (data) => async (dispatch) => {
    dispatch(slice.actions.setServiceCollabId(data));
  };

export const handleFocusPip = (data) => (dispatch) => {
  dispatch(slice.actions.handleFocus(data));
};

export const showNotification = (data) => (dispatch) => {
    dispatch(slice.actions.showNotification(data));
  };

export const handleIsCollabAvailable = (data) => (dispatch) => {
    dispatch(slice.actions.handleIsCollabAvailable(data));
};
export const roomPrefix = (data) => (dispatch) => {
    dispatch(slice.actions.roomPrefix(data));
};

export const BrandingData = (data) => (dispatch) => {
    dispatch(slice.actions.setBrandingData(data));
};

export const reducer = slice.reducer;
export default slice;
