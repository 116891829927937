import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { useSnackbar, WithSnackbarProps } from "notistack";
import {
  IGroups,
  IParticipants,
  IServicesDevice,
  IParticipantsList,
  IDevicesList,
  IEmailList,
  IGroupList,
  ICurrentDeallocateDevice,
} from "src/models/infrastructure";
import jwt_decode from "jwt-decode";
import { reducer as vdiserviceslice } from "src/slices/vdiserviceTable";
import { storeCurrentGroupID } from "../waitingAV";
import Permission from "src/permision.json";
let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

enum ECOLOR {
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
}

interface IAssignedMachineData {
  process: string;
  serviceId: string;
  type: string;
}

interface IResourceAssignment {
  groups: {
    [key: string]: IGroups;
  };
  participants: IParticipants[];
  filterParticipants: IParticipants[];
  currentDeallocateDevice: ICurrentDeallocateDevice[];
  userTablePage: number;
  participantsList: IParticipantsList[];
  devicesList: IDevicesList[];
  servicesDevice: IServicesDevice[];
  UnassignServices: IServicesDevice[];
  destroyedSerivices: IServicesDevice[];
  assignServices: IServicesDevice[];
  allServices: IServicesDevice[];
  toggleDrawer: boolean;
  AllocateDialog: boolean;
  serviceUpdate: {
    [key: string]: any;
  };
  filterValue: {
    type: string;
    value: string[];
  }[];
  filterNames: {
    id: string;
    lable: string;
  }[];
  filterValueTable: {
    type: string;
    value: string[] | string | boolean;
  }[];
  filterNamesTable: {
    id: string;
    lable: string;
  }[];
  participantsName: string[];
  emailList: IEmailList[];
  groupList: IGroupList[];
  servicesId: string;
  participantId: string;
  action: string;
  deviceId: string;
  PtoSActive: boolean;
  StoPActive: boolean;
  snack: {
    active: boolean;
    color: ECOLOR;
    message: string;
  };
  instanceFilter: string;
  VDIOption: {
    active: boolean;
    message: string;
    color: string;
  };
  currentPageNumber: number;
  promptData: {
    active: boolean;
    body?: {
      device: string;
      participant_id: string;
      access: string;
    };
    groupId?: string;
  };
  participantsData: IParticipants[];
  locked: boolean;
  lockedMsg: string;
  updateGroupNotify: {
    active: boolean;
    message: string;
  };
  UnassignServicesAllVDI: IServicesDevice[];
  ConfirmDestroy: {
    confirmMessage: string;
    userConfirmation: string;
  };
  OperationMachine: ICurrentDeallocateDevice[];
  currentTabOpened: boolean;
  assignedMachineData: IAssignedMachineData[];
  isShowBulkDestroyActiveMachine: boolean;
  showMaxLimitDisclaimer: boolean;
}

const initialState: IResourceAssignment = {
  groups: {},
  participants: [],
  filterParticipants: [],
  currentDeallocateDevice: [],
  userTablePage: 0,
  servicesDevice: [],
  UnassignServices: [],
  destroyedSerivices: [],
  assignServices: [],
  allServices: [],
  participantsList: [],
  devicesList: [],
  toggleDrawer: false,
  AllocateDialog: false,
  filterValue: [],
  filterNames: [],
  filterValueTable: [],
  filterNamesTable: [],
  participantsName: [],
  emailList: [],
  groupList: [],
  servicesId: "",
  participantId: "",
  action: "",
  deviceId: "",
  PtoSActive: false,
  StoPActive: false,
  snack: {
    active: false,
    color: ECOLOR.INFO,
    message: "",
  },
  serviceUpdate: {},
  instanceFilter: "Unassigned",
  VDIOption: {
    active: false,
    message: "",
    color: "",
  },
  currentPageNumber: 1,
  promptData: {
    active: false,
  },
  participantsData: [],
  locked: false,
  lockedMsg: "",
  updateGroupNotify: {
    active: false,
    message: "",
  },
  UnassignServicesAllVDI: [],
  ConfirmDestroy: {
    confirmMessage: "CONFIRM",
    userConfirmation: "",
  },
  OperationMachine: [],
  currentTabOpened: false,
  assignedMachineData: [],
  isShowBulkDestroyActiveMachine: false,
  showMaxLimitDisclaimer: false,
};

const ResourceAllocationSlice = createSlice({
  name: "vdiservice",
  initialState,
  reducers: {
    participants(state, action) {
      state.participants = action.payload;
      state.filterParticipants = action.payload;
      const arrayName = [];
      const arrayEmail = [];
      action.payload.forEach((value) => {
        arrayName.push({ id: value.id, name: value.name });
        arrayEmail.push({ id: value.id, name: value.emailId });
      });

      state.participantsList = arrayName;
      state.emailList = arrayEmail;
    },
    setUpdateFilterParticipants(state, action) {
      state.filterParticipants = action.payload;
    },
    setUserTablePage(state, action) {
      state.userTablePage = action.payload;
    },
    availableGroups(state, action) {
      const arrayGroup = [];
      if (action.payload?.length > 0) {
        action.payload.forEach((value) => {
          arrayGroup.push({ id: value.id, name: value.name });
        });

        state.groupList = arrayGroup;
        const arrayToObject = (arr, key) => {
          return arr.reduce((obj, item) => {
            obj[item[key]] = item;
            return obj;
          }, {});
        };
        state.groups = arrayToObject(action.payload, "id");
      }
    },
    availableservices(state, action) {
      if (action.payload) {
        state.allServices = action.payload;
        state.serviceUpdate = action.payload.reduce((acc, cur) => {
          acc[cur["id"]] = cur.state;
          return acc;
        }, {});
        state.UnassignServicesAllVDI = action.payload.filter(
          (value) => !value.access.find((a) => a.access !== "host")
        );
      } else {
        state.allServices = [];
      }
    },
    toggleDrawer(state, action) {
      state.toggleDrawer = action.payload;
    },
    AllocateDialog(state, action) {
      state.AllocateDialog = action.payload;
    },
    storeServiceDevice(state, action) {
      state.servicesDevice = action.payload;
      state.UnassignServices = action.payload?.filter(
        (value) =>
          !value.access.find((a) => a.access !== "host") &&
          value.state !== "DESTROYED"
      );
      state.assignServices = action.payload?.filter((value) =>
        value.access.find((a) => a.access !== "host")
      );
      state.destroyedSerivices = action.payload.filter(
        (value) => value.state === "DESTROYED" || value.state === "DESTROYING"
      );
    },
    storeFilterValue(state, action) {
      state.filterValue = action.payload;
    },
    storeFilterNames(state, action) {
      state.filterNames = action.payload;
    },
    storeFilterValueTable(state, action) {
      state.filterValueTable = action.payload;
    },
    storeFilterNamesTable(state, action) {
      state.filterNamesTable = action.payload;
    },
    storeFilterServiceDevices(state, action) {
      const array = [];
      action.payload?.forEach((value) => {
        if (value.state !== "REGISTERED") {
          array.push({ id: value.id, name: value.name });
        }
      });

      state.devicesList = array;
    },
    setParticiapntId(state, action) {
      state.participantId = action.payload;
    },
    setServiceId(state, action) {
      state.servicesId = action.payload;
    },
    setAction(state, action) {
      state.action = action.payload;
    },
    setDeviceId(state, action) {
      state.deviceId = action.payload;
    },
    setPtoSActive(state, action) {
      state.PtoSActive = action.payload;
    },
    setStoPActive(state, action) {
      state.StoPActive = action.payload;
    },
    setSnack(state, action) {
      state.snack = action.payload;
    },
    setServiceUpdate(state, action) {
      state.serviceUpdate[action.payload.key] = action.payload.value;
    },
    setParticipantsUpdate(state, action) {
      state.participants.forEach((value) => {
        if (value.id === action.payload.key) {
          value.lock = action.payload.value;
        }
      });
      state.participantsData.forEach((value) => {
        if (value.id === action.payload.key) {
          value.lock = action.payload.value;
        }
      });
    },
    setFilterValue(state, action) {
      state.instanceFilter = action.payload;
    },
    VDIOptions(state, action) {
      state.VDIOption = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPageNumber = action.payload;
    },
    setPropmtData(state, action) {
      state.promptData = action.payload;
    },
    setParticipantData(state, action) {
      state.participantsData = action.payload;
    },
    setLocked(state, action) {
      state.locked = action.payload;
    },
    setLockedMsg(state, action) {
      state.lockedMsg = action.payload;
    },
    setUpdateGroupSnack(state, action) {
      state.updateGroupNotify = action.payload;
    },
    setDeallocateAll(state, action) {
      state.currentDeallocateDevice = action.payload;
    },
    setDestroyMessage(state, action) {
      state.ConfirmDestroy[action.payload.key] = action.payload.value;
    },
    setDestroyMessageClear(state) {
      state.ConfirmDestroy.userConfirmation = "";
    },
    setOperationMachine(state, action) {
      state.OperationMachine = action.payload;
    },
    setOperationMachineClear(state) {
      state.OperationMachine = [];
    },
    setSortingParticipants(state, action) {
      state.participants = action.payload;
    },
    setCurrentTabOpened(state, action) {
      state.currentTabOpened = action.payload;
    },
    assignedMachineData(state, action) {
      state.assignedMachineData = action.payload;
    },
    showBulkDestroyActiveMachine(state, action) {
      state.isShowBulkDestroyActiveMachine = action.payload;
    },
    handleShowMaxLimitDisclaimer(state, action) {
      state.showMaxLimitDisclaimer = action.payload;
    },
  },
});

const LINK = `${process.env.REACT_APP_API_URL}/api/v1`;
type CONTEXTID = {
  sub: string;
  contextId: string;
};
export const getParticipants = () => async (dispatch) => {
  const response = await axios.get(`${LINK}/participants`);
  if (response.data.status) {
    dispatch(ResourceAllocationSlice.actions.participants(response.data.data));
    dispatch(vdiserviceslice.actions.setDataNotFound(false));
  }
};

export const AssignAllocate = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.AllocateDialog(data));
};

export const SortingParticants = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setSortingParticipants(data));
};

export const VDIOptions = (data) => async (dispatch) => {
  const response = await axios.post(`${LINK}/service`, data);

  if (response.data.status) {
    dispatch(
      ResourceAllocationSlice.actions.VDIOptions({
        active: true,
        color: "success",
        message: "Operation is successfully",
      })
    );
  } else {
    dispatch(
      ResourceAllocationSlice.actions.VDIOptions({
        active: true,
        color: "error",
        message: "Operation failed",
      })
    );
  }

  setTimeout(() => {
    dispatch(
      ResourceAllocationSlice.actions.VDIOptions({
        active: false,
        message: "",
        color: "",
      })
    );
  }, 3000);
};

export const showBulkDestroyActiveMachine = (data) => (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.showBulkDestroyActiveMachine(data));
};

export const bulkDestroyMachine = (data, enqueueSnackbar) => async () => {
  try {
    const deleteMachineResponse = await axios.post(`${LINK}/service`, data);

    if (!deleteMachineResponse?.data?.status) {
      throw new Error(
        deleteMachineResponse?.data?.message || "Machine destroy failed"
      );
    }
  } catch (error) {
    enqueueSnackbar(error?.message || error || "Machine destroy failed", {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }
};

export const assignedMachineData = (data) => (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.assignedMachineData(data));
};
export const setUpdateFilterParticipant = (data) => (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setUpdateFilterParticipants(data));
};

export const DeAllocate = (data) => async (dispatch) => {
  const response = await axios.put(`${LINK}/access/revoke-access`, data);

  if (response.data.status) {
    dispatch(
      ResourceAllocationSlice.actions.VDIOptions({
        active: true,
        color: "success",
        message: response.data.data,
      })
    );
  } else {
    dispatch(
      ResourceAllocationSlice.actions.VDIOptions({
        active: true,
        color: "error",
        message: response.data.message,
      })
    );
  }

  setTimeout(() => {
    dispatch(
      ResourceAllocationSlice.actions.VDIOptions({
        active: false,
        message: "",
        color: "",
      })
    );
  }, 3000);
};

export const getAllServices =
  (updateState = false) =>
  async (dispatch) => {
    const responseservices: any = await axios.get(`${LINK}/service`);
    if (
      responseservices.data.status &&
      responseservices?.data?.data?.length > 0
    ) {
      dispatch(
        ResourceAllocationSlice.actions.availableservices(
          responseservices.data.data
        )
      );
      if (!updateState) {
        dispatch(
          ResourceAllocationSlice.actions.storeFilterServiceDevices(
            responseservices.data.data
          )
        );
        dispatch(
          vdiserviceslice.actions.setSkeleton({ key: "instance", value: false })
        );
      }
    } else {
      dispatch(ResourceAllocationSlice.actions.availableservices([]));
    }
  };

export const serviceUpdate = (data) => async (dispatch) => {
  dispatch(
    ResourceAllocationSlice.actions.setServiceUpdate({
      key: data.response.data.serviceId,
      value: data.response.data.state,
    })
  );
  dispatch(getAllServices(true));
  dispatch(getParticipants());
};

export const participantsUpdate = (data) => async (dispatch) => {
  dispatch(
    ResourceAllocationSlice.actions.setParticipantsUpdate({
      key: data.response.data.participant_id,
      value: data.response.data.lock,
    })
  );
};
export const toggleResourceDrawer = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.toggleDrawer(data));
};

export const fetchingGourpData =
  (data, group = false, groupId = "", hitFirst = false) =>
  async (dispatch) => {
    const config = {
      headers: {
        contextId: data,
      },
    };
    if (group && hitFirst) {
      const response: any = await axios.get(`${LINK}/group`, config);
      if (response.data.status && response.data.data.length > 0) {
        dispatch(
          ResourceAllocationSlice.actions.availableGroups(response.data.data)
        );
        dispatch(
          vdiserviceslice.actions.setSkeleton({ key: "group", value: false })
        );
        if (groupId) {
          const responseservices: any = await axios.get(
            `${LINK}/service?groupId=${groupId}`
          );
          dispatch(
            ResourceAllocationSlice.actions.storeServiceDevice(
              responseservices.data.data
            )
          );
          dispatch(storeCurrentGroupID(groupId));
        } else {
          const responseservices: any = await axios.get(
            `${LINK}/service?groupId=${response.data.data[0].id}`
          );
          dispatch(
            ResourceAllocationSlice.actions.storeServiceDevice(
              responseservices.data.data
            )
          );
          dispatch(storeCurrentGroupID(response.data.data[0].id));
        }
      }
    }
  };

export const fetcingLiveServices = (data, groupId) => async (dispatch) => {
  if (data.response.data.group_id) {
    const config = {
      headers: {
        contextId: window.location.href.split("/")[4],
      },
    };
    const response: any = await axios.get(`${LINK}/group`, config);
    dispatch(
      ResourceAllocationSlice.actions.availableGroups(response.data.data)
    );
    if (groupId === data.response.data.group_id) {
      const responseservices: any = await axios.get(
        `${LINK}/service?groupId=${data.response.data.group_id}`
      );
      if (responseservices.data.status) {
        dispatch(
          ResourceAllocationSlice.actions.storeServiceDevice(
            responseservices.data.data
          )
        );
      }
    }
  }
};

export const getGroupData = (groupId) => async (dispatch) => {
  try {
    const responseservices: any = await axios.get(
      `${LINK}/service?groupId=${groupId}`
    );

    if (!responseservices?.data?.status) {
      throw new Error(
        responseservices?.data?.message || "Could not fetch group details"
      );
    }

    if (responseservices.data.status) {
      dispatch(
        ResourceAllocationSlice.actions.storeServiceDevice(
          responseservices.data.data
        )
      );
    }
  } catch (error) {
    useSnackbarRef.enqueueSnackbar(
      error?.message || error || "Could not fetch group details",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const createVdiGroup = (data, enqueueSnackbar) => async (dispatch) => {
  const group1 =
    data?.customImg === "0" || !data?.customImg
      ? {
          name: data.name,
          subRecordType: "virtual_desktop_infrastructure_browser",
          properties: {
            instanceCount: Number(data.instanceCount),
            instantiationType: data.instantiationType,
          },
          instantiation: {
            type: "absolute",
            time: "2022-02-23T12:44:37.008+00:00",
            offset: 5,
          },
          userData: {
            location: data.plan.locations,
            os: data.plan.os,
            provider: data.plan.provider,
            cpu_count: data.plan.properties.CPU,
            ram: data.plan.properties.RAM,
            ssd: data.plan.properties.HDD,
            planId: data.plan.id,
          },
        }
      : {
          name: data.name,
          subRecordType: "virtual_desktop_infrastructure_browser",
          properties: {
            instanceCount: Number(data.instanceCount),
            instantiationType: data.instantiationType,
          },
          instantiation: {
            type: "absolute",
            time: "2022-02-23T12:44:37.008+00:00",
            offset: 5,
          },
          userData: {
            location: data.plan.locations,
            os: data.plan.os,
            provider: data.plan.provider,
            cpu_count: data.plan.properties.CPU,
            ram: data.plan.properties.RAM,
            ssd: data.plan.properties.HDD,
            planId: data.plan.id,
          },
          customImage: {
            id: data?.customImg,
            enabled: true,
          },
        };

  dispatch(
    ResourceAllocationSlice.actions.setSnack({
      active: true,
      color: ECOLOR.SUCCESS,
      message: "Requesting to create a group",
    })
  );
  const group = group1;

  const config = {
    headers: {
      contextId: data.contextId,
    },
  };
  const response = await axios.post(`${LINK}/group`, group, config);
  if (response.data.status) {
    dispatch(
      ResourceAllocationSlice.actions.setSnack({
        active: true,
        color: ECOLOR.SUCCESS,
        message: "Group Created Successfully",
      })
    );
  }
  dispatch(handleMyLicenseApi(enqueueSnackbar));
  setTimeout(() => {
    dispatch(
      ResourceAllocationSlice.actions.setSnack({
        active: false,
        color: ECOLOR.SUCCESS,
        message: "",
      })
    );
  }, 3000);
};

export const updatePlanVDI =
  (data, groupId, allowedPermissions) => async (dispatch) => {
    const body = {
      location: data.location,
      os: data.os,
      provider: data.provider,
      cpu_count: data.plan.properties.CPU,
      ram: data.plan.properties.RAM,
      ssd: data.plan.properties.HDD,
      planId: data.plan.id,
    };

    const config = {
      headers: {
        contextId: data.contextId,
      },
    };
    const response = await axios.put(
      `${LINK}/group/${groupId}/userdata`,
      body,
      config
    );

    if (response.data.status) {
      dispatch(
        ResourceAllocationSlice.actions.setSnack({
          active: true,
          color: ECOLOR.SUCCESS,
          message: "Group Upgraded",
        })
      );
      const CONTEXTID: CONTEXTID = jwt_decode(localStorage.getItem("token"));
      dispatch(
        fetchingGourpData(
          CONTEXTID.contextId,
          allowedPermissions?.includes(Permission.VIEW_GROUP),
          "",
          true
        )
      );
    }
    setTimeout(() => {
      dispatch(
        ResourceAllocationSlice.actions.setSnack({
          active: false,
          color: ECOLOR.SUCCESS,
          message: "",
        })
      );
    }, 3000);
  };

export const IncreaseInstance =
  (contextId, groupId, count, MachineCount, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(
      ResourceAllocationSlice.actions.setSnack({
        active: true,
        color: ECOLOR.SUCCESS,
        message: `Requesting for machine PC-${MachineCount + 1} `,
      })
    );

    setTimeout(() => {
      dispatch(
        ResourceAllocationSlice.actions.setSnack({
          active: false,
          color: ECOLOR.SUCCESS,
          message: "",
        })
      );
    }, 3000);

    const config = {
      headers: {
        contextId: contextId,
      },
    };
    const body = {
      instanceCount: count + 1,
    };

    const response = await axios.put(
      `${LINK}/group/${groupId}/properties`,
      body,
      config
    );
    if (response.data.status) {
      dispatch(
        ResourceAllocationSlice.actions.setSnack({
          active: true,
          color: ECOLOR.SUCCESS,
          message: `Machine PC-${MachineCount + 1} Added`,
        })
      );
    }
    dispatch(handleMyLicenseApi(enqueueSnackbar));
    setTimeout(() => {
      dispatch(
        ResourceAllocationSlice.actions.setSnack({
          active: false,
          color: ECOLOR.SUCCESS,
          message: "",
        })
      );
    }, 3000);
  };

export const AssignInstance =
  (
    particiapntId,
    serviceId,
    groupId,
    action,
    deviceId,
    StoPActive,
    PtoSActive,
    ASSIGN_SERVICE,
    REVOKE_SERVICE,
    enqueueSnackbar
  ) =>
  async (dispatch) => {
    const body = {
      device: serviceId,
      access: "owner",
    };

    if (action === "StoP" && PtoSActive && ASSIGN_SERVICE) {
      const response = await axios.put(
        `${LINK}/participants/${particiapntId}/deviceAssign`,
        [body]
      );

      if (response.data.status) {
        if (response.data.data.find((value) => value.status === false)) {
          const val = response.data.data.find(
            (value) => value.status === false
          );
          if (
            val?.message ===
            "You have reached maximum instace limit for your account"
          ) {
            dispatch(handleMyLicenseApi(enqueueSnackbar));
            return;
          }
          dispatch(
            ResourceAllocationSlice.actions.setSnack({
              active: true,
              color: ECOLOR.SUCCESS,
              message: response.data.data.find(
                (value) => value.status === false
              ).message,
            })
          );
          setTimeout(() => {
            dispatch(
              ResourceAllocationSlice.actions.setSnack({
                active: false,
                color: ECOLOR.SUCCESS,
                message: "",
              })
            );
          }, 3000);
        } else {
          // dispatch(
          //   ResourceAllocationSlice.actions.setSnack({
          //     active: true,
          //     color: ECOLOR.SUCCESS,
          //     message: "Allocation was Successful",
          //   })
          // );
          // setTimeout(() => {
          //   dispatch(
          //     ResourceAllocationSlice.actions.setSnack({
          //       active: false,
          //       color: ECOLOR.SUCCESS,
          //       message: "",
          //     })
          //   );
          // }, 3000);
        }
      } else {
        dispatch(
          ResourceAllocationSlice.actions.setSnack({
            active: true,
            color: ECOLOR.ERROR,
            message: response.data.message,
          })
        );
        setTimeout(() => {
          dispatch(
            ResourceAllocationSlice.actions.setSnack({
              active: false,
              color: ECOLOR.SUCCESS,
              message: "",
            })
          );
        }, 3000);
      }
    } else if (action === "PtoS" && StoPActive && REVOKE_SERVICE) {
      const data = {
        active: true,
        body: {
          device: deviceId,
          participant_id: particiapntId,
          access: "owner",
        },
        groupId,
      };

      dispatch(ResourceAllocationSlice.actions.setPropmtData(data));
    } else if (action === "PtoS" && PtoSActive && ASSIGN_SERVICE) {
      const body = {
        device: deviceId,
        access: "owner",
      };
      const response = await axios.put(
        `${LINK}/participants/${particiapntId}/deviceAssign`,
        [body]
      );

      if (response.data.status) {
        const errorValue = response.data.data.find((value) => value.status === false);
        if (errorValue) {
          dispatch(ResourceAllocationSlice.actions.setSnack({
            active: true,
            color: ECOLOR.SUCCESS,
            message: errorValue.message,
          }));
        }
      } else {
        dispatch(ResourceAllocationSlice.actions.setSnack({
          active: true,
          color: ECOLOR.ERROR,
          message: response.data.message,
        }));
        setTimeout(() => {
          dispatch(ResourceAllocationSlice.actions.setSnack({
            active: false,
            color: ECOLOR.SUCCESS,
            message: "",
          }));
        }, 3000);
      }
    }

    dispatch(StoPStateChange(false));
    dispatch(PtoSStateChange(false));
  };

export const AllocateAssign =
  (participantId, data, enqueueSnackbar) => async (dispatch) => {
    const response = await axios.put(
      `${LINK}/participants/${participantId}/deviceAssign`,
      data
    );
    if (response.data.status) {
      if (response.data.data.find((value) => value.status === false)) {
        const val = response.data.data.find((value) => value.status === false);
        if (
          val?.message ===
          "You have reached maximum instace limit for your account"
        ) {
          dispatch(handleMyLicenseApi(enqueueSnackbar));
          return;
        }
        dispatch(
          ResourceAllocationSlice.actions.setSnack({
            active: true,
            color: ECOLOR.SUCCESS,
            message: response.data.data.find((value) => value.status === false)
              .message,
          })
        );
        setTimeout(() => {
          dispatch(
            ResourceAllocationSlice.actions.setSnack({
              active: false,
              color: ECOLOR.SUCCESS,
              message: "",
            })
          );
        }, 3000);
      } else {
        dispatch(
          ResourceAllocationSlice.actions.setSnack({
            active: true,
            color: ECOLOR.SUCCESS,
            message: "Instance Assigned",
          })
        );
        setTimeout(() => {
          dispatch(
            ResourceAllocationSlice.actions.setSnack({
              active: false,
              color: ECOLOR.SUCCESS,
              message: "",
            })
          );
        }, 3000);
      }
    } else {
      dispatch(
        ResourceAllocationSlice.actions.setSnack({
          active: true,
          color: ECOLOR.ERROR,
          message: response.data.message,
        })
      );
      setTimeout(() => {
        dispatch(
          ResourceAllocationSlice.actions.setSnack({
            active: false,
            color: ECOLOR.SUCCESS,
            message: "",
          })
        );
      }, 3000);
    }
  };

export const revokeAPIconfirmationAccept = (data) => async (dispatch) => {
  const response = await axios.put(`${LINK}/access/revoke-access`, data.body);

  if (response.data.status) {
    dispatch(
      ResourceAllocationSlice.actions.setSnack({
        active: true,
        color: ECOLOR.SUCCESS,
        message: "Machine Revoked",
      })
    );
    dispatch(ResourceAllocationSlice.actions.setPropmtData({ active: false }));
    setTimeout(() => {
      dispatch(
        ResourceAllocationSlice.actions.setSnack({
          active: false,
          color: ECOLOR.SUCCESS,
          message: "",
        })
      );
    }, 3000);
  }
  dispatch(StoPStateChange(false));
  dispatch(PtoSStateChange(false));
};

export const revokeAPIconfirmationCancel = () => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setPropmtData({ active: false }));

  dispatch(StoPStateChange(false));
  dispatch(PtoSStateChange(false));
};

export const setSnackValue = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setSnack(data));
};

export const fetchingServicesDevices = (data) => async (dispatch) => {
  dispatch(storeCurrentGroupID(data));
  const response = await axios.get(`${LINK}/service?groupId=${data}`);
  dispatch(
    ResourceAllocationSlice.actions.storeServiceDevice(response.data.data)
  );
};

export const setParticiapntID = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setParticiapntId(data));
};

export const setServicesID = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setServiceId(data));
};

export const setMoveAction = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setAction(data));
};

export const deviceID = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setDeviceId(data));
};
export const PtoSStateChange = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setPtoSActive(data));
};

export const participantsIsLocked = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setLocked(data));
};

export const lockedMsg = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setLockedMsg(data));
};

export const StoPStateChange = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setStoPActive(data));
};

export const storeFilterValue = (value) => async (dispatch) => {
  //eslint-disable-next-line
  console.log("value", value);
  dispatch(ResourceAllocationSlice.actions.setFilterValue(value));
};

export const setCurrentPageNumber = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setCurrentPage(data));
};

export const storeParticipantsDataFilter = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setParticipantData(data));
};

export const groupUpdatedNotification = () => async (dispatch) => {
  dispatch(
    ResourceAllocationSlice.actions.setUpdateGroupSnack({
      active: true,
      message: "Group Updating",
    })
  );
  setTimeout(() => {
    dispatch(
      ResourceAllocationSlice.actions.setUpdateGroupSnack({
        active: false,
        message: "",
      })
    );
  }, 3000);
};

export const dispatchUserTablePage = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setUserTablePage(data));
};

export const UpdateGroupVdiGroup = (data) => async (dispatch) => {
  const contextId = window.location.href.split("/")[4];
  const config = {
    headers: {
      contextId: contextId,
    },
  };
  const response: any = await axios.get(`${LINK}/group`, config);
  dispatch(ResourceAllocationSlice.actions.availableGroups(response.data.data));
  dispatch(storeCurrentGroupID(data.response.data.group_id));
  dispatch(setCurrentPageNumber(response.data.data.length));
  if (response.data.status) {
    const responseservices = await axios.get(
      `${LINK}/service?groupId=${data.response.data.group_id}`
    );
    const responseservicesUpdate: any = await axios.get(`${LINK}/service`);
    if (responseservicesUpdate.data.status) {
      dispatch(
        ResourceAllocationSlice.actions.availableservices(
          responseservicesUpdate.data.data
        )
      );
    }
    dispatch(
      ResourceAllocationSlice.actions.storeServiceDevice(
        responseservices.data.data
      )
    );
  }
};

export const UpdateGroupVdiGroupStatus = () => async (dispatch) => {
  const contextId = window.location.href.split("/")[4];
  const config = {
    headers: {
      contextId: contextId,
    },
  };
  const response: any = await axios.get(`${LINK}/group`, config);
  dispatch(ResourceAllocationSlice.actions.availableGroups(response.data.data));
};

export const setDeallocateMultiple = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setDeallocateAll(data));
};

export const setMachineOperation = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setOperationMachine(data));
};

export const destroyMessageConfrimation = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setDestroyMessage(data));
};

export const DestroyConfrimationClear = () => async (dispatch: any) => {
  dispatch(ResourceAllocationSlice.actions.setDestroyMessageClear());
};
export const MachineOperationClear = () => async (dispatch: any) => {
  dispatch(ResourceAllocationSlice.actions.setOperationMachineClear());
};

export const handleMachineStopNotification = (data) => () => {
  if (data.state === "STOPPING" || data.state === "STOPPED") {
    useSnackbarRef.enqueueSnackbar(
      `${data.state.charAt(0).toUpperCase()}${data.state
        .toLowerCase()
        .slice(1)} ${data.serviceName}`,
      {
        variant: data.state === "STOPPING" ? "warning" : "success",
        autoHideDuration: 5000,
      }
    );
  } else if (data.message === "Performing delete on VDI") {
    useSnackbarRef.enqueueSnackbar(`${data.message} ${data.serviceName}`, {
      variant: "success",
      autoHideDuration: 5000,
    });
  }
};

export const CurrentTabOpened = (data) => async (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.setCurrentTabOpened(data));
};

export const handleShowMaxLimitDisclaimer = (data) => (dispatch) => {
  dispatch(ResourceAllocationSlice.actions.handleShowMaxLimitDisclaimer(data));
};

export const handleMyLicenseApi = (enqueueSnackbar) => async (dispatch) => {
  try {
    const getLicenseResponse = await axios.get(`${LINK}/my-license`);

    if (!getLicenseResponse?.data?.status) {
      throw new Error(
        getLicenseResponse?.data?.message || "Could not fetch license details"
      );
    }

    if (
      getLicenseResponse.data?.data?.current_instance >=
      getLicenseResponse.data?.data?.limits?.maximumInstanceCount
    ) {
      dispatch(handleShowMaxLimitDisclaimer(true));
    }
  } catch (error) {
    enqueueSnackbar(
      error?.message || error || "Could not fetch license details",
      {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }
    );
  }
};

export const reducer = ResourceAllocationSlice;

export default ResourceAllocationSlice;
