import { createSlice } from '@reduxjs/toolkit';
import { ITest, IAddQuestion } from 'src/models/gaamification';
import axios from "src/utils/axios";
import objectArray from "src/utils/objectArray";
// import URLs from 'src/URL.json'

enum ECOLOR {
    PRIMARY = "primary",
    ERROR = "error",
    SUCCESS = "success"
}

interface IRoot {
    test?: ITest,
    question?: IAddQuestion
    allQuestions?: IAddQuestion
    [key: string]: any,
    addQuestionStatus: boolean
    showQuestion: IAddQuestion[],
    pollResonseStatus: boolean
    pollResponse: {
        option_id?: string,
        option?: string,
        percentage?: number,
        [key: string]: any
    },
    answer: {
        id: string,
        correctId: string
        color: ECOLOR,
        submit: boolean,
    },

}

const initialState: IRoot = {
    addQuestionStatus: false,
    showQuestion: [],
    answer: {
        id: '',
        correctId: '',
        color: ECOLOR.PRIMARY,
        submit: false,
    },
    pollResonseStatus: false,
    pollResponse: {}
};

const slice = createSlice({
    name: 'gamification',
    initialState,
    reducers: {
        testData(state, action) {
            state.test = objectArray(action.payload, "id");
        },
        testQuestion(state, action) {
            state.allQuestions = objectArray(action.payload, "id");
        },
        setAddQuestion(state, action) {
            state.addQuestionStatus = action.payload;
        },
        addQuestion(state, action) {
            state.question = action.payload;
        },
        fillQuestion(state, action) {
            state.question[action.payload.key] = action.payload.value;
        },
        addOptions(state, action) {
            const data = state.question.options;
            Object.assign(data, { [action.payload.options.id]: { ...action.payload.options } });
            state.question.options = data;
        },
        addOptionData(state, action) {
            state.question.options[action.payload.key].option = action.payload.value;
        },
        removeOption(state, action) {
            const data = state.question.options;
            delete data[action.payload];
            state.question.options = data;
        },
        isCorrect(state, action) {
            Object.keys(state.question.options).forEach(key => {
                state.question.options[key].isCorrect = false;
            });
            state.question.options[action.payload].isCorrect = true;
        },
        removeQuestion(state) {
            delete state.question;
        },
        storeQuestions(state, action) {
            if (state.showQuestion.length === 0) {
                state.showQuestion = [...state.showQuestion, action.payload];
            }
            else {
                state.showQuestion[0].questions = [...state.showQuestion[0].questions, ...action.payload.questions];
            }
        },
        closeQuestion(state) {
            state.showQuestion = [];
        },
        submitAns(state, action) {
            state.answer = action.payload;
        },
        nextQuestion(state) {
            state.showQuestion[0].questions = state.showQuestion[0].questions.splice(1, state.showQuestion[0].questions.length);
        },
        pollResposne(state, action) {
            state.pollResponse = objectArray(action.payload, "option_id");
        },
        pollResposneStatus(state, action) {
            state.pollResonseStatus = action.payload;
        }
    }
});

export const reducer = slice.reducer;

const BASE_URL = process.env.REACT_APP_API_URL;

export const getAllTest = () => async (dispatch) => {
    const response = await axios.get(`${BASE_URL}/api/v1/test`);
    if (response.data.status) {
        dispatch(slice.actions.testData(response.data.data));
    }
};

export const getAllQuestion = () => async (dispatch) => {
    const response = await axios.get(`${BASE_URL}/api/v1/question`);
    if (response.data.status) {
        dispatch(slice.actions.testQuestion(response.data.data));
    }
};

export const setAddQuestionStatus = (data) => async (dispatch) => {
    dispatch(slice.actions.setAddQuestion(data));
};

export const addQuestion = (id) => async (dispatch) => {
    const data = {
        type: "",
        parentId: id,
        question: "",
        score: 0,
        options: {}
    };
    dispatch(slice.actions.addQuestion(data));
};

export const fillQuestion = (data) => async (dispatch) => {
    dispatch(slice.actions.fillQuestion(data));
};

export const setOptions = () => async (dispatch) => {
    const data = {
        id: Date.now().toString(),
        option: "",
        isCorrect: false
    };
    dispatch(slice.actions.addOptions({ options: data }));
};

export const addOptionValue = (data) => async (dispatch) => {
    dispatch(slice.actions.addOptionData(data));
};

export const removeOptions = (data) => async (dispatch) => {
    dispatch(slice.actions.removeOption(data));
};

export const isCorrectHandler = (data) => async (dispatch) => {
    dispatch(slice.actions.isCorrect(data));
};

export const removeQuestion = () => async (dispatch) => {
    dispatch(slice.actions.removeQuestion());
    dispatch(slice.actions.setAddQuestion(false));
};

export const addQuestionInAPI = (value, result) => async (dispatch) => {
    const data = {
        type: value.type,
        parentId: value.parentId,
        question: value.question,
        score: Number(value.score),
        options: result
    };
    const response = await axios.post(`${BASE_URL}/api/v1/question`, data);
    if (response.data.status) {
        dispatch(slice.actions.setAddQuestion(false));
        dispatch(getAllQuestion());
        dispatch(getAllTest());
    }
};

export const changeStatus = (id, status) => async (dispatch) => {
    const data = {
        status: status
    };
    const response = await axios.put(`${BASE_URL}/api/v1/test/${id}/updateStatus`, data);
    if (response.data.status) {
        dispatch(getAllTest());
    }
};

export const submitQuestion = (data, type) => async (dispatch) => {
    const response = await axios.post(`${BASE_URL}/api/v1/question/submission`, data);
    if (type === "mcq") {
        if (response.data.status) {
            if (response.data.data.answerOptionId === data.option_id) {
                dispatch(slice.actions.submitAns({ id: data.option_id, correctId: response.data.data.answerOptionId, color: ECOLOR.SUCCESS, submit: true }));
            } else if (response.data.data.answerOptionId !== data.option_id) {
                dispatch(slice.actions.submitAns({ id: data.option_id, correctId: response.data.data.answerOptionId, color: ECOLOR.ERROR, submit: true }));
            }
        }
    } else if (type === "poll") {
        dispatch(slice.actions.pollResposneStatus(true));
        dispatch(slice.actions.pollResposne(response.data.data));
    }
};

export const closeQuestion = () => async (dispatch) => {
    dispatch(slice.actions.closeQuestion());
    dispatch(slice.actions.submitAns({ id: '', correctId: '', color: ECOLOR.PRIMARY, submit: false }));
    dispatch(slice.actions.pollResposneStatus(false));
};

export const emptySubmition = () => async (dispatch) => {
    dispatch(slice.actions.nextQuestion());
    dispatch(slice.actions.submitAns({ id: '', correctId: '', color: ECOLOR.PRIMARY, submit: false }));
    dispatch(slice.actions.pollResposneStatus(false));
};
export default slice;
