import { Button, styled } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { useSnackbar } from 'notistack';


export const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot.SnackbarItem-variantSuccess {
    background-color: #DDFFDE;
    color: #11BD60;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    & .SnackbarItem-action svg g{
        stroke: #11BD60;
    }
  },
  & .SnackbarItem-message svg {
    margin-right: 10px;
    min-width: 17px;
    max-width: 17px;
  },
  & .SnackbarItem-message {
    width: 280px;
  },
  &.SnackbarItem-contentRoot.SnackbarItem-variantError {
    background-color: #FFE5E5;
    color: #FF5A5A;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    & .SnackbarItem-action svg g{
        stroke: #FF5A5A;
    }
  },
  &.SnackbarItem-contentRoot.SnackbarItem-variantWarning {
    background-color: #FFF7CC;
    color: #FF9900;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    & .SnackbarItem-action svg g{
        stroke: #FF9900;
    }
  },
  &.SnackbarItem-contentRoot.SnackbarItem-variantInfo{
    background-color: #DDEFFF;
    color: #0062FD;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    & .SnackbarItem-action svg g{
        stroke: #0062FD;
    }
  },
  &.SnackbarContent-root.SnackbarItem-contentRoot .SnackbarItem-action {
    margin-right: 5px;
  }  
  `;

  export const SnackbarCloseButton = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();
  
    return (
        <Button onClick={() => closeSnackbar(snackbarKey)} sx={{ minWidth: 0, p: 0, '&:hover': { background: 'transparent'} }}>
            <svg width="11" height="11" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_977_1346)">
                    <path d="M5.39062 5L9.71875 0.65625C9.82812 0.546875 9.82812 0.375 9.71875 0.265625C9.60938 0.15625 9.4375 0.15625 9.32812 0.265625L5 4.60938L0.65625 0.28125C0.546875 0.171875 0.375 0.171875 0.265625 0.28125C0.15625 0.390625 0.15625 0.5625 0.265625 0.671875L4.60938 5L0.28125 9.34375C0.171875 9.45312 0.171875 9.625 0.28125 9.73438C0.328125 9.78125 0.40625 9.8125 0.46875 9.8125C0.53125 9.8125 0.609375 9.78125 0.65625 9.73438L5 5.39062L9.32812 9.73438C9.375 9.78125 9.45312 9.8125 9.51562 9.8125C9.57812 9.8125 9.65625 9.78125 9.70312 9.73438C9.8125 9.625 9.8125 9.45312 9.70312 9.34375L5.39062 5Z" fill="#005E47" />
                </g>
                <defs>
                    <clipPath id="clip0_977_1346">
                        <rect width="10" height="10" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </Button>
    );
  };
