import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box } from '@mui/material';

function SuspenseLoader() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{ width: '100%', height: '100%' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img src={process.env.REACT_APP_LOADER_LOGO} alt="logo" width={60} height={60} />
    </Box>
  );
}

export default SuspenseLoader;
