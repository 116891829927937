import { createSlice } from "@reduxjs/toolkit";

interface IRoot {
    keyShorts: string
    // executeKeyShortcut: boolean
}

const initialState: IRoot = {
    keyShorts: "",
    // executeKeyShortcut: false
};

const slice = createSlice({
    name: "keyshortcuts",
    initialState,
    reducers: {
        setKeyShorts(state, action) {
            state.keyShorts = action.payload;
        },
        // setExecuteKeyShortcut(state, action) {
        //     state.executeKeyShortcut = action.payload;
        // },
    },
});

export const KeyShortcutsAction = slice.actions;

export const reducer = slice.reducer;
export default slice;
